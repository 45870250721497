import React from 'react';
import { translate } from 'react-multi-lang';
import {
  Container,
} from 'semantic-ui-react';
import ResponsiveContainer from '../../components/containers/ResponsiveContainer';
import PageHeader from '../../components/headers/PageHeader';
import useScrollTopOnMount from '../../logic/ui/useScrollTopOnMount';
import bgName from '../../img/bg/legal-bg.jpg';

const LegalPage = ({ t }) => {
  useScrollTopOnMount();
  return (
    <ResponsiveContainer
      minHeight={300}
      bgName={bgName}
      header={
        <PageHeader
          header="legal"
        />
      }
    >
      <Container className="padded" text>
        <p>MENTIONS LEGALES</p>
      </Container>
    </ResponsiveContainer>
  );
};

export default translate(LegalPage);
