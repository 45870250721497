import {
  firestore,
  firebaseFirestore,
} from './firebase';
import {
  PAGINATION_LIMIT,
} from '../constants/limits';

const MAX_BATCH_SIZE = 10;

/*
	GENERIC FUNCTIONS
*/
export const createTimeStamp = () => firebaseFirestore.FieldValue.serverTimestamp();
export const createTimeStampFromS = (seconds, nanoseconds) => new firebaseFirestore.Timestamp(seconds, nanoseconds);
export const getTimeStamp = () => firebaseFirestore.Timestamp.now();
export const decrement = (n) => firebaseFirestore.FieldValue.increment(-n);
export const increment = (n) => firebaseFirestore.FieldValue.increment(n);
export const getDateTimeStamp = date => firebaseFirestore.Timestamp.fromDate(date);

const createBatch = () => firestore.batch();

// First level
const getL1CollectionRef = (collection) => (
  firestore
    .collection(collection)
);

const getL1DocRef = (collection, docId) => (
  getL1CollectionRef(collection)
    .doc(docId)
);

const getL1NewDocRef = (collection) => (
  getL1CollectionRef(collection)
    .doc()
);

const getL1CollectionRefWithCond = (
  collection,
  param,
  comparator: WhereFilterOp,
  value,
):Query => (
  getL1CollectionRef(collection)
    .where(param, comparator, value)
);

const getL1CollectionRefWith2Cond = (
  collection,
  param,
  comparator: WhereFilterOp,
  value,
  param2,
  comparator2: WhereFilterOp,
  value2,
):Query => (
  getL1CollectionRef(collection)
    .where(param, comparator, value)
    .where(param2, comparator2, value2)
);

// SECOND LEVEL
const getL2CollectionRef = (
  L1CollectionRef,
  L1DocRefId,
  L2CollectionRef,
) => (
  getL1DocRef(L1CollectionRef, L1DocRefId)
    .collection(L2CollectionRef)
);

const getL2NewDocRef = (
  L1CollectionRef,
  L1DocRefId,
  L2CollectionRef,
) => (
  getL2CollectionRef(L1CollectionRef, L1DocRefId, L2CollectionRef)
    .doc()
);

const getL2DocRef = (
  L1CollectionRef,
  L1DocRefId,
  L2CollectionRef,
  L2DocRefId,
) => (
  getL2CollectionRef(L1CollectionRef, L1DocRefId, L2CollectionRef)
    .doc(L2DocRefId)
);

// THIRD LEVEL
const getL3CollectionRef = (
  L1CollectionRef,
  L1DocRefId,
  L2CollectionRef,
  L2DocRefId,
  L3CollectionRef,
) => (
  getL2DocRef(
    L1CollectionRef,
    L1DocRefId,
    L2CollectionRef,
    L2DocRefId,
  )
    .collection(L3CollectionRef)
);

const getL3DocRef = (
  L1CollectionRef,
  L1DocRefId,
  L2CollectionRef,
  L2DocRefId,
  L3CollectionRef,
  L3Id,
) => (
  getL3CollectionRef(
    L1CollectionRef,
    L1DocRefId,
    L2CollectionRef,
    L2DocRefId,
    L3CollectionRef,
  )
    .doc(L3Id)
);

// FOURTH LEVEL
const getL4CollectionRef = (
  L1CollectionRef,
  L1DocRefId,
  L2CollectionRef,
  L2DocRefId,
  L3CollectionRef,
  L3Id,
  L4CollectionRef,
) => (
  getL3DocRef(
    L1CollectionRef,
    L1DocRefId,
    L2CollectionRef,
    L2DocRefId,
    L3CollectionRef,
    L3Id,
  )
    .collection(L4CollectionRef)
);


const getL4DocRef = (
  L1CollectionRef,
  L1DocRefId,
  L2CollectionRef,
  L2DocRefId,
  L3CollectionRef,
  L3Id,
  L4CollectionRef,
  L4Id,
) => (
  getL4CollectionRef(
    L1CollectionRef,
    L1DocRefId,
    L2CollectionRef,
    L2DocRefId,
    L3CollectionRef,
    L3Id,
    L4CollectionRef,
  )
    .doc(L4Id)
);

// Setters
const setDoc = (
  docRef,
  objectToInsert,
) => (
  docRef
    .set(
      objectToInsert,
      { merge: true },
    )
);

const set2DocsWithRefs = (
  doc1Ref,
  object1,
  doc2Ref,
  object2,
) => {
  const batch = createBatch();
  batch.set(doc1Ref, object1, { merge: true });
  batch.set(doc2Ref, object2, { merge: true });
  return batch.commit();
};

export const getAuthUser = (id) => (
  getL1DocRef('users', id)
);

export const createUser = async (
  id,
  userObj,
) => {
  const { nbUsers } = await getNbUsers();
  const newCounters = {
    nbUsers: firebaseFirestore.FieldValue.increment(1),
    nbUsersNotDeleted: firebaseFirestore.FieldValue.increment(1),
  };
  const userDocRef = getAuthUser(id);
  const counterDocRef = getL1DocRef('counters', 'counters');
  return set2DocsWithRefs(
    userDocRef,
    {
      ...userObj,
      counter: nbUsers + 1,
      deleted: false,
    },
    counterDocRef,
    {
      ...newCounters,
    },
  );
};


export const createValidatedGraduate = async (
  id,
  userObj,
) => {
  const newCounters = {
    nbUsers: firebaseFirestore.FieldValue.increment(1),
    nbUsersNotDeleted: firebaseFirestore.FieldValue.increment(1),
    nbGraduatesNotDeleted: firebaseFirestore.FieldValue.increment(1),
    nbGraduatesValidated: firebaseFirestore.FieldValue.increment(1),
    nbGraduatesValidatedNotDeleted: firebaseFirestore.FieldValue.increment(1),
  };
  const userDocRef = getAuthUser(id);
  const counterDocRef = getL1DocRef('counters', 'counters');
  return set2DocsWithRefs(
    userDocRef,
    {
      ...userObj,
    },
    counterDocRef,
    {
      ...newCounters,
    },
  );
};

export const updateUser = (
  id,
  userObj,
) => {
  const userDocRef = getAuthUser(id);
  return setDoc(
    userDocRef,
    {
      ...userObj,
    }
  );
};

export const getNbUsers = async () => {
  let nbUsers = { nbUsers: 0, nbUsersNotDeleted: 0 };
  try {
    const doc = await getL1DocRef('counters', 'counters').get();
    if (doc.exists) {
      nbUsers = {
        nbUsers: doc.data().nbUsers,
        nbUsersNotDeleted: doc.data().nbUsersNotDeleted,
      };
    } else {
      throw new Error('nbUsersNotFound');
    }
  } catch (e) {
    throw e;
  }
  return nbUsers;
}

export const getNbGraduates = async () => {
  let nbGraduates = { nbUsers: 0, nbGraduatesValidatedNotDeleted: 0 };
  try {
    const doc = await getL1DocRef('counters', 'counters').get();
    if (doc.exists) {
      nbGraduates = {
        nbUsers: doc.data().nbUsers,
        nbGraduatesValidatedNotDeleted: doc.data().nbGraduatesValidatedNotDeleted,
      };
    } else {
      throw new Error('nbGraduatesNotFound');
    }
  } catch (e) {
    throw e;
  }
  return nbGraduates;
}

export const getGraduates = async (startAt, djceCenterFilter = '', promoYearFilter = '') => {
  const graduatesRes = [];
  try {
    let ref = getL1CollectionRefWith2Cond('users', 'validated', '==', true, 'role', '==', 'GRADUATE').where('deleted', '==', false).orderBy('counter', 'desc').startAt(startAt).limit(PAGINATION_LIMIT);
    if ((djceCenterFilter !== '') && (promoYearFilter !== '')) {
      ref = getL1CollectionRefWith2Cond('users', 'validated', '==', true, 'role', '==', 'GRADUATE').where('deleted', '==', false).where('djceCenter', '==', djceCenterFilter).where('promoYear', '==', promoYearFilter).orderBy('counter', 'desc').startAt(startAt).limit(PAGINATION_LIMIT);
    } else if ((djceCenterFilter === '') && (promoYearFilter !== '')) {
      ref = getL1CollectionRefWith2Cond('users', 'validated', '==', true, 'role', '==', 'GRADUATE').where('deleted', '==', false).where('promoYear', '==', promoYearFilter).orderBy('counter', 'desc').startAt(startAt).limit(PAGINATION_LIMIT);
    } else if ((djceCenterFilter !== '') && (promoYearFilter === '')) {
      ref = getL1CollectionRefWith2Cond('users', 'validated', '==', true, 'role', '==', 'GRADUATE').where('deleted', '==', false).where('djceCenter', '==', djceCenterFilter).orderBy('counter', 'desc').startAt(startAt).limit(PAGINATION_LIMIT);
    }
    const querySnapshot = await ref.get();
    querySnapshot.forEach((graduate) => {
      // doc.data() is never undefined for query doc snapshots
      graduatesRes.push({
        id: graduate.id,
        ...graduate.data(),
      })
    });
  } catch (e) {
    throw e;
  }
  return graduatesRes;
};

export const searchGraduates = async (query, djceCenterFilter = '', promoYearFilter = '') => {
  const graduatesRes = [];
  try {
    let ref = getL1CollectionRefWith2Cond('users', 'validated', '==', true, 'role', '==', 'GRADUATE').where('deleted', '==', false).orderBy('name').startAt(query).endAt(query + "\uf8ff");
    if ((djceCenterFilter !== '') && (promoYearFilter !== '')) {
      ref = getL1CollectionRefWith2Cond('users', 'validated', '==', true, 'role', '==', 'GRADUATE').where('deleted', '==', false).where('djceCenter', '==', djceCenterFilter).where('promoYear', '==', promoYearFilter).orderBy('name').startAt(query).endAt(query + "\uf8ff");
    } else if ((djceCenterFilter === '') && (promoYearFilter !== '')) {
      ref = getL1CollectionRefWith2Cond('users', 'validated', '==', true, 'role', '==', 'GRADUATE').where('deleted', '==', false).where('promoYear', '==', promoYearFilter).orderBy('name').startAt(query).endAt(query + "\uf8ff");
    } else if ((djceCenterFilter !== '') && (promoYearFilter === '')) {
      ref = getL1CollectionRefWith2Cond('users', 'validated', '==', true, 'role', '==', 'GRADUATE').where('deleted', '==', false).where('djceCenter', '==', djceCenterFilter).orderBy('name').startAt(query).endAt(query + "\uf8ff");
    }
    const querySnapshot = await ref.get();
    querySnapshot.forEach((graduate) => {
      // doc.data() is never undefined for query doc snapshots
      graduatesRes.push({
        id: graduate.id,
        ...graduate.data(),
      })
    });
  } catch (e) {
    console.log(e)
    throw e;
  }
  return graduatesRes;
};

export const getUsers = async (startAt, statusFilter = '', roleFilter = '') => {
  const usersRes = [];
  try {
    let ref = getL1CollectionRef('users').where('deleted', '==', false).orderBy('counter', 'desc').startAt(startAt).limit(PAGINATION_LIMIT);
    if ((statusFilter !== '') && (roleFilter !== '')) {
      ref = getL1CollectionRef('users').where('deleted', '==', false).where('validated', '==', statusFilter).where('role', '==', roleFilter).orderBy('counter', 'desc').startAt(startAt).limit(PAGINATION_LIMIT);
    } else if ((statusFilter === '') && (roleFilter !== '')) {
      ref = getL1CollectionRef('users').where('deleted', '==', false).where('role', '==', roleFilter).orderBy('counter', 'desc').startAt(startAt).limit(PAGINATION_LIMIT);
    } else if ((statusFilter !== '') && (roleFilter === '')) {
      ref = getL1CollectionRef('users').where('deleted', '==', false).where('validated', '==', statusFilter).orderBy('counter', 'desc').startAt(startAt).limit(PAGINATION_LIMIT);
    }
    const querySnapshot = await ref.get();
    querySnapshot.forEach((user) => {
      // doc.data() is never undefined for query doc snapshots
      usersRes.push({
        id: user.id,
        ...user.data(),
      })
    });
  } catch (e) {
    throw e;
  }
  return usersRes;
};

export const searchUsers = async (query, statusFilter = '', roleFilter = '') => {
  console.log(query);
  const usersRes = [];
  try {
    let ref = getL1CollectionRef('users').where('deleted', '==', false).orderBy('name').startAt(query).endAt(query + "\uf8ff");
    if ((statusFilter !== '') && (roleFilter !== '')) {
      ref = getL1CollectionRef('users').where('deleted', '==', false).where('validated', '==', statusFilter).where('role', '==', roleFilter).orderBy('name').startAt(query).endAt(query + "\uf8ff");
    } else if ((statusFilter === '') && (roleFilter !== '')) {
      ref = getL1CollectionRef('users').where('deleted', '==', false).where('role', '==', roleFilter).orderBy('name').startAt(query).endAt(query + "\uf8ff");
    } else if ((statusFilter !== '') && (roleFilter === '')) {
      ref = getL1CollectionRef('users').where('deleted', '==', false).where('validated', '==', statusFilter).orderBy('name').startAt(query).endAt(query + "\uf8ff");
    }
    console.log(ref);
    const querySnapshot = await ref.get();
    console.log(querySnapshot);
    querySnapshot.forEach((user) => {
      // doc.data() is never undefined for query doc snapshots
      usersRes.push({
        id: user.id,
        ...user.data(),
      })
    });
  } catch (e) {
    throw e;
  }
  return usersRes;
};

export const validateUser = (userId, validated, role) => {
  const userDocRef = getL1DocRef('users', userId);
  const countersDocRef = getL1DocRef('counters', 'counters');
  const batch = createBatch();
  if ((role === 'GRADUATE') && !!validated) {
    batch.set(
      countersDocRef, {
        nbGraduatesValidated: firebaseFirestore.FieldValue.increment(1),
        nbGraduatesValidatedNotDeleted: firebaseFirestore.FieldValue.increment(1),
      },
      { merge: true },
    );
  } else if ((role === 'GRADUATE') && !validated) {
    batch.set(
      countersDocRef, {
        nbGraduatesValidated: firebaseFirestore.FieldValue.increment(-1),
        nbGraduatesValidatedNotDeleted: firebaseFirestore.FieldValue.increment(-1),
      },
      { merge: true },
    );
  }
  batch.set(
    userDocRef,
    {
      validated,
    },
    { merge: true },
  );
  return batch.commit();
};

export const deleteUser = (
  userId,
  role,
) => {
  let newCounters = { nbUsersNotDeleted: firebaseFirestore.FieldValue.increment(-1) };
  if (role === 'GRADUATE') {
    newCounters = {
      ...newCounters,
      nbGraduatesNotDeleted: firebaseFirestore.FieldValue.increment(-1)
    }
  }
  const userDocRef = getL1DocRef('users', userId);
  const countersDocRef = getL1DocRef('counters', 'counters');
  const batch = createBatch();
  batch.set(userDocRef, { deleted: true }, { merge: true });
  batch.set(countersDocRef, { ...newCounters }, { merge: true });
  return batch.commit();
};


export const deleteShit = async () => {
  const userDocRef = getL1CollectionRef('users').where('counter', '>=', 2382);
  const querySnapshot = await userDocRef.get();
  querySnapshot.forEach((doc) => doc.ref.delete());
};

/* JOB OFFERS */

export const getNbJobOffers = async () => {
  let nbJobOffers = { nbJobOffers: 0, nbJobOffersNotDeleted: 0 };
  try {
    const doc = await getL1DocRef('counters', 'counters').get();
    if (doc.exists) {
      nbJobOffers = {
        nbJobOffers: doc.data().nbJobOffers,
        nbJobOffersNotDeleted: doc.data().nbJobOffersNotDeleted,
      };
    } else {
      throw new Error('nbJobOffersNotFound');
    }
  } catch (e) {
    throw e;
  }
  return nbJobOffers;
}


export const createJobOffer = async (
  jobOfferObj,
) => {
  const { nbJobOffers } = await getNbJobOffers();
  const jobOfferDocRef = getL1NewDocRef('jobs');
  const counterDocRef = getL1DocRef('counters', 'counters');
  return set2DocsWithRefs(
    jobOfferDocRef,
    {
      ...jobOfferObj,
      counter: nbJobOffers + 1,
      deleted: false,
    },
    counterDocRef,
    {
      nbJobOffers: firebaseFirestore.FieldValue.increment(1),
      nbJobOffersNotDeleted: firebaseFirestore.FieldValue.increment(1),
    },
  );
};

export const updateJobOffer = (
  jobOfferId,
  jobOfferObj,
) => {
  const jobOfferDocRef = getL1DocRef('jobs', jobOfferId);
  return setDoc(
    jobOfferDocRef,
    {
      ...jobOfferObj,
    }
  );
};

export const deleteJobOffer = (
  jobOfferId,
) => {
  const jobOfferDocRef = getL1DocRef('jobs', jobOfferId);
  const countersDocRef = getL1DocRef('counters', 'counters');
  const batch = createBatch();
  batch.set(jobOfferDocRef, { deleted: true }, { merge: true });
  batch.set(countersDocRef, { nbJobOffersNotDeleted: firebaseFirestore.FieldValue.increment(-1) }, { merge: true });
  return batch.commit();
};

export const getJobOffers = async (startAt, typeFilter = '', domainFilter = '') => {
  const jobOffersObj = [];
  try {
    let ref = getL1CollectionRef('jobs').where('deleted', '==', false).orderBy('counter', 'desc').startAt(startAt).limit(PAGINATION_LIMIT);
    if ((typeFilter !== '') && (domainFilter === '')) {
      ref = getL1CollectionRef('jobs').where('deleted', '==', false).where('type', '==', typeFilter).orderBy('counter', 'desc').startAt(startAt).limit(PAGINATION_LIMIT)
    } else if ((typeFilter === '') && (domainFilter !== '')) {
      ref = getL1CollectionRef('jobs').where('deleted', '==', false).where('domain', '==', domainFilter).orderBy('counter', 'desc').startAt(startAt).limit(PAGINATION_LIMIT)
    } else if ((typeFilter !== '') && (domainFilter !== '')) {
      ref = getL1CollectionRef('jobs').where('deleted', '==', false).where('type', '==', typeFilter).where('domain', '==', domainFilter).orderBy('counter', 'desc').startAt(startAt).limit(PAGINATION_LIMIT)
    }
    const querySnapshot = await ref.get();
    querySnapshot.forEach((jobOffer) => {
      // doc.data() is never undefined for query doc snapshots
      jobOffersObj.push({
        id: jobOffer.id,
        ...jobOffer.data(),
      })
    });
  } catch (e) {
    console.log(e);
    throw e;
  }
  return jobOffersObj;
};

export const getMyJobOffersCollectionRef = (authUserId) => (
  getL1CollectionRefWith2Cond('jobs', 'createdBy', '==', authUserId, 'deleted', '==', false).orderBy('counter', 'desc')
);

export const getJobOffer = async (id) => {
  let jobOffer = {};
  try {
    const doc = await getL1DocRef('jobs', id).get();
    if (doc.exists) {
      jobOffer = {
        id: doc.id,
        ...doc.data(),
      };
    } else {
      throw new Error('jobOfferNotFound');
    }
  } catch (e) {
    throw e;
  }
  return jobOffer;
};


/* JOB OFFERS */

export const getNbNews = async () => {
  let nbNews = { nbNews: 0, nbNewsNotDeleted: 0 };
  try {
    const doc = await getL1DocRef('counters', 'counters').get();
    if (doc.exists) {
      nbNews = {
        nbNews: doc.data().nbNews,
        nbNewsNotDeleted: doc.data().nbNewsNotDeleted,
      };
    } else {
      throw new Error('nbNewsNotFound');
    }
  } catch (e) {
    throw e;
  }
  return nbNews;
}


export const createNews = async (
  newsObj,
) => {
  const { nbNews } = await getNbNews();
  const newsDocRef = getL1NewDocRef('news');
  const counterDocRef = getL1DocRef('counters', 'counters');
  return set2DocsWithRefs(
    newsDocRef,
    {
      ...newsObj,
      counter: nbNews + 1,
      deleted: false,
    },
    counterDocRef,
    {
      nbNews: firebaseFirestore.FieldValue.increment(1),
      nbNewsNotDeleted: firebaseFirestore.FieldValue.increment(1),
    },
  );
};

export const updateNews = (
  newsId,
  newsObj,
) => {
  const newsDocRef = getL1DocRef('news', newsId);
  return setDoc(
    newsDocRef,
    {
      ...newsObj,
    }
  );
};

export const deleteNews = (
  newsId,
) => {
  const newsDocRef = getL1DocRef('news', newsId);
  const countersDocRef = getL1DocRef('counters', 'counters');
  const batch = createBatch();
  batch.set(newsDocRef, { deleted: true }, { merge: true });
  batch.set(countersDocRef, { nbNewsNotDeleted: firebaseFirestore.FieldValue.increment(-1) }, { merge: true });
  return batch.commit();
};

export const getAllNews = async (startAt, tagFilter = '') => {
  const newssObj = [];
  try {
    let ref = getL1CollectionRef('news').where('deleted', '==', false).orderBy('counter', 'desc').startAt(startAt).limit(PAGINATION_LIMIT);
    if (tagFilter !== '') {
      ref = getL1CollectionRef('news').where('deleted', '==', false).where('tag', '==', tagFilter).orderBy('counter', 'desc').startAt(startAt).limit(PAGINATION_LIMIT)
    }
    const querySnapshot = await ref.get();
    querySnapshot.forEach((news) => {
      // doc.data() is never undefined for query doc snapshots
      newssObj.push({
        id: news.id,
        ...news.data(),
      })
    });
  } catch (e) {
    console.log(e);
    throw e;
  }
  return newssObj;
};


export const getNews = async (id) => {
  let news = {};
  try {
    const doc = await getL1DocRef('news', id).get();
    if (doc.exists) {
      news = {
        id: doc.id,
        ...doc.data(),
      };
    } else {
      throw new Error('newsNotFound');
    }
  } catch (e) {
    throw e;
  }
  return news;
};

export const getThreeNews = async (startAt) => {
  const newssObj = [];
  try {
    const { nbNews } = await getNbNews();
    let ref = getL1CollectionRef('news').where('deleted', '==', false).orderBy('counter', 'desc').startAt(nbNews).limit(3);
    const querySnapshot = await ref.get();
    querySnapshot.forEach((news) => {
      // doc.data() is never undefined for query doc snapshots
      newssObj.push({
        id: news.id,
        ...news.data(),
      })
    });
  } catch (e) {
    console.log(e);
    throw e;
  }
  return newssObj;
};
