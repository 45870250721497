import {
  useState,
  useEffect,
  useContext,
} from 'react';
import { firestore } from '../../firebase';
import { UseToastsContext } from '../contexts/UseToastsContext';

const useThreeNews = ()  => {
  const {
    setError,
    isLoading,
    setIsLoading,
  } = useContext(UseToastsContext);

  const [news, setNews] = useState([]);

  useEffect(
    () => {
      setIsLoading(true);
      firestore.getThreeNews().then((threeNewsArray) => {
        setNews([...threeNewsArray]);
        setIsLoading(false);
      }).catch((e) => {
        console.log(e);
        setError(e);
        setIsLoading(false);
      })
    },
    [],
  );

  return {
    news,
    isLoading,
  };
};

export default useThreeNews;
