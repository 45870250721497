import React from 'react';
import { translate } from 'react-multi-lang';
import { Dimmer, Loader } from 'semantic-ui-react';

const FullPageLoaderNoRedirect = ({ t, history }) => {
  return (
    <Dimmer active inverted>
      <Loader inverted size="massive">
        <p className="color-dark-red">
          { t('loading') }
        </p>
      </Loader>
    </Dimmer>
  );
};

export default translate(FullPageLoaderNoRedirect);
