import {
  useState,
  useEffect,
  useContext,
} from 'react';
import { firestore } from '../../firebase';
import { UseToastsContext } from '../contexts/UseToastsContext';
import { UseAuthUserContext } from '../contexts/UseAuthUserContext';

const useMyJobOffers = (t)  => {
  const {
    setError,
    isLoading,
    setIsLoading,
    setSuccess,
  } = useContext(UseToastsContext);

  const {
    authUser,
  } = useContext(UseAuthUserContext);

  const {
    id,
  } = authUser || {
    id: '',
  };

  const [offers, setOffers] = useState([]);
  const [allOffers, setAllOffers] = useState([]);
  const [positionOptions, setPositionOptions] = useState(null);
  const [positionFilter, setPositionFilter] = useState('all');
  const [typeOptions, setTypeOptions] = useState(null);
  const [typeFilter, setTypeFilter] = useState('all');
  const [isInitialized, setIsInitialized] = useState(false);
  const [isFiltering, setIsFiltering] = useState(false);

  useEffect(
    () => {
      setIsLoading(true);
      const listenerFunc = firestore.getMyJobOffersCollectionRef(id).onSnapshot((querySnapshot) => {
        setIsLoading(true);
        const jobOffers = [];
        querySnapshot.forEach((jobOffer) => {
          jobOffers.push({
            id: jobOffer.id,
            ...jobOffer.data(),
          })
        });
        setOffers([...jobOffers]);
        setAllOffers([...jobOffers]);
        const typeOptionsTemp = [{
          key: 'all',
          value: 'all',
          text: t('all'),
        }];
        const typeTemp = [];
        const positionOptionsTemp = [{
          key: 'all',
          value: 'all',
          text: t('all'),
        }];
        const positionTemp = [];
        jobOffers.forEach(({ position, type }) => {
          if (!typeTemp.includes(type)) {
            typeTemp.push(type);
            typeOptionsTemp.push({
              key: type,
              value: type,
              text: t(type),
            });
          }
          if (!positionTemp.includes(type)) {
            positionTemp.push(type);
            positionOptionsTemp.push({
              key: position,
              value: position,
              text: t(position),
            });
          }
        });
        setPositionOptions([...positionOptionsTemp]);
        setTypeOptions([...typeOptionsTemp]);
        setError(null);
      }, (e) => {
        console.log(e);
        setError(e);
        setOffers([]);
        setAllOffers([]);
        setPositionOptions([]);
        setTypeOptions([]);
      });
      return () => listenerFunc();
    },
    [],
  );

  useEffect(
    () => {
      if (!!positionOptions && !!typeOptions && !!allOffers && !!offers) {
        setIsLoading(false);
        setIsInitialized(true);
      }
    },
    [positionOptions, typeOptions, allOffers, offers]
  );

  useEffect(
    () => {
      if (isInitialized) {
        setIsFiltering(true);
        const offersPositionFilter = allOffers.filter(({ position }) => (
          (positionFilter !== 'all') ? (position === positionFilter) : true
        ));
        const offersFiltered = offersPositionFilter.filter(({ type }) => (
          (typeFilter !== 'all') ? (type === typeFilter) : true
        ));
        setOffers([...offersFiltered]);
      }
    },
    [isInitialized, positionFilter, typeFilter]
  );

  useEffect(
    () => {
      if (isInitialized) {
        setIsFiltering(false);
      }
    },
    [offers]
  );

  const deleteJobOffer = (jobOfferId) => {
    if (!isLoading) {
      setIsLoading(true);
      firestore.deleteJobOffer(jobOfferId).then(() => {
        setSuccess({ message: 'jobOfferDeleteSuccess' });
        setError(null);
        setIsLoading(false);
      }).catch((e) => {
        setError(e);
        setSuccess(null);
        setIsLoading(false);
      })
    } else {
      setError({ message: 'fillAllInfo' });
    }
  };

  return {
    offers,
    positionOptions,
    positionFilter,
    setPositionFilter,
    typeOptions,
    typeFilter,
    setTypeFilter,
    deleteJobOffer,
    isFiltering,
    isLoading,
  };
};

export default useMyJobOffers;
