import React, {
  useState,
} from 'react';
/* STYLE */
import {
  Grid,
  Segment,
  Header,
  Select,
  Card,
  Icon,
  Button,
  Label,
  Search,
} from 'semantic-ui-react';
import {
  debounce,
} from 'lodash';
/* TRANSLATIONS */
import {
  translate,
} from 'react-multi-lang';
import uuidv4 from 'uuid/v4';
import {
  generateUserValidatedOptions,
  generateUserRoleOptions
} from '../../../constants/userOptions';
import DeletionModal from '../../../components/modals/DeletionModal';
import GraduateInfoUpdateModal from '../../../components/modals/GraduateInfoUpdateModal';
import LawPracticeInfoUpdateModal from '../../../components/modals/LawPracticeInfoUpdateModal';
import useScrollTopOnMount from '../../../logic/ui/useScrollTopOnMount';
import useAdminUserControl from '../../../logic/users/useAdminUserControl';
const UsersAdminPanel = ({
  history,
  t,
}) => {
  useScrollTopOnMount();
  const {
    users,
    validatedFilter,
    setValidatedFilter,
    roleFilter,
    setRoleFilter,
    validateUser,
    deleteUser,
    isLoading,
    isFiltering,
    setLoadMore,
    allLoaded,
    loadMore,
    setUsers,
    setEventQuery,
    searchLoading,
    query,
  } = useAdminUserControl(t);
  return (
    <React.Fragment>
      {(isLoading)
      ? (
        <Grid.Row centered textAlign="center" verticalAlign="middle"  className="padded">
          <Segment
            basic
            className="full-width"
            loading={true}
          />
        </Grid.Row>
      ) : (
        <Grid.Row>
          <Grid.Column width={4}>
            <Segment basic compact className="full-width">
              <Header content={t('filters')} as="h2" />
              <span className="label">{t('validated')}</span>
              <Select
                options={generateUserValidatedOptions(t)}
                text={t(`validated${validatedFilter}`)}
                defaultValue={validatedFilter}
                className="full-width margin-bottom-1em"
                onChange={(e, { value }) => setValidatedFilter(value)}
              />
              <span className="label">{t('role')}</span>
              <Select
                options={generateUserRoleOptions(t)}
                defaultValue={roleFilter}
                text={t(roleFilter)}
                className="full-width margin-bottom-1em"
                onChange={(e, { value }) => setRoleFilter(`${value}`)}
              />
              <span className="label">{t('search')}</span>
              <Search
                loading={searchLoading}
                onSearchChange={debounce(setEventQuery, 500, {
                  leading: true,
                })}
                value={query}
                showNoResults={false}
                placeholder={t('name')}
              />
            </Segment>
          </Grid.Column>
          <Grid.Column width={11}>
            {isFiltering
              ? (
                <Segment
                  basic
                  className="full-width"
                  loading={true}
                />
              ) : (
                <React.Fragment>
                  <Card.Group centered>
                    {users.map(({ id, ...rest }, index) => (
                      <UserCard
                        key={id}
                        id={id}
                        deleteUser={deleteUser}
                        validateUser={validateUser}
                        t={t}
                        index={index}
                        users={users}
                        setUsers={setUsers}
                        {...rest}
                      />
                    ))}
                    {(users.length === 0) && (
                      <Card
                        centered
                        className="full-width"
                        header={
                          <Header as="h4" textAlign="center" className="full-width">
                            <Icon name="search" />
                            {searchLoading ? t('searching') : t('noResult')}
                          </Header>
                        }
                      />
                    )}
                    </Card.Group>
                    {(!allLoaded && (query === '')) && (
                      <Button
                        disabled={allLoaded}
                        loading={loadMore}
                        onClick={() => setLoadMore(true)}
                        className="margin-top-1em"
                        content={t('loadMore')}
                        color="red"
                        basic={allLoaded}
                        size="big"
                      />
                    )}
                  </React.Fragment>
                )
              }
            </Grid.Column>
          </Grid.Row>
        )
      }
    </React.Fragment>
  );
};

export default translate(UsersAdminPanel);


const UserCard = (
  {
    id,
    email,
    surname,
    name,
    role,
    validated,
    validateUser,
    deleteUser,
    t,
    promoYear,
    djceCenter,
    linkedinUrl,
    corporateName,
    address,
    city,
    country,
    zipCode,
    position,
    index,
    users,
    setUsers,
  }
) => {
  const [isShowingDeletionModal, setIsShowingDeletionModal] = useState(false);
  const [isShowingUpdateModal, setIsShowingUpdateModal] = useState(false);
  return (
    <React.Fragment>
      <Card
        centered
        key={uuidv4()}
        header={
          <React.Fragment>
            <Label ribbon color="black" content={t(role)} />
            <Header as="h2">
              {`${surname} ${name}`}
            </Header>
          </React.Fragment>
        }
        meta={
          (role !== 'ADMIN') && (
            !!email
              ? (
                <React.Fragment>
                  <Icon name="mail outline" />{email}
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <Icon name="warning" />{t('userMigratedWithoutEmail')}
                </React.Fragment>
              )
          )
        }
        className="full-width"
        description={
          <React.Fragment>
            {(role === 'GRADUATE') && (
              <GraduatedDecription
                promoYear={promoYear}
                djceCenter={djceCenter}
                linkedinUrl={linkedinUrl}
                t={t}
              />
            )}
            {(role === 'LAW_PRACTICE') && (
              <LawPracticeDecription
                corporateName={corporateName}
                address={address}
                city={city}
                country={country}
                zipCode={zipCode}
                position={position}
                t={t}
              />
            )}
          </React.Fragment>
        }
        extra={
          <React.Fragment>
            <Button
              color={validated ? 'olive' : 'orange'}
              content={validated ? t('isValidated') : t('notValidated')}
              icon={validated ? t('check') : t('warning')}
              labelPosition="left"
              onClick={() => validateUser(index, id, !validated, role)}
            />
            {(role !== 'ADMIN') && (
              <React.Fragment>
                <Button
                  color="black"
                  content={t('edition')}
                  icon="edit"
                  labelPosition="left"
                  onClick={() => setIsShowingUpdateModal(true)}
                />
                <Button
                  color="red"
                  content={t('deletion')}
                  icon="close"
                  labelPosition="left"
                  onClick={() => setIsShowingDeletionModal(true)}
                />
              </React.Fragment>
            )}
          </React.Fragment>
        }
      />
      <DeletionModal
        isShowingModal={isShowingDeletionModal}
        setIsShowingModal={setIsShowingDeletionModal}
        confirmButtonAction={() => {
          deleteUser(id, role);
          setIsShowingDeletionModal(false);
        }}
      />
      {(role === 'GRADUATE') && (
        <GraduateInfoUpdateModal
          isShowingModal={isShowingUpdateModal}
          setIsShowingModal={setIsShowingUpdateModal}
          id={id}
          currentName={name}
          currentSurname={surname}
          currentLinkedinUrl={linkedinUrl}
          currentDjceCenter={djceCenter}
          currentPromoYear={promoYear}
          setUsers={setUsers}
          users={users}
        />
      )}
      {(role === 'LAW_PRACTICE') && (
        <LawPracticeInfoUpdateModal
          isShowingModal={isShowingUpdateModal}
          setIsShowingModal={setIsShowingUpdateModal}
          id={id}
          currentName={name}
          currentSurname={surname}
          currentPosition={position}
          currentCorporateName={corporateName}
          currentCountry={country}
          currentCity={city}
          currentAddress={address}
          currentZipCode={zipCode}
          setUsers={setUsers}
          users={users}
        />
      )}
    </React.Fragment>
  );
}

const GraduatedDecription = ({
  promoYear,
  linkedinUrl,
  djceCenter,
  t,
}) => (
  <React.Fragment>
    <br />
    <Icon name="map marker alternate" />
    {`${djceCenter} - ${promoYear}`}
    <br />
    {(linkedinUrl === '')
      ? (<React.Fragment>
          <Icon name='linkedin' color="blue"/> {t('linkedinNotCommunicated')}
        </React.Fragment>
      ) : (
        <a href={linkedinUrl}>
          <Icon name='linkedin' color="blue"/>
          {t('seeOnLinkedin')}
        </a>
      )
    }
  </React.Fragment>
);

const LawPracticeDecription = ({
  corporateName,
  address,
  city,
  country,
  zipCode,
  position,
  t,
}) => (
  <React.Fragment>
    <br />
    <Icon name="briefcase" />
    {`${position} - ${corporateName}`}
    <br />
    <Icon name="map marker alternate" />
    {`${address} - ${city} ${zipCode} ${country}`}
  </React.Fragment>
);