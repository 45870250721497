export const SIGN_IN = '/sign-in';
export const UNAUTHENTICATED_LANDING = '/';
export const AUTHENTICATED_HOME = '/home';
export const RECOVER_MY_PASSWORD = '/recover-my-password';
export const LOADING = '/loading';
export const MY_ACCOUNT = '/my-account';

export const GRADUATE_SIGN_UP = '/graduate-sign-up';

export const LAW_PRACTICE_SIGN_UP = '/law-practice-sign-up';

export const PARTNERSHIPS = '/partnerships';
export const LEGAL = '/legal';
export const DIPLOMA = '/diploma';
export const PRESIDENT_WORD = '/presentation';
export const ADMINISTRATION_COUNCIL = '/administration-council';
export const MEMBERSHIP = '/membership';
// export const PRESENTATION = '/presentation';
export const HUMANITIES_AWARD = '/humanities-award';
export const BOURSE = '/bourse';
export const CONTACT = '/contact';

export const JOB_OFFERS = '/job-offers';
export const JOB_OFFERS_ADD = '/job-offers/add';
export const MY_JOB_OFFERS = '/my-job-offers';
export const MY_JOB_OFFER_UPDATE = '/my-job-offers/update/:id';
export const MY_JOB_OFFERS_UPDATE = '/my-job-offers/update';
export const JOBFAIR = '/jobfair';
export const JOBFAIR_EVENTS = '/jobfair-events';
export const DIRECTORY = '/directory';

export const NEWS = '/news';
export const NEWS_ADD = '/news/add';
export const NEWS_UPDATE_PAGE = '/news/update/:id';
export const NEWS_UPDATE = '/news/update';

export const ADMIN_PANEL = '/admin';
export const ADMIN_NEWS_PANEL = '/admin-news';
export const ADMIN_JOBS_PANEL = '/admin-jobs';