import {
  useState,
  useEffect,
  useContext,
} from 'react';
import { firestore } from '../../firebase';
import { UseToastsContext } from '../contexts/UseToastsContext';
import {
  UseAuthUserContext,
} from '../contexts/UseAuthUserContext';

const useGraduateAccountInfo = (history)  => {
  const {
    setError,
    setSuccess,
    isLoading,
    setIsLoading,
  } = useContext(UseToastsContext);
  const {
    authUser,
  } = useContext(UseAuthUserContext);
  const {
    id: authUserId,
    name: currentName,
    surname: currentSurname,
    djceCenter: currentDjceCenter,
    linkedinUrl: currentLinkedinUrl,
    promoYear: currentPromoYear,
  } = authUser || {
    id: '',
    name: '',
    surname: '',
    djceCenter: '',
    linkedinUrl: '',
    promoYear: '',
  };
  const [name, setNameLow] = useState(currentName);
  const [surname, setSurname] = useState(currentSurname);
  const [linkedinUrl, setLinkedinUrl] = useState(currentLinkedinUrl);
  const [djceCenter, setDjceCenter] = useState(currentDjceCenter);
  const [promoYear, setPromoYear] = useState(currentPromoYear);
  const [isDisabled, setIsDisabled] = useState(true);

  const setName = (name) => {
    setNameLow(name.toUpperCase())
  }

  useEffect(
    () => {
      setIsDisabled(
        name.trim() === ''
        || surname.trim() === ''
        || djceCenter.trim() === ''
        || promoYear.trim() === ''
      );
    },
    [name, surname, djceCenter, promoYear],
  );

  const updateMyInfo = (): void => {
    if (isLoading || isDisabled) {
      setError({ message: 'fillAllInformation' });
    }
    else {
      setIsLoading(true);
      if (authUserId !== '') {
        firestore.updateUser(
          authUserId,
          {
            surname,
            name: name.toUpperCase(),
            linkedinUrl,
            djceCenter,
            promoYear,
            updatedOn: firestore.createTimeStamp(),
          },
        ).then(() => {
          setSuccess({ message: 'infoUpdateSuccess' });
          setIsLoading(false);
        }).catch((e) => {
          setError(e);
          setIsLoading(false);
        });
      } else {
        setError({ message: 'userNotFound' });
        setIsLoading(false);
      }
    }
  };

  return {
    name,
    setName,
    surname,
    setSurname,
    linkedinUrl,
    setLinkedinUrl,
    promoYear,
    setPromoYear,
    djceCenter,
    setDjceCenter,
    isDisabled,
    isLoading,
    updateMyInfo,
  };
};

export default useGraduateAccountInfo;
