import {
  useState,
  useEffect,
  useContext,
} from 'react';
import { firestore } from '../../firebase';
import { UseToastsContext } from '../contexts/UseToastsContext';
import { UseAuthUserContext } from '../contexts/UseAuthUserContext';
import { scrollTop } from '../../utils/utils';
import useScrollTopOnMount from '../ui/useScrollTopOnMount';
import {
  generateNewsTags,
} from '../../constants/news';
import {
  ADMIN_PANEL,
} from '../../constants/routes';

const useNewsUpdate = (t, history, newsId) => {
  useScrollTopOnMount();
  const {
    setError,
    setSuccess,
    isLoading,
    setIsLoading,
  } = useContext(UseToastsContext);

  const {
    authUser,
  } = useContext(UseAuthUserContext);

  const {
    id,
  } = authUser || {
    id: '',
  };

  const [name, setName] = useState('');
  const [tag, setTag] = useState(generateNewsTags(t)[0].value);
  const [description, setDescription] = useState('');
  const [isDisabled, setIsDisabled] = useState(true);

  useEffect(
    () => {
      setIsLoading(true);
      firestore.getNews(newsId).then(({
        name: currentName,
        tag: currentTag,
        description: currentDescription,
      }) => {
        setName(currentName);
        setTag(currentTag);
        setDescription(currentDescription);
        setIsLoading(false);
      }).catch((e) => {
        setError(e);
        setIsLoading(false);
        history.push(ADMIN_PANEL);
      })
    },
    [],
  );

  useEffect(
    () => {
      setIsDisabled(
        name.trim() === ''
        || tag.trim() === ''
        || description.trim() === ''
      );
    },
    [
      name,
      tag,
      description,
    ],
  );

  const updateNews = () => {
    if (!isLoading && !isDisabled && !!id) {
      setIsLoading(true);
      firestore.updateNews(
        newsId,
        {
          name,
          tag,
          description,
          updatedBy: id,
          updatedOn: firestore.createTimeStamp(),
        }
      ).then(() => {
        setSuccess({ message: 'newsUpdateSuccess' });
        setError(null);
        setIsLoading(false);
        scrollTop();
      }).catch((e) => {
        setError(e);
        setSuccess(null);
        setIsLoading(false);
      })
    } else {
      setError({ message: 'fillAllInfo '});
    }
  };

  return {
    name,
    description,
    tag,
    setName,
    setDescription,
    setTag,
    updateNews,
    isDisabled,
    isLoading,
  };
};

export default useNewsUpdate;
