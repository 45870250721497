import {
  useState,
  useEffect,
  useContext,
} from 'react';
import axios from 'axios'; 
import { UseToastsContext } from '../contexts/UseToastsContext';
import { scrollTop } from '../../utils/utils';
import useScrollTopOnMount from '../ui/useScrollTopOnMount';

const useContact = (t)  => {
  useScrollTopOnMount();
  const {
    setError,
    setSuccess,
    isLoading,
    setIsLoading,
  } = useContext(UseToastsContext);

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [hasSentMessage, setHasSentMessage] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);

  useEffect(
    () => {
      setIsDisabled(
        name.trim() === ''
        || message.trim() === ''
        || email.trim() === ''
      );
    },
    [
      name,
      message,
      email,
    ],
  );

  const resetForm = () => {
    setName('');
    setMessage('');
    setEmail('');
    scrollTop();
  };

  useEffect(() => {
    setTimeout(() => {
      setHasSentMessage(false);
    }, 5000)
  }, [hasSentMessage])

  const sendContactMessage = () => {
    if (!isLoading && !isDisabled && !hasSentMessage) {
      setIsLoading(true);
      setHasSentMessage(true);
      axios.post(
        `https://backend.andjce.com/sendMailToContact`,
        {
          'userName': name,
          'userEmail': email,
          'userMessage': message,
        },
      ).then((response) => {
        const {
          data: {
            statusCode,
          },
        } = response || {
          data: {
            statusCode: -1,
          },
        };
        if (statusCode === 200) {
          setSuccess({ message: 'mailSent'});
          setIsLoading(false);
          resetForm();
        } else {
          setError({ message: 'mailCouldNotBeSent'});
          setIsLoading(false);
          resetForm();
        }
      }).catch((e) => {
        setError(e);
        setIsLoading(false);
        resetForm();
      });
    } else {
      setError({ message: 'fillAllInfo' });
    }
  };

  return {
    name,
    setName,
    message,
    setMessage,
    email,
    setEmail,
    sendContactMessage,
    isDisabled,
    isLoading,
   };
};

export default useContact;
