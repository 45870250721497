import React, {
  useContext,
  useState,
} from 'react';
import {
  Link,
  withRouter,
} from 'react-router-dom';
/* STYLE */
import {
  Grid,
  Select,
  Header,
  Card,
  Segment,
  Button,
  Checkbox,
  Icon,
  Label,
} from 'semantic-ui-react';
/* TRANSLATIONS */
import {
  translate,
} from 'react-multi-lang';
import {
  timeToLT
} from '../../utils/utils';
import useJobOffers from '../../logic/jobs/useJobOffers';
import JobOfferModal from '../../components/modals/JobOfferModal';
import DeletionModal from '../../components/modals/DeletionModal';
import { UseIsMobileContext } from '../../logic/contexts/UseIsMobileContext';
import { UseAuthUserContext } from '../../logic/contexts/UseAuthUserContext';
import {
  JOB_OFFERS_ADD,
  MY_JOB_OFFERS,
  MY_JOB_OFFERS_UPDATE,
} from '../../constants/routes';
import {
  generateJobTypes,
  generateJobDomains,
  JOB_XP,
} from '../../constants/jobs';

const JobOffersPanel = ({
  history,
  t,
}) => {
  const isMobile = useContext(UseIsMobileContext);
  const { authUser } = useContext(UseAuthUserContext);
  const {
    role,
  } = authUser || {
    role: 'NONE',
  };
  const {
    offers,
    /*positionOptions,
    positionFilter,
    setPositionFilter,
    typeOptions,*/
    typeFilter,
    setTypeFilter,
    domainFilter,
    setDomainFilter,
    isLoading,
    isFiltering,
    loadMore,
    deleteJobOffer,
    authUserId,
    setLoadMore,
    allLoaded,
  } = useJobOffers(t, history);
  const [showingAllOffers, setShowingAllOffers] = useState(false);
  return (
    <React.Fragment>
      {/*(isLoading || !typeOptions || !positionOptions)*/}
      {(isLoading)
        ? (
          <Grid.Row textAlign="center" verticalAlign="middle" className="padded">
            <Segment
              basic
              className="full-width"
              loading={true}
            />
          </Grid.Row>
        ) : (
          <Grid.Row>
            <Grid.Column width={4}>
              <Segment basic compact className="full-width">
                {(role === "LAW_PRACTICE") && (
                  <Button
                    as={Link}
                    to={JOB_OFFERS_ADD}
                    color="olive"
                    icon="edit"
                    labelPosition="left"
                    size={isMobile ? 'medium' : 'huge'}
                    content={t('addAJobOffer')}
                  />
                )}
                <Header content={t('filters')} as="h2" />
                {(role === "LAW_PRACTICE") && (
                  <React.Fragment>
                    <span className="label-no-margin">{t('allOffers')}</span>
                    <Checkbox
                      checked={showingAllOffers}
                      className="vertical-align-bottom"
                      onChange={(e, { checked }) => history.push(MY_JOB_OFFERS)}
                      slider
                    />
                    <span className="label-no-margin color-grey">{t('myJobOffers')}</span>
                  </React.Fragment>
                )}
                {/*<span className="label">{t('position')}</span>
                <Select
                  options={positionOptions}
                  defaultValue={positionFilter}
                  text={t(positionFilter)}
                  className="full-width margin-bottom-1em"
                  onChange={(e, { value }) => setPositionFilter(`${value}`)}
                />*/}
                <span className="label">{t('type')}</span>
                <Select
                  options={[{ key: 'all', value: 'all', text: t('all') }, ...generateJobTypes(t)]}
                  defaultValue={typeFilter}
                  text={t(typeFilter)}
                  className="full-width margin-bottom-1em"
                  onChange={(e, { value }) => setTypeFilter(`${value}`)}
                />
                <span className="label">{t('domain')}</span>
                <Select
                  options={[{ key: 'all', value: 'all', text: t('all') }, ...generateJobDomains(t)]}
                  defaultValue={domainFilter}
                  text={t(domainFilter)}
                  className="full-width margin-bottom-1em"
                  onChange={(e, { value }) => setDomainFilter(`${value}`)}
                />
              </Segment>
            </Grid.Column>
            <Grid.Column width={11}>
              {isFiltering
                ? (
                  <Segment
                    basic
                    className="full-width"
                    loading={true}
                  />
                ) : (
                  <React.Fragment>
                    <Card.Group>
                      {offers.map(({
                        id,
                        ...props
                      }) => (
                        <OfferCard
                          key={id}
                          id={id}
                          role={role}
                          authUserId={authUserId}
                          t={t}
                          deleteJobOffer={deleteJobOffer}
                          {...props}
                        />
                      ))}
                    </Card.Group>
                    {!allLoaded && (
                      <Button
                        disabled={allLoaded}
                        loading={loadMore}
                        onClick={() => setLoadMore(true)}
                        className="margin-top-1em"
                        content={t('loadMore')}
                        color="red"
                        basic={allLoaded}
                        size="big"
                      />
                    )}
                  </React.Fragment>
                )
              }
            </Grid.Column>
          </Grid.Row>
        )
      }
    </React.Fragment>
  );
};

const OfferCard = ({
  id,
  authUserId,
  role,
  name,
  type,
  domain,
  position,
  city,
  description,
  startMonth,
  endMonth,
  startYear,
  endYear,
  hasEndDate,
  requiredXP,
  company,
  contactPhoneNumber,
  contactEmail,
  createdBy,
  createdOn,
  deleteJobOffer,
  t,
}) => {
  const [isShowingDeletionModal, setIsShowingDeletionModal] = useState(false);
  const [isShowingInfoModal, setIsShowingInfoModal] = useState(false);
  return (
    <React.Fragment>
      <Card
        centered
        raised
        className="full-width"
        header={
          <React.Fragment>
            <Label ribbon color="black" content={t(type)} />
            <Header as="h2">
              {`${name}`}
            </Header>
          </React.Fragment>}
        meta={
          <React.Fragment>
            <Icon name="map marker alternate" />{`${company} - ${city}`}
          </React.Fragment>
        }
        description={
          <React.Fragment>
            {hasEndDate
              ? <div><Icon name="calendar outline" />{`${t(startMonth)} ${startYear} - ${t(endMonth)} ${endYear}`}</div>
              : <div><Icon name="calendar outline" />{`${t(startMonth)} ${startYear}`}</div>
            }
            <div className="margin-bottom-1em"><Icon name="briefcase" />{`${position}`}</div>
            <Label>
              <Icon name="calendar outline" />{timeToLT(createdOn, 'LLL')}
            </Label>
          </React.Fragment>
        }
        extra={
          <React.Fragment>
            <div className="margin-bottom-1em margin-top-1em pre-wrap">
              {`${description.substr(0, 300)}`}
              {(description.length > 299) && (
                <React.Fragment>
                  <br />
                  {'...'}
                </React.Fragment>
              )}
            </div>
            <Button
              color="olive"
              content={t('learnMore')}
              icon="plus"
              labelPosition="left"
              onClick={() => setIsShowingInfoModal(true)}
            />
            {((createdBy === authUserId) || (role === 'ADMIN')) && (
              <React.Fragment>
                <Button
                  color="black"
                  content={t('edition')}
                  icon="edit"
                  labelPosition="left"
                  as={Link}
                  to={`${MY_JOB_OFFERS_UPDATE}/${id}`}
                />
                <Button
                  color="red"
                  content={t('deletion')}
                  icon="close"
                  labelPosition="left"
                  onClick={() => setIsShowingDeletionModal(true)}
                />
              </React.Fragment>
            )}
          </React.Fragment>
        }
      />
      <DeletionModal
        isShowingModal={isShowingDeletionModal}
        setIsShowingModal={setIsShowingDeletionModal}
        confirmButtonAction={() => {
          deleteJobOffer(id);
          setIsShowingDeletionModal(false);
        }}
      />
      <JobOfferModal
        name={name}
        type={type}
        domain={domain}
        position={position}
        city={city}
        description={description}
        startMonth={startMonth}
        endMonth={endMonth}
        startYear={startYear}
        endYear={endYear}
        hasEndDate={hasEndDate}
        requiredXP={requiredXP}
        company={company}
        contactPhoneNumber={contactPhoneNumber}
        contactEmail={contactEmail}
        isShowingModal={isShowingInfoModal}
        setIsShowingModal={setIsShowingInfoModal}
      />
    </React.Fragment>
  );
};


export default withRouter(translate(JobOffersPanel));
