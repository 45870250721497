import React from 'react';
import { withRouter } from 'react-router-dom';
/* STYLE */
import {
  Grid,
  Form,
  Button,
} from 'semantic-ui-react';

import ResponsiveContainer from '../../components/containers/ResponsiveContainer';
import PageHeader from '../../components/headers/PageHeader';
import useScrollTopOnMount from '../../logic/ui/useScrollTopOnMount';
import bgName from '../../img/bg/sign-up-bg.jpg';
/* TRANSLATIONS */
import {
  translate,
} from 'react-multi-lang';
/* LOGIC */
import useLawPracticeSignUp from '../../logic/users/useLawPracticeSignUp';

const LawPracticeSignupPage = ({
  history,
  t,
}) => {
  useScrollTopOnMount();
  const {
    email,
    setEmail,
    password,
    setPassword,
    name,
    setName,
    surname,
    setSurname,
    position,
    setPosition,
    address,
    setAddress,
    city,
    setCity,
    zipCode,
    setZipCode,
    country,
    setCountry,
    corporateName,
    setCorporateName,
    isDisabled,
    isLoading,
    signUp,
  } = useLawPracticeSignUp(history);
  return (
    <ResponsiveContainer
      minHeight={300}
      bgName={bgName}
      header={
        <PageHeader
          header="signUpHeader"
          subHeader="lawPractice"
        />
      }
    >
      <Grid textAlign="center" verticalAlign="middle"  className="padded">
        <Grid.Column className="max-width-45em">
          <Form size="big" loading={isLoading}>
            <Form.Input
              fluid
              icon="user"
              value={surname}
              onChange={(e, { value }) => setSurname(`${value}`)}
              iconPosition="left"
              placeholder={t('surname')}
            />
            <Form.Input
              fluid
              icon="user"
              value={name}
              onChange={(e, { value }) => setName(`${value}`)}
              iconPosition="left"
              placeholder={t('name')}
            />
            <Form.Input
              fluid
              icon="warehouse"
              value={corporateName}
              onChange={(e, { value }) => setCorporateName(`${value}`)}
              iconPosition="left"
              placeholder={t('corporateName')}
            />
            <Form.Input
              fluid
              icon="certificate"
              value={position}
              onChange={(e, { value }) => setPosition(`${value}`)}
              iconPosition="left"
              placeholder={t('position')}
            />
            <Form.Input
              fluid
              icon="map"
              value={address}
              onChange={(e, { value }) => setAddress(`${value}`)}
              iconPosition="left"
              placeholder={t('address')}
            />
            <Form.Input
              fluid
              icon="map"
              value={city}
              onChange={(e, { value }) => setCity(`${value}`)}
              iconPosition="left"
              placeholder={t('city')}
            />
            <Form.Input
              fluid
              icon="map"
              value={zipCode}
              onChange={(e, { value }) => setZipCode(`${value}`)}
              iconPosition="left"
              placeholder={t('zipCode')}
            />
            <Form.Input
              fluid
              icon="map"
              value={country}
              onChange={(e, { value }) => setCountry(`${value}`)}
              iconPosition="left"
              placeholder={t('country')}
            />
            <Form.Input
              fluid
              icon="at"
              value={email}
              onChange={(e, { value }) => setEmail(`${value}`)}
              iconPosition="left"
              placeholder={t('email')}
            />
            <Form.Input
              fluid
              icon="lock"
              value={password}
              onChange={(e, { value }) => setPassword(`${value}`)}
              iconPosition="left"
              placeholder={t('password')}
              type="password"
            />
            <Button color="red" fluid size="big" disabled={isDisabled} onClick={() => signUp()}>
              {t('createMyAccount')}
            </Button>
          </Form>
        </Grid.Column>
      </Grid>
    </ResponsiveContainer>
  );
};

export default withRouter(translate(LawPracticeSignupPage));
