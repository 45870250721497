import React from 'react';
/* TRANSLATIONS */
import {
  translate,
} from 'react-multi-lang';
import {
  Grid,
} from 'semantic-ui-react';
import ResponsiveContainer from '../../components/containers/ResponsiveContainer';
import PageHeader from '../../components/headers/PageHeader';
import NewsPanel from './NewsPanel';
import bgName from '../../img/bg/news-bg.jpg';

const NewsPage = ({
  t,
  category,
}) => {
  return (
    <ResponsiveContainer
      minHeight={300}
      bgName={bgName}
      header={
        <PageHeader
          header="newsPanel"
        />
      }
    >
      <Grid  className="small-padded" stackable divided centered>
        <NewsPanel category={category} />
      </Grid>
    </ResponsiveContainer>
  );
};

export default translate(NewsPage);
