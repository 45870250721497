import React from 'react';
import { withRouter, Link } from 'react-router-dom';
/* STYLE */
import {
  Grid,
  Form,
  Button,
  Message,
  Container,
  Segment,
  Label,
  Divider,
} from 'semantic-ui-react';
/* TRANSLATIONS */
import {
  translate,
} from 'react-multi-lang';
/* IMAGE */
/* LOGIC */
import useResetPassword from '../../logic/users/useResetPassword';

import {
  SIGN_IN,
} from '../../constants/routes';

import ResponsiveContainer from '../../components/containers/ResponsiveContainer';
import PageHeader from '../../components/headers/PageHeader';
import useScrollTopOnMount from '../../logic/ui/useScrollTopOnMount';
import bgName from '../../img/bg/recover-bg.jpg';


const Step = ({ nb, text }) => (
  <div className="margin-bottom-1em">
    <Label size="small" circular color="red">
      { nb }
    </Label>
    <span>
      { text }
    </span>
  </div>
);

const RecoverPasswordPage = ({
  history,
  t,
}) => {
  useScrollTopOnMount();
  const {
    email,
    isDisabled,
    setEmail,
    isLoading,
    recoverMyPwd,
  } = useResetPassword();
  const infos: string[] = [
    'recoverInfo1',
    'recoverInfo2',
    'recoverInfo3',
    'recoverInfo4',
  ];
  const steps = infos.map((
    text: string,
    index: number,
  ) => (
    <Step
      key={text}
      nb={index + 1}
      text={t(text)}
    />
  ));
  return (
    <ResponsiveContainer
      minHeight={300}
      bgName={bgName}
      header={
        <PageHeader
          header="recoverMyPwd"
        />
      }
    >
      <Grid textAlign="center" verticalAlign="middle"  className="padded">
        <Grid.Column className="max-width-45em">
          <Form size="big" loading={isLoading}>
            <Form.Input
              fluid
              icon="at"
              value={email}
              onChange={(e, { value }) => setEmail(`${value}`)}
              iconPosition="left"
              placeholder={t('email')}
            />
            <Button color="red" fluid size="big" disabled={isDisabled} onClick={() => recoverMyPwd()}>
              {t('recoverMyPwd')}
            </Button>
          </Form>
          <Divider />
          <Container className="width-45em">
            <Segment textAlign="left">
              { steps }
            </Segment>
            <Message className="text-align-center">
              <b>{ t('recoverInfoHeader') }</b>
              <br />
              { t('recoverInfoMessage') }
            </Message>
            <Divider horizontal>
              {t('or')}
            </Divider>
            <Link to={SIGN_IN}>
              <Message color="red" className="text-align-center">
                  { t('login') }
              </Message>
            </Link>
          </Container>
        </Grid.Column>
      </Grid>
    </ResponsiveContainer>
  );
};

export default withRouter(translate(RecoverPasswordPage));
