import React from 'react';
import { translate } from 'react-multi-lang';
import { Link } from 'react-router-dom';
import {
  Card,
  Image,
  Segment,
  Container,
} from 'semantic-ui-react';

import ResponsiveContainer from '../../components/containers/ResponsiveContainer';
import PageHeader from '../../components/headers/PageHeader';

import useScrollTopOnMount from '../../logic/ui/useScrollTopOnMount';

import logo_elvinger from '../../img/partnerships/elvinger.png';
import logo_darroisVilley from '../../img/partnerships/Darrois-Villey-Maillot-Brochier.jpg';
import logo_hastings from '../../img/partnerships/Paul-Hastings.jpg';
import logo_bredin from '../../img/partnerships/Bredin.jpg';
import logo_white from '../../img/partnerships/White.jpg';
import logo_skadden from '../../img/partnerships/logo_skadden.png';
import bgName from '../../img/bg/partnerships-bg.jpg';

const partnerShips = [
  {
    text: 'darroisText',
    name: 'Darrois Villey Maillot Brochier',
    website: 'http://www.darroisvilley.com/',
    image: logo_darroisVilley,
  },
  {
    text: 'cabinetText',
    name: 'Elvinger Hoss Prussen',
    website: 'https://www.elvingerhoss.lu/',
    image: logo_elvinger,
  },
  {
    text: 'darroisText',
    name: 'Paul Hastings',
    website: 'https://www.paulhastings.com',
    image: logo_hastings,
  },
  {
    text: 'darroisText',
    name: 'Bredin Pat',
    website: 'https://www.bredinprat.fr/fr/',
    image: logo_bredin,
  },
  {
    text: 'cabinetText',
    name: 'White & Case',
    website: 'https://www.whitecase.com',
    image: logo_white,
  },
  {
    text: 'darroisText',
    name: 'Skadden',
    website: 'https://www.skadden.com',
    image: logo_skadden,
  },
];

const PartnershipSegmentUntranslated = ({ t }) => (
  <Card.Group stackable centered itemsPerRow={3}>
    {partnerShips.map(({ text, name, image, website }) => (
      <Card
        centered
        color="black"
        raised
        key={name}
        onClick={() => window.open(website, '_blank')}
      >
        <Image src={image} className="full-width" />
        <Card.Content textAlign="center">
          <Card.Header>{name}</Card.Header>
          <Card.Meta>
            <span>{t(text)}</span>
          </Card.Meta>
        </Card.Content>
        {/*<Card.Content extra textAlign="center">
          {website}
        </Card.Content>*/}
      </Card>
    ))}
  </Card.Group>
);

const PartnershipSegment = translate(PartnershipSegmentUntranslated);

const PartnershipsPage = () => {
  useScrollTopOnMount();
  return (
    <ResponsiveContainer
      minHeight={300}
      bgName={bgName}
      header={
        <PageHeader
          header="partnerships"
        />
      }
    >
      <Container>
        <Segment basic className="small-padded">
          <PartnershipSegment />
        </Segment>
      </Container>
    </ResponsiveContainer>
  );
};

export {
  PartnershipSegment,
  PartnershipsPage,
};

