import React from 'react';
import {
  Modal,
  Header,
  Button,
  Icon,
  Segment,
} from 'semantic-ui-react';
import { translate } from 'react-multi-lang';

const BasicModal = ({
  t,
  isShowing,
  onClose,
  headerText,
  headerIcon,
  content,
  headerColor = 'black',
  basic = false,
  backButtonText = t('back'),
  backButtonIcon = 'reply',
  hasConfirmButton = false,
  confirmButtonAction = () => {return},
  confirmButtonText= '',
}) => (
  <Modal
    open={isShowing}
    basic={basic}
    centered={false}
    onClose={onClose}
    size="small"
  >
    <Header
      icon={headerIcon}
      color={headerColor}
      content={headerText}
    />
    {basic
      ? (
        <Segment inverted>
          <Modal.Actions>
          <Button
            size="medium"
            className="float-right"
            onClick={onClose}
          >
            <Icon name={backButtonIcon} color="red" />
            {backButtonText}
          </Button>
          {hasConfirmButton && (
            <Button
              color="olive"
              size="medium"
              inverted
              className="float-right"
              onClick={confirmButtonAction}
            >
              <Icon name="checkmark" />
              {confirmButtonText}
            </Button>
          )}
          </Modal.Actions>
          {content}
        </Segment>
      ) : (
        <React.Fragment>
          {content}
          <Modal.Actions>
            <Button
              color="red"
              size="big"
              onClick={onClose}
            >
              <Icon name={backButtonIcon} />
              {backButtonText}
            </Button>
            {hasConfirmButton && (
              <Button
                color="olive"
                size="big"
                onClick={confirmButtonAction}
              >
                <Icon name="checkmark" />
                {confirmButtonText}
              </Button>
            )}
          </Modal.Actions>
        </React.Fragment>
      )
    }
  </Modal>
);

export default translate(BasicModal);
