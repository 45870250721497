import {
  useEffect,
  useState,
} from 'react';

const useWaitForImgToLoad = ( imgSrc ): void => {
  const img = new Image();
  img.src = imgSrc;
  img.onload = () => setIsImgLoaded(true);

  const [imgLoaded, setIsImgLoaded] = useState(false);

  useEffect(() => {
    if (img.complete) {
      img.onload();
    }
  }, [img.complete]);

  return {
    imgLoaded,
  };
};

export default useWaitForImgToLoad;
