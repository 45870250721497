import React, {
  useContext,
} from 'react';
import { translate } from 'react-multi-lang';
import BasicModal from './BasicModal';
import { UseIsMobileContext } from '../../logic/contexts/UseIsMobileContext';

/* STYLE */
import {
  Header,
  List,
  Label,
} from 'semantic-ui-react';

const JobOfferModal = ({
  name,
  type,
  domain,
  position,
  city,
  description,
  startMonth,
  endMonth,
  startYear,
  endYear,
  hasEndDate,
  requiredXP,
  company,
  contactPhoneNumber,
  contactEmail,
  isShowingModal,
  setIsShowingModal,
  t,
}) => {
  const isMobile = useContext(UseIsMobileContext);
  const size = isMobile ? "small" : "medium";
  const labelClass = isMobile ? "width-10em" : "width-10em";
  const titleStyle = isMobile ? "h4" : "h3";
  const descriptionStyle = isMobile ? "h5" : "h4";
  return (
    <BasicModal
      isShowing={isShowingModal}
      onClose={() => setIsShowingModal(false)}
      headerText={name}
      headerIcon="briefcase"
      content={
        <React.Fragment>
          <Header as={titleStyle} content={t('generalInfo')} textAlign="center" />
          <List divided selection size={size}>
            <List.Item>
              <Label color="grey" size={size} className={labelClass} basic horizontal>
                {t('company')}
              </Label>
              <span className="label-no-margin">
                {`${company}`}
              </span>
            </List.Item>
            <List.Item>
              <Label color="grey" size={size} className={labelClass} basic horizontal>
                {t('domain')}
              </Label>
              <span className="label-no-margin">
                {t(domain)}
              </span>
            </List.Item>
            <List.Item>
              <Label color="grey" size={size} className={labelClass} basic horizontal>
                {t('type')}
              </Label>
              <span className="label-no-margin">
                {`${t(type)}`}
              </span>
            </List.Item>
            <List.Item>
              <Label color="grey" size={size} className={labelClass} basic horizontal>
                {t('position')}
              </Label>
              <span className="label-no-margin">
                {`${t(position)}`}
              </span>
            </List.Item>
            <List.Item>
              <Label color="grey" size={size} className={labelClass} basic horizontal>
                {t('city')}
              </Label>
              <span className="label-no-margin">
                {`${city}`}
              </span>
            </List.Item>
            <List.Item>
              <Label color="grey" size={size} className={labelClass} basic horizontal>
                {hasEndDate
                  ? t('dates')
                  : t('wishedStart')
                }
              </Label>
              <span className="label-no-margin">
                {hasEndDate
                  ? `${t(startMonth)} ${startYear} - ${t(endMonth)} ${endYear}`
                  : `${t(startMonth)} ${startYear}`
                }
              </span>
            </List.Item>
            <List.Item>
              <Label color="grey" size={size} className={labelClass} basic horizontal>
                {t('requiredXP')}
              </Label>
              <span className="label-no-margin">
                {`${requiredXP}`}
              </span>
            </List.Item>
            <List.Item>
              <Label color="grey" size={size} className={labelClass} basic horizontal>
                {t('contactPhoneNumber')}
              </Label>
              <span className="label-no-margin">
                {`${contactPhoneNumber}`}
              </span>
            </List.Item>
            <List.Item>
              <Label color="grey" size={size} className={labelClass} basic horizontal>
                {t('contactEmail')}
              </Label>
              <span className="label-no-margin">
                {`${contactEmail}`}
              </span>
            </List.Item>
            <List.Item />
          </List>
          <Header as={titleStyle} content={t('description')} textAlign="center" />
          <Header as={descriptionStyle} content={description} className="pre-wrap" />
        </React.Fragment>
      }
      hasBackButton
    />
  );
};

export default translate(JobOfferModal);
