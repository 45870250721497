import React from 'react';
import {
  withRouter,
  Link,
} from 'react-router-dom';
/* STYLE */
import {
  Grid,
  Form,
  Button,
  TextArea,
  Select,
} from 'semantic-ui-react';
/* TRANSLATIONS */
import {
  translate,
} from 'react-multi-lang';
/* LOGIC */
import useNewsUpdate from '../../logic/news/useNewsUpdate';

import ResponsiveContainer from '../../components/containers/ResponsiveContainer';
import PageHeader from '../../components/headers/PageHeader';
import bgName from '../../img/bg/news-bg.jpg';
import {
  generateNewsTags,
} from '../../constants/news';
import {
  ADMIN_NEWS_PANEL,
} from '../../constants/routes';

const NewsUpdatePage = ({
  history,
  match,
  t,
}) => {
  const {
    params: {
      id,
    },
  } = match || {
    id: '',
  };
  if (id === '') {
    history.push('/');
  }
  const {
    
    name,
    description,
    tag,
    setName,
    setDescription,
    setTag,
    updateNews,
    isDisabled,
    isLoading,
  } = useNewsUpdate(t, history, id);
  return (
    <ResponsiveContainer
      minHeight={300}
      bgName={bgName}
      header={
        <PageHeader
          header="updateNews"
        />
      }
    >
      <Grid textAlign="center" verticalAlign="top" divided stackable className="padded">
        <Grid.Row>
          <Grid.Column width={4}>
            <Button
              as={Link}
              to={ADMIN_NEWS_PANEL}
              color="red"
              size="huge"
              basic
              content={t('back')}
              icon="left arrow"
              labelPosition="left"
            />
          </Grid.Column>
          <Grid.Column  width={11} textAlign="left">
            <Form size="big" loading={isLoading}>
              <span className="label">{t('newsName')}</span>
              <Form.Input
                fluid
                icon="newspaper outline"
                value={name}
                onChange={(e, { value }) => setName(`${value}`)}
                iconPosition="left"
                placeholder={t('newsName')}
              />
              <span className="label">{t('tag')}</span>
              <Select
                placeholder={t('tag')}
                options={generateNewsTags(t)}
                defaultValue={tag}
                text={t(tag)}
                onChange={(e, { value }) => setTag(`${value}`)}
                className="full-width margin-bottom-1em"
              />
              <span className="label">{t('newsDescription')}</span>
              <TextArea
                value={description}
                onChange={(e, { value }) => setDescription(`${value}`)}
                placeholder={t('newsDescription')}
                rows={10}
              />
              <Button
                color="red"
                fluid
                size="massive"
                disabled={isDisabled}
                onClick={() => updateNews()}
                content={t('addNews')}
              />
            </Form>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </ResponsiveContainer>
  );
};

export default withRouter(translate(NewsUpdatePage));
