import React from 'react';
import {
  Visibility,
  Segment,
} from 'semantic-ui-react';
import useWaitForImgToLoad from '../../logic/ui/useWaitForImgToLoad';
import FullPageLoaderNoRedirect from '../../views/loaders/FullPageLoaderNoRedirect';
import Footer from '../../components/Footer';
import DesktopMenu from '../menu/DesktopMenu';

const DesktopContainer = ({ header, children, minHeight, bgName }) => {
  const [fixed, setFixed] = React.useState(false);

  const hideFixedMenu = () => setFixed(false);
  const showFixedMenu = () => setFixed(true);

  const {
    imgLoaded,
  } = useWaitForImgToLoad(bgName);

  return imgLoaded
    ? (
      <React.Fragment>
        <Visibility
          once={false}
          onBottomPassed={() => showFixedMenu()}
          onBottomPassedReverse={() => hideFixedMenu()}
        >
          <Segment
            inverted
            textAlign="center"
            style={{ minHeight: minHeight, padding: "1em 0em", background: `url(${bgName}) no-repeat center center fixed`, backgroundSize: "cover" }}
            vertical
          >
            <DesktopMenu
              fixed={fixed}
            />
            {header}
          </Segment>
        </Visibility>
        {children}
        <Footer />
      </React.Fragment>
    )
    : <FullPageLoaderNoRedirect />;
};

export default DesktopContainer;
