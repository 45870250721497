import React, {
  useContext,
  useState,
} from 'react';
import {
  Link,
} from 'react-router-dom';
/* STYLE */
import {
  Grid,
  Select,
  Header,
  Card,
  Segment,
  Button,
  Icon,
  Checkbox,
} from 'semantic-ui-react';
/* TRANSLATIONS */
import {
  translate,
} from 'react-multi-lang';

import ResponsiveContainer from '../../components/containers/ResponsiveContainer';
import PageHeader from '../../components/headers/PageHeader';
import DeletionModal from '../../components/modals/DeletionModal';
import JobOfferModal from '../../components/modals/JobOfferModal';
import useScrollTopOnMount from '../../logic/ui/useScrollTopOnMount';
import useMyJobOffers from '../../logic/jobs/useMyJobOffers';
import { UseIsMobileContext } from '../../logic/contexts/UseIsMobileContext';
import bgName from '../../img/bg/job-bg.jpg';
import {
  JOB_OFFERS_ADD,
  JOB_OFFERS,
  MY_JOB_OFFERS_UPDATE,
  MY_JOB_OFFERS,
} from '../../constants/routes';

const MyJobOffersPage = ({
  history,
  t,
}) => {
  const isMobile = useContext(UseIsMobileContext);
  const {
    offers,
    positionOptions,
    positionFilter,
    setPositionFilter,
    typeOptions,
    typeFilter,
    setTypeFilter,
    deleteJobOffer,
    isLoading,
    isFiltering,
  } = useMyJobOffers(t);
  const [showingAllOffers, setShowingAllOffers] = useState(true);
  return (
    <ResponsiveContainer
      minHeight={300}
      bgName={bgName}
      header={
        <PageHeader
          header="myJobOffers"
        />
      }
    >
      {(isLoading || !typeOptions || !positionOptions)
        ? (
          <Grid textAlign="center" verticalAlign="middle" className="padded">
            <Segment
              basic
              className="full-width"
              loading={true}
            />
          </Grid>
        ) : (
          <Grid divided stackable className="small-padded">
            <Grid.Row>
              <Grid.Column width={4}>
                <Segment basic compact className="full-width">
                  <Header as="h1" textAlign="center">
                    <Button
                      as={Link}
                      to={JOB_OFFERS_ADD}
                      color="olive"
                      icon="edit"
                      iconPosition="left"
                      size={isMobile ? 'medium' : 'huge'}
                      content={t('addAJobOffer')}
                    />
                  </Header>
                  <Header content={t('filters')} as="h2" />
                  <span className="label-no-margin color-grey">{t('allOffers')}</span>
                  <Checkbox
                    checked={showingAllOffers}
                    className="vertical-align-bottom"
                    onChange={(e, { checked }) => history.push(JOB_OFFERS)}
                    slider
                  />
                  <span className="label-no-margin">{t('myJobOffers')}</span>
                  <span className="label">{t('position')}</span>
                  <Select
                    options={positionOptions}
                    defaultValue={positionFilter}
                    text={t(positionFilter)}
                    className="full-width margin-bottom-1em"
                    onChange={(e, { value }) => setPositionFilter(`${value}`)}
                  />
                  <span className="label">{t('type')}</span>
                  <Select
                    options={typeOptions}
                    defaultValue={typeFilter}
                    text={t(typeFilter)}
                    className="full-width margin-bottom-1em"
                    onChange={(e, { value }) => setTypeFilter(`${value}`)}
                  />
                </Segment>
              </Grid.Column>
              <Grid.Column width={11}>
                {isFiltering
                  ? (
                    <Segment
                      basic
                      className="full-width"
                      loading={true}
                    />
                  ) : (
                    <Card.Group>
                      {offers.map(({
                        id,
                        ...props
                      }) => (
                        <MyOfferCard
                          key={id}
                          id={id}
                          t={t}
                          deleteJobOffer={deleteJobOffer}
                          {...props}
                        />
                      ))}
                    </Card.Group>
                  )
                }
              </Grid.Column>
            </Grid.Row>
          </Grid>
        )
      }
    </ResponsiveContainer>
  );
};

export default translate(MyJobOffersPage);

const MyOfferCard = ({
  id,
  name,
  type,
  domain,
  position,
  city,
  description,
  startMonth,
  endMonth,
  startYear,
  endYear,
  hasEndDate,
  requiredXP,
  company,
  contactPhoneNumber,
  contactEmail,
  deleteJobOffer,
  t,
}) => {
  const [isShowingDeletionModal, setIsShowingDeletionModal] = useState(false);
  const [isShowingInfoModal, setIsShowingInfoModal] = useState(false);
  return (
    <React.Fragment>
      <Card
        centered
        raised
        className="full-width"
        header={`${name}`}
        meta={
          <React.Fragment>
            <Icon name="map marker alternate" />{`${company} - ${city}`}
          </React.Fragment>
        }
        description={
          <React.Fragment>
            {hasEndDate
              ? <div><Icon name="calendar outline" />{`${t(startMonth)} ${startYear} - ${t(endMonth)} ${endYear}`}</div>
              : <div><Icon name="calendar outline" />{`${t(startMonth)} ${startYear}`}</div>
            }
            <div className="bold"><Icon name="briefcase" />{`${t(type)} - ${position}`}</div>
          </React.Fragment>
        }
        extra={
          <React.Fragment>
            <div className="margin-bottom-1em margin-top-1em pre-wrap">
              {`${description.substr(0, 300)}`}
              {(description.length > 299) && (
                <React.Fragment>
                  <br />
                  {'...'}
                </React.Fragment>
              )}
            </div>
            <Button
              color="olive"
              content={t('learnMore')}
              icon="plus"
              labelPosition="left"
              onClick={() => setIsShowingInfoModal(true)}
            />
            <Button
              color="black"
              content={t('edition')}
              icon="edit"
              labelPosition="left"
              as={Link}
              to={`${MY_JOB_OFFERS_UPDATE}/${id}`}
            />
            <Button
              color="red"
              content={t('deletion')}
              icon="close"
              labelPosition="left"
              onClick={() => setIsShowingDeletionModal(true)}
            />
          </React.Fragment>
        }
      />
      <DeletionModal
        isShowingModal={isShowingDeletionModal}
        setIsShowingModal={setIsShowingDeletionModal}
        confirmButtonAction={() => {
          deleteJobOffer(id);
          setIsShowingDeletionModal(false);
        }}
      />
      <JobOfferModal
        name={name}
        type={type}
        domain={domain}
        position={position}
        city={city}
        description={description}
        startMonth={startMonth}
        endMonth={endMonth}
        startYear={startYear}
        endYear={endYear}
        hasEndDate={hasEndDate}
        requiredXP={requiredXP}
        company={company}
        contactPhoneNumber={contactPhoneNumber}
        contactEmail={contactEmail}
        isShowingModal={isShowingInfoModal}
        setIsShowingModal={setIsShowingInfoModal}
      />
    </React.Fragment>
  );
};
