import {
  useEffect,
  useState,
  useContext,
} from 'react';
import { auth } from '../../firebase';
import { UseToastsContext } from '../contexts/UseToastsContext';

const useChangeMyPassword = () => {
  const [currentPwd, setCurrentPwd] = useState('');
  const [newPwd, setNewPwd] = useState('');
  const [newPwdConfirm, setNewPwdConfirm] = useState('');
  const [isDisabled, setIsDisabled] = useState(true);

  useEffect(
    () => {
      setIsDisabled(
        newPwd.trim() === ''
        || newPwd.length < 6
        || currentPwd.trim() === ''
        || currentPwd.length < 6
        || newPwdConfirm.trim() === ''
        || newPwdConfirm.length < 6
        || newPwd !== newPwdConfirm
      );
    },
    [newPwd, currentPwd, newPwdConfirm],
  );

  const {
    setError,
    setSuccess,
    isLoading,
    setIsLoading,
  } = useContext(UseToastsContext);

  const changeMyPwd = (): void => {
    if (isLoading || isDisabled) {
      setError({ message: 'fillAllInformation' });
    }
    else {
      setIsLoading(true);
      if (!!auth && (newPwd === newPwdConfirm)) {
        auth.reauthenticateWithCredential(currentPwd).then(() => {
          auth.updatePassword(newPwd).then(() => {
            setSuccess({ message: 'changePwdSuccess' });
            setIsLoading(false);
            setError(null);
          })
          .catch((e) => {
            setError(e);
            setSuccess(null);
            setIsLoading(false);
          });
        })
        .catch((e) => {
          setError(e);
          setSuccess(null);
          setIsLoading(false);
        });
      } else {
        setError({ message: 'newPwdsDontMatch' });
        setSuccess(null);
        setIsLoading(false);
      }
    }
  };

  return {
    currentPwd,
    setCurrentPwd,
    newPwd,
    setNewPwd,
    newPwdConfirm,
    setNewPwdConfirm,
    isLoading,
    isDisabled,
    changeMyPwd,
  };
};

export default useChangeMyPassword;
