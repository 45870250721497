import React, {
  useContext,
} from 'react';
import { translate } from 'react-multi-lang';
import BasicModal from './BasicModal';
import { UseIsMobileContext } from '../../logic/contexts/UseIsMobileContext';
import { timeToLT } from '../../utils/utils';

/* STYLE */
import {
  Header,
  Label,
} from 'semantic-ui-react';

const NewsModal = ({
  name,
  tag,
  description,
  isShowingModal,
  setIsShowingModal,
  createdOn,
  t,
}) => {
  const isMobile = useContext(UseIsMobileContext);
  const titleStyle = isMobile ? "h3" : "h2";
  const descriptionStyle = isMobile ? "p" : "p";
  return (
    <BasicModal
      basic
      isShowing={isShowingModal}
      onClose={() => setIsShowingModal(false)}
      headerText={name}
      headerIcon="newspaper outline"
      content={
        <React.Fragment>
          <Label ribbon inverted size="medium">
            {timeToLT(createdOn, 'LLL')}
          </Label>
          <Header as={titleStyle} content={name} textAlign="center" />
          <Header as={descriptionStyle} color="olive" content={t(tag)} textAlign="center" />
          <p className="pre-wrap">{description}</p>
        </React.Fragment>
      }
      hasBackButton
    />
  );
};

export default translate(NewsModal);
