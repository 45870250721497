import {
  useState,
  useEffect,
  useContext,
} from 'react';
import { firestore } from '../../firebase';
import { PAGINATION_LIMIT } from '../../constants/limits';
import { UseToastsContext } from '../contexts/UseToastsContext';

const useDirectory = (t)  => {
  const {
    setError,
    isLoading,
    setSuccess,
    setIsLoading,
  } = useContext(UseToastsContext);

  const [graduates, setGraduates] = useState([]);
  // const [allGraduates, setAllGraduates] = useState([]);
  // const [promoYearOptions, setPromoYearOptions] = useState(null);
  const [promoYearFilter, setPromoYearFilter] = useState('all');
  const [searchLoading, setSearchLoading] = useState(false);
  // const [djceCenterOptions, setDjceCenterOptions] = useState(null);
  const [djceCenterFilter, setDjceCenterFilter] = useState('all');
  const [isInitialized, setIsInitialized] = useState(false);
  const [isFiltering, setIsFiltering] = useState(false);
  const [queryHasChangedOnce, setQueryHasChangedOnce] = useState(false);
  const [startAt, setStartAt] = useState(0);
  const [nbUsers, setNbUsers] = useState(-1);
  const [nbGraduatesNotDeleted, setNbGraduatesNotDeleted] = useState(-1);
  const [loadMore, setLoadMore] = useState(false);
  const [allLoaded, setAllLoaded] = useState(false);
  const [query, setQuery] = useState('');

  useEffect(
    () => {
      getNbGraduates();
    },
    [djceCenterFilter, promoYearFilter],
  );

  const setEventQuery = (e, { value }) => {
    setQuery((`${value}`).toUpperCase());
  };

  const getNbGraduates = () => {
    setIsLoading(true);
    resetGraduates();
    firestore.getNbGraduates().then((nb) => {
      setStartAt(nb.nbUsers);
      setNbUsers(nb.nbUsers);
      setNbGraduatesNotDeleted(nb.nbGraduatesValidatedNotDeleted);
    })
  }

  const resetGraduates = () => {
    setNbUsers(-1);
    setNbGraduatesNotDeleted(-1);
    setAllLoaded(false);
    setIsInitialized(false);
    setGraduates([]);
  }

  useEffect(
    () => {
      if (!isInitialized && (nbUsers >= 0)) {
        getGraduates();
      }
    },
    [nbUsers, isInitialized],
  );

  useEffect(
    () => {
      if (loadMore) {
        setLoadMore(true);
        getGraduates();
      }
    },
    [loadMore]
  );

  const getGraduates = () => {
    const djceCenterFilterTreated = (djceCenterFilter === 'all') ? '' : djceCenterFilter;
    const promoYearFilterTreated = (promoYearFilter === 'all') ? '' : promoYearFilter;
    firestore.getGraduates(startAt, djceCenterFilterTreated, promoYearFilterTreated).then(graduateUsers => {
      const newCounter = (graduateUsers.length > 0)
        ? graduateUsers[graduateUsers.length - 1].counter - 1
        : 0;
      setStartAt(newCounter);
      if ((newCounter === 0) || (graduateUsers.length < PAGINATION_LIMIT) || ((graduateUsers.length + graduates.length) === nbGraduatesNotDeleted)){
        setAllLoaded(true);
      }
      setGraduates([...graduates, ...graduateUsers]);
      if ((graduates.length > 0) && (graduateUsers.length === 0)) {
        setSuccess({ message: 'noMoreToLoad'})
      }
      // setAllOffers([...graduates, ...jobOffers]);
      setError(null);
    }).catch((e) => {
      setError(e);
      setGraduates([]);
      /*setAllOffers([]);
      setPositionOptions([]);
      setTypeOptions([]);*/
    });
  };


  /*useEffect(
    () => { 
      if (!!positionOptions && !!typeOptions && !!allOffers && !!graduates) {
        setIsLoading(false);
        setIsInitialized(true);
      }
    },
    [positionOptions, typeOptions, allOffers, graduates]
  );*/

  useEffect(
    () => {
      if (!!graduates && (nbUsers >= 0) && (nbGraduatesNotDeleted >= 0)) {
        setIsInitialized(true);
        setIsLoading(false);
        setTimeout(() => {
          setLoadMore(false);
        }, 500)
      }
    },
    [graduates]
  );

  /*useEffect(
    () => { 
      if (isInitialized) {
        setIsFiltering(true);
        const graduatesPositionFilter = allOffers.filter(({ position }) => (
          (positionFilter !== 'all') ? (position === positionFilter) : true
        ));
        const graduatesFiltered = graduatesPositionFilter.filter(({ type }) => (
          (typeFilter !== 'all') ? (type === typeFilter) : true
        ));
        setGraduates([...graduatesFiltered]);
      }
    },
    [isInitialized, positionFilter, typeFilter]
  );*/

  useEffect(
    () => {
      if (isInitialized) {
        setIsFiltering(false);
      }
    },
    [graduates]
  );

  /*const searchByName = (name) => {
    const graduatesRef = firestore
      .where('status', '==', showing)
      .orderBy('reference')
      .startAt(queryUpper)
  }*/


  const search = (queryToSearch) => {
    if (!isLoading) {
      setSearchLoading(true);
      resetGraduates();
      setTimeout(() => {
        const djceCenterFilterTreated = (djceCenterFilter === 'all') ? '' : djceCenterFilter;
        const promoYearFilterTreated = (promoYearFilter === 'all') ? '' : promoYearFilter;
        firestore.searchGraduates(queryToSearch, djceCenterFilterTreated, promoYearFilterTreated).then(graduateUsers => {
          setAllLoaded(true);
          setGraduates([...graduateUsers]);
          setError(null);
          setSearchLoading(false);
        }).catch((e) => {
          setError(e);
          setGraduates([]);
          setSearchLoading(false);
        });
      }, 300)
    }
  };

  useEffect(
    () => {
      if (queryHasChangedOnce && query.length > 3) {
        search(query)
      }
    },
    [queryHasChangedOnce, query],
  );

  useEffect(
    () => {
      if (query.length > 0) {
        setQueryHasChangedOnce(true);
      } else {
        setSearchLoading(false);
        getNbGraduates();
      }
    },
    [query],
  );



  return {
    /*positionOptions,
    typeOptions,*/
    promoYearFilter,
    setPromoYearFilter,
    graduates,
    djceCenterFilter,
    setDjceCenterFilter,
    isFiltering,
    isLoading,
    loadMore,
    setLoadMore,
    allLoaded,
    search,
    setEventQuery,
    searchLoading,
    query,
  };
};

export default useDirectory;
