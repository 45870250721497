export const DJCE_CENTERS = [
  { key: 'Bordeaux', value: 'Bordeaux', text: 'Bordeaux' },
  { key: 'Caen', value: 'Caen', text: 'Caen' },
  { key: 'Cergy', value: 'Cergy', text: 'Cergy' },
  { key: 'Lyon', value: 'Lyon', text: 'Lyon' },
  { key: 'Montpellier', value: 'Montpellier', text: 'Montpellier' },
  { key: 'Nancy', value: 'Nancy', text: 'Nancy' },
  { key: 'Paris II', value: 'Paris II', text: 'Paris II' },
  { key: 'Poitiers', value: 'Poitiers', text: 'Poitiers' },
  { key: 'Rennes', value: 'Rennes', text: 'Rennes' },
  { key: 'Strasbourg', value: 'Strasbourg', text: 'Strasbourg' },
  { key: 'Toulouse', value: 'Toulouse', text: 'Toulouse' },
];


export const generatePromoYearsOptions = (t) => {
  const date = new Date();
  const nextYear = date.getFullYear() + 1;
  const PROMO_YEARS = [{ key: 'all', value: 'all', text:t('all') }];
  let i = nextYear;
  for (i ; i > 1919 ; i--) {
    PROMO_YEARS.push(
      { key: i, value: i, text: `${i}` },
    )
  };
  return PROMO_YEARS;
};
