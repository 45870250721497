import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { translate } from 'react-multi-lang';
import {
  Menu,
  Dropdown,
  List,
  Header,
  Image,
} from 'semantic-ui-react';
import * as ROUTES from '../../constants/routes';
import {
  UseAuthUserContext,
} from '../../logic/contexts/UseAuthUserContext';
import useSignout from '../../logic/users/useSignout';
import logo from '../../img/logo.png';

const generalItems = [
  {
    title: 'theAssociation',
    route: '',
    isDropdown: true,
    subtitles: [
      /*{
        subTitle: 'presentation',
        subRoute: ROUTES.PRESENTATION,
      },*/
      {
        subTitle: 'presidentWord',
        subRoute: ROUTES.PRESIDENT_WORD,
      },
      {
        subTitle: 'administrationCouncil',
        subRoute: ROUTES.ADMINISTRATION_COUNCIL,
      },
    ],
  },
  {
    title: 'partnerships',
    route: ROUTES.PARTNERSHIPS,
    isDropdown: false,
    subtitles: [],
  },
  {
    title: 'djce',
    route: '',
    isDropdown: true,
    subtitles: [
      {
        subTitle: 'theDiploma',
        subRoute: ROUTES.DIPLOMA,
      },
      {
        subTitle: 'bourse',
        subRoute: ROUTES.BOURSE,
      },
      {
        subTitle: 'humanitiesAward',
        subRoute: ROUTES.HUMANITIES_AWARD,
      },
    ],
  },
  {
    title: 'contact',
    route: ROUTES.CONTACT,
    isDropdown: false,
    subtitles: [],
  },
];

const unauthenticatedItems = [
  {
    title: 'theCommunity',
    route: '',
    isDropdown: true,
    subtitles: [
      {
        subTitle: 'news',
        subRoute: ROUTES.NEWS,
      },
      {
        subTitle: 'jobFair',
        subRoute: ROUTES.JOBFAIR,
      },
    ],
  },
  ...generalItems,
  {
    title: 'signup',
    route: ROUTES.MEMBERSHIP,
    isDropdown: false,
    subtitles: [],
  },
  {
    title: 'login',
    route: ROUTES.SIGN_IN,
    isDropdown: false,
    subtitles: [],
  },
];

const authenticatedItems = [
  {
    title: 'theCommunity',
    route: '',
    isDropdown: true,
    subtitles: [
      {
        subTitle: 'news',
        subRoute: ROUTES.NEWS,
      },
      {
        subTitle: 'theDirectory',
        subRoute: ROUTES.DIRECTORY,
      },
    ],
  },
  ...generalItems,
  {
    title: 'yourCarrier',
    route: '',
    isDropdown: true,
    subtitles: [
      {
        subTitle: 'jobOffers',
        subRoute: ROUTES.JOB_OFFERS,
      },
      {
        subTitle: 'jobFair',
        subRoute: ROUTES.JOBFAIR,
      },
    ],
  },
  {
    title: 'myAccount',
    route: ROUTES.MY_ACCOUNT,
    isDropdown: false,
    subtitles: [],
  },
];


const adminItems = [
  {
    title: 'theCommunity',
    route: '',
    isDropdown: true,
    subtitles: [
      {
        subTitle: 'news',
        subRoute: ROUTES.NEWS,
      },
      {
        subTitle: 'theDirectory',
        subRoute: ROUTES.DIRECTORY,
      },
    ],
  },
  ...generalItems,
  {
    title: 'yourCarrier',
    route: '',
    isDropdown: true,
    subtitles: [
      {
        subTitle: 'jobOffers',
        subRoute: ROUTES.JOB_OFFERS,
      },
      {
        subTitle: 'jobFair',
        subRoute: ROUTES.JOBFAIR,
      },
    ],
  },
  {
    title: 'administration',
    route: ROUTES.ADMIN_PANEL,
    isDropdown: false,
    subtitles: [],
  },
];

const getItemsFromRole = (role) => {
  switch(role) {
    case "GRADUATE": {
      return authenticatedItems;
    }
    case "ADMIN": {
      return adminItems;
    }
    case "LAW_PRACTICE": {
      return authenticatedItems;
    }
    case "NONE": {
      return unauthenticatedItems;
    }
    default: {
      return unauthenticatedItems;
    }
  }
}

const MenuItems = ({ fixed, t, mobile, history }, index) => {
  const {
    authUser,
  } = React.useContext(UseAuthUserContext);
  const { signOut } = useSignout(history);
  const role = !!authUser ? authUser.role : "NONE";
  const items = getItemsFromRole(role);
  const menuItemsJSX = items.map((
    {
      title,
      route,
      isDropdown,
      subtitles,
    }
  ) => (
    isDropdown
      ? (
        <DropdownItem
          t={t}
          title={title}
          key={`${title + index}`}
          subtitles={subtitles}
          mobile={mobile}
        />
      )
      : (
        <Link to={route} key={`${title + index}`} className={!fixed ? "" : "color-white"}>
          <Menu.Item>
            {t(title)}
          </Menu.Item>
        </Link>
      )
  ));
  return (
    <React.Fragment>
      <Menu.Item as={Link} to={authUser ? ROUTES.AUTHENTICATED_HOME : ROUTES.UNAUTHENTICATED_LANDING}>
        <Image src={logo} className="logoImg"/>
      </Menu.Item>
      {menuItemsJSX}
      {!!authUser && (
        <Menu.Item onClick={() => signOut()}>
          {t('signOut')}
        </Menu.Item>
      )}
    </React.Fragment>
  );
};

const DropdownItem = ({ title, subtitles, t, mobile }) => (
  mobile
    ? (
      <DropdownMobileItem
        title={title}
        subtitles={subtitles}
        t={t}
      />
    )
    : (
      <DropdownDesktopItem
        title={title}
        subtitles={subtitles}
        t={t}
      />
    )
);

const DropdownDesktopItem = ({ title, subtitles, t }) => {
  const subItemsJSX = subtitles.map(({ subTitle, subRoute }, index) => (
    <Dropdown.Item key={`${subTitle + index}`} as={Link} to={subRoute}>
      <span  className="color-white">
        {t(subTitle)}
      </span>
    </Dropdown.Item>
  ));
  return (
    <Dropdown item simple text={t(title)}>
      <Dropdown.Menu>
        {subItemsJSX}
      </Dropdown.Menu>
    </Dropdown>
  );
};

const DropdownMobileItem = ({ title, subtitles, t }) => {
  const subItemsJSX = subtitles.map(({ subTitle, subRoute }, index) => (
    <List.Item key={`${subTitle + index}`} as={Link} to={subRoute}>
      {t(subTitle)}
    </List.Item>
  ));
  return (
    <Menu.Item>
      <Header inverted as="h5" content={t(title)} />
      <List link inverted>
        {subItemsJSX}
      </List>
    </Menu.Item>
  );
};

export default withRouter(translate(MenuItems));
