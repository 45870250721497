import React from 'react';
import { withRouter, Link } from 'react-router-dom';
/* STYLE */
import {
  Grid,
  Form,
  Button,
  Message,
  Divider,
} from 'semantic-ui-react';
/* TRANSLATIONS */
import {
  translate,
} from 'react-multi-lang';
/* IMAGE */
/* LOGIC */
import useLogin from '../../logic/users/useLogin';

import ResponsiveContainer from '../../components/containers/ResponsiveContainer';
import PageHeader from '../../components/headers/PageHeader';
import useScrollTopOnMount from '../../logic/ui/useScrollTopOnMount';
import bgName from '../../img/bg/connection-bg.jpg';

import {
  RECOVER_MY_PASSWORD,
} from '../../constants/routes';

const LoginPage = ({
	history,
  t,
}) => {
  useScrollTopOnMount();
  const {
    isLoading,
    email,
    setEmail,
    password,
    setPassword,
    isDisabled,
    signIn,
  } = useLogin(history);
  return (
    <ResponsiveContainer
      minHeight={300}
      bgName={bgName}
      header={
        <PageHeader
          header="loginHeader"
        />
      }
    >
      <Grid textAlign="center" verticalAlign="middle"  className="padded">
        <Grid.Column className="max-width-45em">
          <Form size="big" loading={isLoading}>
            <Form.Input
              fluid
              icon="at"
              value={email}
              onChange={(e, { value }) => setEmail(`${value}`)}
              iconPosition="left"
              placeholder={t('email')}
            />
            <Form.Input
              fluid
              icon="lock"
              value={password}
              onChange={(e, { value }) => setPassword(`${value}`)}
              iconPosition="left"
              placeholder={t('password')}
              type="password"
            />
            <Button color="red" fluid size="big" disabled={isDisabled} onClick={() => signIn()}>
              {t('login')}
            </Button>
          </Form>
          <Divider />
          <Link to={RECOVER_MY_PASSWORD}>
            <Message className="text-align-center">
                { t('recoverPwd') }
            </Message>
          </Link>
        </Grid.Column>
      </Grid>
    </ResponsiveContainer>
  );
};

export default withRouter(translate(LoginPage));
