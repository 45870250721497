import React from 'react';
import { UseIsMobileContext } from '../../logic/contexts/UseIsMobileContext';

import DesktopContainer from './DesktopContainer';
import MobileContainer from './MobileContainer';

const ResponsiveContainer = ({ header, children, bgName, minHeight }) => {
  const isMobile = React.useContext(UseIsMobileContext);
  return isMobile
    ? (
      <MobileContainer
        header={header}
        children={children}
        bgName={bgName}
        minHeight={minHeight / 1.5}
      />
    )
    : (
      <DesktopContainer
        header={header}
        children={children}
        bgName={bgName}
        minHeight={minHeight}
      />
    );
};

export default ResponsiveContainer;
