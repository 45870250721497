import {
  useState,
  useEffect,
  useContext,
} from 'react';
import { firestore } from '../../firebase';
import { PAGINATION_LIMIT } from '../../constants/limits';
import { UseToastsContext } from '../contexts/UseToastsContext';

const useAdminUserControl = (t)  => {
  const {
    setError,
    isLoading,
    setIsLoading,
    setSuccess,
  } = useContext(UseToastsContext);

  const [users, setUsers] = useState([]);
  // const [allUsers, setAllUsers] = useState([]);
  // const [validatedOptions, setValidatedOptions] = useState(null);
  const [validatedFilter, setValidatedFilter] = useState('all');
  // const [roleOptions, setRoleOptions] = useState(null);
  const [roleFilter, setRoleFilter] = useState('all');
  const [isInitialized, setIsInitialized] = useState(false);
  const [searchLoading, setSearchLoading] = useState(false);
  const [isFiltering, setIsFiltering] = useState(false);
  const [queryHasChangedOnce, setQueryHasChangedOnce] = useState(false);
  // const [updated, setUpdated] = useState(false);

  const [startAt, setStartAt] = useState(0);
  const [nbUsers, setNbUsers] = useState(-1);
  const [nbUsersNotDeleted, setNbUsersNotDeleted] = useState(-1);
  const [loadMore, setLoadMore] = useState(false);
  const [allLoaded, setAllLoaded] = useState(false);
  const [query, setQuery] = useState('');

  useEffect(
    () => {
      getNbUsers();
    },
    [validatedFilter, roleFilter],
  );

  const getNbUsers = () => {
    setIsLoading(true);
    resetUsers();
    firestore.getNbUsers().then((nb) => {
      setStartAt(nb.nbUsers);
      setNbUsers(nb.nbUsers);
      setNbUsersNotDeleted(nb.nbUsersNotDeleted);
    })
  }

  const setEventQuery = (e, { value }) => {
    setQuery((`${value}`).toUpperCase());
  };

  const resetUsers = () => {
    setNbUsers(-1);
    setNbUsersNotDeleted(-1);
    setAllLoaded(false);
    setIsInitialized(false);
    setUsers([]);
  }

  useEffect(
    () => {
      if (!isInitialized && (nbUsers >= 0)) {
        getUsers();
      }
    },
    [nbUsers, isInitialized],
  );

  useEffect(
    () => {
      if (loadMore) {
        setLoadMore(true);
        getUsers();
      }
    },
    [loadMore]
  );

  const getUsers = () => {
    const validatedFilterTreated = (validatedFilter === 'all') ? '' : validatedFilter;
    const roleFilterTreated = (roleFilter === 'all') ? '' : roleFilter;
    firestore.getUsers(startAt, validatedFilterTreated, roleFilterTreated).then(usersFromDB => {
      const newCounter = (usersFromDB.length > 0)
        ? usersFromDB[usersFromDB.length - 1].counter - 1
        : 0;
      setStartAt(newCounter);
      if ((newCounter === 0) || (usersFromDB.length < PAGINATION_LIMIT) || ((usersFromDB.length + users.length) === nbUsers)){
        setAllLoaded(true);
      }
      setUsers([...users, ...usersFromDB]);
      if ((users.length > 0) && (usersFromDB.length === 0)) {
        setSuccess({ message: 'noMoreToLoad'})
      }
      // setAllOffers([...users, ...jobOffers]);
      setError(null);
    }).catch((e) => {
      setError(e);
      setUsers([]);
      /*setAllOffers([]);
      setPositionOptions([]);
      setTypeOptions([]);*/
    });
  };


  /*useEffect(
    () => { 
      if (!!positionOptions && !!typeOptions && !!allOffers && !!users) {
        setIsLoading(false);
        setIsInitialized(true);
      }
    },
    [positionOptions, typeOptions, allOffers, users]
  );*/

  useEffect(
    () => {
      if (!!users && (nbUsers >= 0) && (nbUsers >= 0)) {
        setIsInitialized(true);
        setIsLoading(false);
        setTimeout(() => {
          setLoadMore(false);
        }, 500)
      }
    },
    [users]
  );

  const validateUser = (index, userId, validated, role) => {
    if (!isLoading) {
      setIsLoading(true);
      firestore.validateUser(userId, validated, role).then(() => {
        setSuccess({ message: validated ? 'userValidated' : 'userUnvalidated' });
        const newUsers = [...users];
        newUsers[index].validated = validated;
        setUsers([...newUsers]);
        setError(null);
        setIsLoading(false);
      }).catch((e) => {
        setError(e);
        setSuccess(null);
        setIsLoading(false);
      });
    }
  }

  const deleteUser = (userId, role) => {
    if (!isLoading) {
      setIsLoading(true);
      firestore.deleteUser(userId, role).then(() => {
        setSuccess({ message: 'userDeletionSuccess' });
        setNbUsersNotDeleted(nbUsersNotDeleted - 1);
        const newUsers = users.filter(({ id }) => (id !== userId));
        setUsers([...newUsers]);
        setError(null);
        setIsLoading(false);
      }).catch((e) => {
        setError(e);
        setSuccess(null);
        setIsLoading(false);
      });
    }
  }

  const search = (queryToSearch) => {
    if (!isLoading) {
      setSearchLoading(true);
      resetUsers();
      setTimeout(() => {
        const validatedFilterTreated = (validatedFilter === 'all') ? '' : validatedFilter;
        const roleFilterTreated = (roleFilter === 'all') ? '' : roleFilter;
        firestore.searchUsers(queryToSearch, validatedFilterTreated, roleFilterTreated).then(dbUsers => {
          setAllLoaded(true);
          setUsers([...dbUsers]);
          setError(null);
          setSearchLoading(false);
        }).catch((e) => {
          setError(e);
          setUsers([]);
          setSearchLoading(false);
        });
      }, 300)
    }
  };

  useEffect(
    () => {
      if (queryHasChangedOnce && query.length > 3) {
        search(query)
      }
    },
    [queryHasChangedOnce, query],
  );

  useEffect(
    () => {
      if (query.length > 0) {
        setQueryHasChangedOnce(true);
      } else {
        setSearchLoading(false);
        getNbUsers();
      }
    },
    [query],
  );

  return {
    users,
    setUsers,
    validatedFilter,
    setValidatedFilter,
    roleFilter,
    setRoleFilter,
    validateUser,
    deleteUser,
    isLoading,
    isFiltering,
    loadMore,
    setLoadMore,
    allLoaded,
    search,
    setEventQuery,
    searchLoading,
    query,
  };
};

export default useAdminUserControl;
