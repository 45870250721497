import React from 'react';
import {
  withRouter,
  Link,
} from 'react-router-dom';
/* STYLE */
import {
  Grid,
  Form,
  Button,
  TextArea,
  Select,
  Checkbox,
} from 'semantic-ui-react';
/* TRANSLATIONS */
import {
  translate,
} from 'react-multi-lang';
/* LOGIC */
import useJobOfferCreate from '../../logic/jobs/useJobOfferCreate';

import ResponsiveContainer from '../../components/containers/ResponsiveContainer';
import PageHeader from '../../components/headers/PageHeader';
import bgName from '../../img/bg/membership-bg.jpg';
import {
  JOB_OFFERS,
} from '../../constants/routes';
import {
  generateJobTypes,
  generateJobDomains,
  JOB_XP,
} from '../../constants/jobs';
import {
  generateMonths,
  YEARS,
} from '../../constants/datetimes';

const JobOfferAddPage = ({
  history,
  t,
}) => {
  const {
    name,
    type,
    domain,
    position,
    city,
    description,
    startMonth,
    startYear,
    endMonth,
    endYear,
    hasEndDate,
    requiredXP,
    company,
    contactPhoneNumber,
    contactEmail,
    setName,
    setType,
    setDomain,
    setPosition,
    setCity,
    setDescription,
    setStartMonth,
    setStartYear,
    setEndMonth,
    setEndYear,
    setHasEndDate,
    setRequiredXP,
    setCompany,
    setContactPhoneNumber,
    setContactEmail,
    createJobOffer,
    isDisabled,
    isLoading,
  } = useJobOfferCreate(t);
  return (
    <ResponsiveContainer
      minHeight={300}
      bgName={bgName}
      header={
        <PageHeader
          header="addJobOffer"
        />
      }
    >
      <Grid textAlign="center" verticalAlign="top" divided stackable className="padded">
        <Grid.Row>
          <Grid.Column width={4}>
              <Button
                as={Link}
                to={JOB_OFFERS}
                color="red"
                size="huge"
                basic
                content={t('backToJobOffers')}
                icon="left arrow"
                labelPosition="left"
              />
          </Grid.Column>
          <Grid.Column  width={11} textAlign="left">
            <Form size="big" loading={isLoading}>
              <span className="label">{t('offerName')}</span>
              <Form.Input
                fluid
                icon="briefcase"
                value={name}
                onChange={(e, { value }) => setName(`${value}`)}
                iconPosition="left"
                placeholder={t('offerName')}
              />
              <span className="label">{t('offerType')}</span>
              <Select
                placeholder={t('type')}
                options={generateJobTypes(t)}
                defaultValue={type}
                onChange={(e, { value }) => setType(`${value}`)}
                className="full-width margin-bottom-1em"
              />
              <span className="label">{t('domain')}</span>
              <Select
                placeholder={t('domain')}
                options={generateJobDomains(t)}
                defaultValue={domain}
                onChange={(e, { value }) => setDomain(`${value}`)}
                className="full-width margin-bottom-1em"
              />
              <span className="label">{t('company')}</span>
              <Form.Input
                fluid
                icon="building"
                value={company}
                onChange={(e, { value }) => setCompany(`${value}`)}
                iconPosition="left"
                placeholder={t('Darrois Villey Maillot Brochier')}
              />
              <span className="label">{t('position')}</span>
              <Form.Input
                fluid
                icon="sitemap"
                value={position}
                onChange={(e, { value }) => setPosition(`${value}`)}
                iconPosition="left"
                placeholder={t('lawyer')}
              />
              <span className="label">{t('city')}</span>
              <Form.Input
                fluid
                icon="map"
                value={city}
                onChange={(e, { value }) => setCity(`${value}`)}
                iconPosition="left"
                placeholder={t('Paris')}
              />
              <span className="label">{t('startDate')}</span>
              <Form.Group widths="equal">
                <Select
                  placeholder={t('startMonth')}
                  options={generateMonths(t)}
                  defaultValue={startMonth}
                  onChange={(e, { value }) => setStartMonth(`${value}`)}
                  className="full-width margin-bottom-1em"
                />
                <Select
                  placeholder={t('type')}
                  options={YEARS}
                  defaultValue={startYear}
                  onChange={(e, { value }) => setStartYear(value)}
                  className="full-width margin-bottom-1em"
                />
              </Form.Group>
              <span className="label">{t('hasEndDate')}</span>
              <Checkbox
                checked={hasEndDate}
                onChange={(e, { checked }) => setHasEndDate(checked)}
                toggle
              />
              <span className="label-no-margin">
                {
                  hasEndDate
                    ? t('yes')
                    : t('no')
                }
              </span>
              {hasEndDate && (
                <React.Fragment>
                  <span className="label">{t('endDate')}</span>
                  <Form.Group widths="equal">
                    <Select
                      placeholder={t('startMonth')}
                      options={generateMonths(t)}
                      defaultValue={endMonth}
                      onChange={(e, { value }) => setEndMonth(`${value}`)}
                      className="full-width margin-bottom-1em"
                    />
                    <Select
                      placeholder={t('type')}
                      options={YEARS}
                      defaultValue={endYear}
                      onChange={(e, { value }) => setEndYear(value)}
                      className="full-width margin-bottom-1em"
                    />
                  </Form.Group>
                </React.Fragment>
              )}
              <span className="label">{t('contactPhoneNumber')}</span>
              <Form.Input
                fluid
                icon="phone"
                value={contactPhoneNumber}
                onChange={(e, { value }) => setContactPhoneNumber(`${value}`)}
                iconPosition="left"
                placeholder={t('01 45 02 19 19')}
              />
              <span className="label">{t('contactEmail')}</span>
              <Form.Input
                fluid
                icon="at"
                value={contactEmail}
                onChange={(e, { value }) => setContactEmail(`${value}`)}
                iconPosition="left"
                placeholder={t('contact@darroisvilley.com')}
              />
              <span className="label">{t('requiredXP')}</span>
              <Select
                placeholder={t('type')}
                options={JOB_XP}
                defaultValue={requiredXP}
                onChange={(e, { value }) => setRequiredXP(value)}
                className="full-width margin-bottom-1em"
              />
              <span className="label">{t('description')}</span>
              <TextArea
                value={description}
                onChange={(e, { value }) => setDescription(`${value}`)}
                placeholder={t('description')}
                rows={10}
              />
              <Button
                color="red"
                fluid
                size="massive"
                disabled={isDisabled}
                onClick={() => createJobOffer()}
                content={t('addJobOffer')}
              />
            </Form>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </ResponsiveContainer>
  );
};

export default withRouter(translate(JobOfferAddPage));
