// Created by François LELIEVRE & Matthieu MARIE-LOUISE
// Copyrights KOMDAN SAS - 2019

import React from 'react';
/* ROUTING */
import {
  BrowserRouter as Router,
  Route,
  Switch,
} from 'react-router-dom';
/* LOGIC */
import { UseIsMobileProvider } from './logic/contexts/UseIsMobileContext';
import { UseToastsProvider } from './logic/contexts/UseToastsContext';
import {
  UseAuthUserProvider,
  UseAuthUserContext,
} from './logic/contexts/UseAuthUserContext';
/* STYLE */
import './App.css';
import 'semantic-ui-css/semantic.min.css'
import 'react-toastify/dist/ReactToastify.css';
/* ROUTES */
import * as ROUTES from './constants/routes';
/* COMPONENTS */
import UnauthenticatedLandingPage from './views/unauthenticated/UnauthenticatedLandingPage';
import { PartnershipsPage } from './views/unauthenticated/PartnershipsPage';
import DiplomaPage from './views/unauthenticated/DiplomaPage';
import PresidentWordPage from './views/unauthenticated/PresidentWordPage';
import AdministrationCouncilPage from './views/unauthenticated/AdministrationCouncilPage';
import MembershipPage from './views/unauthenticated/MembershipPage';
// import PresentationPage from './views/unauthenticated/PresentationPage';
import HumanitiesAwardPage from './views/unauthenticated/HumanitiesAwardPage';
import BoursePage from './views/unauthenticated/BoursePage';
import JobFairPage from './views/unauthenticated/JobFairPage';
import GraduateSignupPage from './views/unauthenticated/GraduateSignupPage';
import LawPracticeSignupPage from './views/unauthenticated/LawPracticeSignupPage';
import LoginPage from './views/unauthenticated/LoginPage';
import RecoverPasswordPage from './views/unauthenticated/RecoverPasswordPage';
import ContactPage from './views/unauthenticated/ContactPage';
import LegalPage from './views/unauthenticated/LegalPage';

import AuthenticatedHomePage from './views/authenticated/AuthenticatedHomePage';
import GraduateAccountPage from './views/authenticated/GraduateAccountPage';
import LawPracticeAccountPage from './views/authenticated/LawPracticeAccountPage';
import DirectoryPage from './views/authenticated/DirectoryPage';
import JobOffersPage from './views/authenticated/JobOffersPage';
import MyJobOffersPage from './views/authenticated/MyJobOffersPage';
import JobOfferAddPage from './views/authenticated/JobOfferAddPage';
import JobOfferUpdatePage from './views/authenticated/JobOfferUpdatePage';
import NewsAddPage from './views/authenticated/NewsAddPage';
import NewsUpdatePage from './views/authenticated/NewsUpdatePage';
import NewsPage from './views/authenticated/NewsPage';

import AdminPanelPage from './views/authenticated/adminPanel/AdminPanelPage';

import FullPageLoader from './views/loaders/FullPageLoader';

/* NOTIFICATIONS */
import { toast } from 'react-toastify';

toast.configure();


const unauthenticatedRoutes = [
  {
    path: ROUTES.LOADING,
    component: FullPageLoader,
  },
  {
    path: ROUTES.PARTNERSHIPS,
    component: PartnershipsPage,
  },
  {
    path: ROUTES.DIPLOMA,
    component: DiplomaPage,
  },
  {
    path: ROUTES.PRESIDENT_WORD,
    component: PresidentWordPage,
  },
  {
    path: ROUTES.CONTACT,
    component: ContactPage,
  },
  {
    path: ROUTES.LEGAL,
    component: LegalPage,
  },
  {
    path: ROUTES.ADMINISTRATION_COUNCIL,
    component: AdministrationCouncilPage,
  },
  {
    path: ROUTES.MEMBERSHIP,
    component: MembershipPage,
  },
  {
    path: ROUTES.JOBFAIR,
    component: JobFairPage,
  },
  {
    path: ROUTES.JOBFAIR_EVENTS,
    component: JobFairPage,
    category: 'jobFair',
  },
  /*{
    path: ROUTES.PRESENTATION,
    component: PresentationPage,
  },*/
  {
    path: ROUTES.HUMANITIES_AWARD,
    component: HumanitiesAwardPage,
  },
  {
    path: ROUTES.BOURSE,
    component: BoursePage,
  },
  {
    path: ROUTES.UNAUTHENTICATED_LANDING,
    component: UnauthenticatedLandingPage,
  },
  {
    path: ROUTES.GRADUATE_SIGN_UP,
    component: GraduateSignupPage,
  },
  {
    path: ROUTES.LAW_PRACTICE_SIGN_UP,
    component: LawPracticeSignupPage,
  },
  {
    path: ROUTES.NEWS,
    component: NewsPage,
  },
  {
    path: ROUTES.SIGN_IN,
    component: LoginPage,
  },
  {
    path: ROUTES.RECOVER_MY_PASSWORD,
    component: RecoverPasswordPage,
  },
];

const graduateRoutes = [
  {
    path: ROUTES.LOADING,
    component: FullPageLoader,
  },
  {
    path: ROUTES.PARTNERSHIPS,
    component: PartnershipsPage,
  },
  {
    path: ROUTES.DIPLOMA,
    component: DiplomaPage,
  },
  {
    path: ROUTES.CONTACT,
    component: ContactPage,
  },
  {
    path: ROUTES.LEGAL,
    component: LegalPage,
  },
  {
    path: ROUTES.NEWS,
    component: NewsPage,
  },
  {
    path: ROUTES.JOBFAIR,
    component: JobFairPage,
  },
  {
    path: ROUTES.JOBFAIR_EVENTS,
    component: JobFairPage,
    category: 'jobFair',
  },
  {
    path: ROUTES.PRESIDENT_WORD,
    component: PresidentWordPage,
  },
  {
    path: ROUTES.ADMINISTRATION_COUNCIL,
    component: AdministrationCouncilPage,
  },
  {
    path: ROUTES.MEMBERSHIP,
    component: MembershipPage,
  },
  /*{
    path: ROUTES.PRESENTATION,
    component: PresentationPage,
  },*/
  {
    path: ROUTES.HUMANITIES_AWARD,
    component: HumanitiesAwardPage,
  },
  {
    path: ROUTES.BOURSE,
    component: BoursePage,
  },
  {
    path: ROUTES.DIRECTORY,
    component: DirectoryPage,
  },
  {
    path: ROUTES.MY_ACCOUNT,
    component: GraduateAccountPage,
  },
  {
    path: ROUTES.JOB_OFFERS,
    component: JobOffersPage,
  },
];

const lawPracticeRoutes = [
{
    path: ROUTES.LOADING,
    component: FullPageLoader,
  },
  {
    path: ROUTES.PARTNERSHIPS,
    component: PartnershipsPage,
  },
  {
    path: ROUTES.CONTACT,
    component: ContactPage,
  },
  {
    path: ROUTES.LEGAL,
    component: LegalPage,
  },
  {
    path: ROUTES.NEWS,
    component: NewsPage,
  },
  {
    path: ROUTES.DIPLOMA,
    component: DiplomaPage,
  },
  {
    path: ROUTES.JOBFAIR,
    component: JobFairPage,
  },
  {
    path: ROUTES.JOBFAIR_EVENTS,
    component: JobFairPage,
    category: 'jobFair',
  },
  {
    path: ROUTES.PRESIDENT_WORD,
    component: PresidentWordPage,
  },
  {
    path: ROUTES.ADMINISTRATION_COUNCIL,
    component: AdministrationCouncilPage,
  },
  {
    path: ROUTES.MEMBERSHIP,
    component: MembershipPage,
  },
  /*{
    path: ROUTES.PRESENTATION,
    component: PresentationPage,
  },*/
  {
    path: ROUTES.HUMANITIES_AWARD,
    component: HumanitiesAwardPage,
  },
  {
    path: ROUTES.BOURSE,
    component: BoursePage,
  },
  {
    path: ROUTES.DIRECTORY,
    component: DirectoryPage,
  },
  {
    path: ROUTES.MY_ACCOUNT,
    component: LawPracticeAccountPage,
  },
  {
    path: ROUTES.JOB_OFFERS,
    component: JobOffersPage,
  },
  {
    path: ROUTES.JOB_OFFERS_ADD,
    component: JobOfferAddPage,
  },
  {
    path: ROUTES.MY_JOB_OFFERS,
    component: MyJobOffersPage,
  },
  {
    path: ROUTES.MY_JOB_OFFER_UPDATE,
    component: JobOfferUpdatePage,
  },
];

const adminRoutes = [
  {
    path: ROUTES.LOADING,
    component: FullPageLoader,
  },
  {
    path: ROUTES.NEWS,
    component: NewsPage,
  },
  {
    path: ROUTES.PARTNERSHIPS,
    component: PartnershipsPage,
  },
  {
    path: ROUTES.CONTACT,
    component: ContactPage,
  },
  {
    path: ROUTES.LEGAL,
    component: LegalPage,
  },
  {
    path: ROUTES.DIPLOMA,
    component: DiplomaPage,
  },
  {
    path: ROUTES.JOBFAIR,
    component: JobFairPage,
  },
  {
    path: ROUTES.JOBFAIR_EVENTS,
    component: JobFairPage,
    category: 'jobFair',
  },
  {
    path: ROUTES.PRESIDENT_WORD,
    component: PresidentWordPage,
  },
  {
    path: ROUTES.ADMINISTRATION_COUNCIL,
    component: AdministrationCouncilPage,
  },
  {
    path: ROUTES.MEMBERSHIP,
    component: MembershipPage,
  },
  /*{
    path: ROUTES.PRESENTATION,
    component: PresentationPage,
  },*/
  {
    path: ROUTES.HUMANITIES_AWARD,
    component: HumanitiesAwardPage,
  },
  {
    path: ROUTES.BOURSE,
    component: BoursePage,
  },
  {
    path: ROUTES.DIRECTORY,
    component: DirectoryPage,
  },
  {
    path: ROUTES.ADMIN_PANEL,
    component: AdminPanelPage,
  },
  {
    path: ROUTES.ADMIN_JOBS_PANEL,
    component: AdminPanelPage,
    activeMenuProp: 'jobOffers',
  },
  {
    path: ROUTES.ADMIN_NEWS_PANEL,
    component: AdminPanelPage,
    activeMenuProp: 'news',
  },
  {
    path: ROUTES.JOB_OFFERS,
    component: JobOffersPage,
  },
  {
    path: ROUTES.JOB_OFFERS_ADD,
    component: JobOfferAddPage,
  },
  {
    path: ROUTES.NEWS_ADD,
    component: NewsAddPage,
  },
  {
    path: ROUTES.NEWS_UPDATE_PAGE,
    component: NewsUpdatePage,
  },
  {
    path: ROUTES.MY_JOB_OFFER_UPDATE,
    component: JobOfferUpdatePage,
  },
  {
    path: '*',
    component: AuthenticatedHomePage,
  },
];

const AppWithProvider = () => (
  <UseToastsProvider>
    <UseIsMobileProvider>
      <UseAuthUserProvider>
        <App />
      </UseAuthUserProvider>
    </UseIsMobileProvider>
  </UseToastsProvider>
);

const App = () => {
  const {
    authUser,
  } = React.useContext(UseAuthUserContext);
  return !!authUser
    ? <AuthenticatedApp />
    : <UnauthenticatedApp />
};

const getRoutesFromRole = (role) => {
  switch(role) {
    case "GRADUATE": {
      return [
        ...graduateRoutes,
        {
          path: '*',
          component: AuthenticatedHomePage,
        },
      ]
    }
    case "ADMIN": {
      return [
        ...adminRoutes,
        {
          path: '*',
          component: AuthenticatedHomePage,
        },
      ]
    }
    case "LAW_PRACTICE": {
      return [
        ...lawPracticeRoutes,
        {
          path: '*',
          component: AuthenticatedHomePage,
        },
      ];
    }
    case "NONE": {
      return [
        ...unauthenticatedRoutes,
        {
          path: '*',
          component: UnauthenticatedLandingPage,
        },
      ];
    }
    default: {
      return [
        ...unauthenticatedRoutes,
        {
          path: '*',
          component: UnauthenticatedLandingPage,
        },
      ];
    }
  }
};

const AuthenticatedApp = () => {
  const {
    authUser,
  } = React.useContext(UseAuthUserContext);
  const role = !!authUser ? authUser.role : "NONE";
  const routes = getRoutesFromRole(role);
  return (
    <Router>
      <Switch>
        {routes.map((
          {
            path,
            component,
            ...rest
          },
        ) => {
          return (
          (Object.entries(rest).length === 0 && rest.constructor === Object)
            ? (
              <Route
                key={path}
                exact
                path={path}
                component={component}
              />
            ) : (
              (rest.category !== undefined)
                ? (
                  <Route
                    key={path}
                    exact
                    path={path}
                    render={({
                      category,
                    }) => (
                      <NewsPage {...rest} />
                    )}
                  />
                )
                : (
                  <Route
                    key={path}
                    exact
                    path={path}
                    render={({
                      activeMenuProp,
                    }) => (
                      <AdminPanelPage {...rest} />
                    )}
                  />
                )
            )
        )})}
      </Switch>
    </Router>
  );
};

const UnauthenticatedApp = () => (
  <Router>
    <Switch>
      {[
        ...unauthenticatedRoutes,
        {
          path: '*',
          component: UnauthenticatedLandingPage,
        },
      ].map((
        {
          path,
          component,
          ...rest
        },
      ) => {
        return (
          (Object.entries(rest).length === 0 && rest.constructor === Object)
            ? (
              <Route
                key={path}
                exact
                path={path}
                component={component}
              />
            ) : (
              <Route
                key={path}
                exact
                path={path}
                render={({
                  category,
                }) => (
                  <NewsPage {...rest} />
                )}
              />
            )
        )})}
    </Switch>
  </Router>
);

export default AppWithProvider;
