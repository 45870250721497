export const generateJobTypes = (t) => ([
  { key: 'internship', value: 'internship', text: t('internship') },
  { key: 'jurist', value: 'juristCDI', text: t('jurist') },
  { key: 'collaboration', value: 'collaboration', text: t('collaboration') },
]);

export const generateJobDomains = (t) => ([
  { key: 'companyRights', value: 'companyRights', text: t('companyRights') },
  { key: 'fiscalRights', value: 'fiscalRights', text: t('fiscalRights') },
  { key: 'concurrenceRights', value: 'concurrenceRights', text: t('concurrenceRights') },
  { key: 'referee', value: 'referee', text: t('referee') },
  { key: 'contentieux', value: 'contentieux', text: t('contentieux') },
  { key: 'restructuring', value: 'restructuring', text: t('restructuring') },
  { key: 'socialRights', value: 'socialRights', text: t('socialRights') },
  { key: 'finance', value: 'finance', text: t('finance') },
  { key: 'publicRights', value: 'publicRights', text: t('publicRights') },
  { key: 'other', value: 'other', text: t('other') },
]);


export const JOB_XP = [
  { key: 0, value: 0, text: 0 },
  { key: 1, value: 1, text: 1 },
  { key: 2, value: 2, text: 2 },
  { key: 3, value: 3, text: 3 },
  { key: 4, value: 4, text: 4 },
  { key: 5, value: 5, text: 5 },
  { key: 6, value: 6, text: 6 },
  { key: 7, value: 7, text: 7 },
  { key: 8, value: 8, text: 8 },
  { key: 9, value: 9, text: 9 },
  { key: 10, value: 10, text: 10 },
  { key: 11, value: 11, text: 11 },
  { key: 12, value: 12, text: 12 },
  { key: 13, value: 13, text: 13 },
  { key: 14, value: 14, text: 14 },
  { key: 15, value: 15, text: 15 },
]