import React from 'react';
import { translate } from 'react-multi-lang';
import {
  Container,
  Button,
  Segment,
} from 'semantic-ui-react';

import ResponsiveContainer from '../../components/containers/ResponsiveContainer';
import PageHeader from '../../components/headers/PageHeader';
import useScrollTopOnMount from '../../logic/ui/useScrollTopOnMount';
import bgName from '../../img/bg/humanities-award-bg.jpg';

import reglement from '../../files/andjce-prix-des-Humanités-règlement-2017-2018.pdf'
import form from '../../files/andjce-formulaire-candidature-prix-des-Humanités-.pdf'


const HumanitiesAwardPage = ({ t }) => {
  useScrollTopOnMount();
  return (
    <ResponsiveContainer
      minHeight={300}
      bgName={bgName}
      header={
        <PageHeader
          header="humanitiesAward"
        />
      }
    >
      <Container className="padded" text>
        <p>
          {t('humanitiesAwardText1')}
          <a href="http://www.andjce.com/">
            {t('andjceWebsiteUrl')}
          </a>
          {t('humanitiesAwardText3')}
          <br />
          <br />
          {t('humanitiesAwardText4')}
          <br />
          <br />
          {t('humanitiesAwardText5')}
          <br />
          <br />
          {t('humanitiesAwardText6')}
          <br />
          <br />
          {t('humanitiesAwardText7')}
          <br />
          {t('humanitiesAwardText8')}
          <br />
          {t('humanitiesAwardText9')}
          <br />
          <br />
          {t('humanitiesAwardText10')}
          <br />
          <br />
        </p>
        <Segment basic textAlign="center">
          <Button
            size="huge"
            color="red"
          >
            <a
              download
              className="color-white"
              href={form}
            >
            {t('humanitiesAwardText13')}
            </a>
          </Button>
          <Button
            size="huge"
            basic
            color="red"
          >
            <a
              download
              className="color-red"
              href={reglement}
            >
            {t('humanitiesAwardText12')}
            </a>
          </Button>
        </Segment>
      </Container>
    </ResponsiveContainer>
  );
};

export default translate(HumanitiesAwardPage);
