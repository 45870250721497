import React from 'react';
import {
  Sidebar,
  Container,
  Segment,
} from 'semantic-ui-react';
import useWaitForImgToLoad from '../../logic/ui/useWaitForImgToLoad';
import FullPageLoaderNoRedirect from '../../views/loaders/FullPageLoaderNoRedirect';
import MobileTopMenu from '../menu/MobileTopMenu';
import MobileSideMenu from '../menu/MobileSideMenu';
import Footer from '../../components/Footer';

const MobilePageHeader = ({ header, children, bgName, minHeight }) => {
  const [sidebarOpened, setSidebarOpened] = React.useState(false); 

  const handlePusherClick = () => {
    if (sidebarOpened) setSidebarOpened(false);
  };

  const handleToggle = () => setSidebarOpened(!sidebarOpened);

  const {
    imgLoaded,
  } = useWaitForImgToLoad(bgName);

  return imgLoaded
    ? (
      <Sidebar.Pushable>
        <MobileSideMenu
          sidebarOpened={sidebarOpened}
        />

        <Sidebar.Pusher
          dimmed={sidebarOpened}
          onClick={() => handlePusherClick()}
          style={{ minHeight: "100vh" }}
        >
          <Segment
            inverted
            textAlign="center"
            style={{ minHeight: minHeight, padding: "1em 0em", background: `url(${bgName}) no-repeat center center fixed`, backgroundSize: "cover"  }}
            vertical
          >
            <Container>
              <MobileTopMenu
                handleToggle={handleToggle}
              />
            </Container>
            {header}
          </Segment>
          {children}
          <Footer />
        </Sidebar.Pusher>
      </Sidebar.Pushable>
    )
    : <FullPageLoaderNoRedirect />;
};

export default MobilePageHeader;
