export const generateUserRoleOptions = (t) => (
  [
    { key: 'all', value: 'all', text: t('all') },
    { key: 'GRADUATE', value: 'GRADUATE', text: t('GRADUATE') },
    { key: 'LAW_PRACTICE', value: 'LAW_PRACTICE', text: t('LAW_PRACTICE') },
    { key: 'ADMIN', value: 'ADMIN', text: t('ADMIN') },
  ]
);

export const generateUserValidatedOptions = (t) => (
  [
    { key: 'all', value: 'all', text: t('all') },
    { key: true, value: true, text: t('isValidated') },
    { key: false, value: false, text: t('notValidated') },
  ]
);