import {
  useState,
  useEffect,
  useContext,
} from 'react';
import { firestore } from '../../firebase';
import { UseToastsContext } from '../contexts/UseToastsContext';
import { UseAuthUserContext } from '../contexts/UseAuthUserContext';
import { generateMonths } from '../../constants/datetimes';
import { scrollTop } from '../../utils/utils';
import useScrollTopOnMount from '../ui/useScrollTopOnMount';
import {
  generateJobTypes,
  generateJobDomains,
  JOB_XP,
} from '../../constants/jobs';

const useJobOfferCreate = (t)  => {
  useScrollTopOnMount();
  const {
    setError,
    setSuccess,
    isLoading,
    setIsLoading,
  } = useContext(UseToastsContext);

  const {
    authUser,
  } = useContext(UseAuthUserContext);

  const {
    id,
  } = authUser || {
    id: '',
  };
  const currentDate = new Date();
  const [name, setName] = useState('');
  const [type, setType] = useState(generateJobTypes(t)[0].value);
  const [domain, setDomain] = useState(generateJobDomains(t)[0].value);
  const [position, setPosition] = useState('');
  const [city, setCity] = useState('');
  const [description, setDescription] = useState('');
  const [startMonth, setStartMonth] = useState(generateMonths(t)[currentDate.getMonth()].value);
  const [startYear, setStartYear] = useState(currentDate.getFullYear());
  const [hasEndDate, setHasEndDate] = useState(true);
  const [endMonth, setEndMonth] = useState(generateMonths(t)[currentDate.getMonth()].value);
  const [endYear, setEndYear] = useState(currentDate.getFullYear() + 1);
  const [requiredXP, setRequiredXP] = useState(JOB_XP[0].value);
  const [company, setCompany] = useState('');
  const [contactPhoneNumber, setContactPhoneNumber] = useState('');
  const [contactEmail, setContactEmail] = useState('');
  const [isDisabled, setIsDisabled] = useState(true);

  useEffect(
    () => {
      setIsDisabled(
        name.trim() === ''
        || type.trim() === ''
        || domain.trim() === ''
        || position.trim() === ''
        || city.trim() === ''
        || description.trim() === ''
        || startMonth.trim() === ''
        || endMonth.trim() === ''
        || startYear < currentDate.getFullYear()
        || endYear < currentDate.getFullYear()
        || (hasEndDate && (startYear > endYear))
        || requiredXP < 0
        || company.trim() === ''
        || contactPhoneNumber.trim() === ''
        || contactEmail.trim() === ''
      );
    },
    [
      name,
      type,
      domain,
      position,
      city,
      description,
      startMonth,
      endMonth,
      startYear,
      endYear,
      requiredXP,
      company,
      contactPhoneNumber,
      contactEmail,
    ],
  );

  const resetForm = () => {
    setName('');
    setType(generateJobTypes(t)[0].value);
    setDomain(generateJobDomains(t)[0].value);
    setPosition('');
    setCity('');
    setDescription('');
    setStartMonth(generateMonths(t)[currentDate.getMonth()].value);
    setEndMonth(generateMonths(t)[currentDate.getMonth()].value);
    setStartYear(currentDate.getFullYear());
    setEndYear(currentDate.getFullYear() + 1);
    setRequiredXP(JOB_XP[0].value);
    setHasEndDate(false);
    setCompany('');
    setContactPhoneNumber('');
    setContactEmail('');
    scrollTop();
  };

  const createJobOffer = () => {
    if (!isLoading && !isDisabled && !!id) {
      setIsLoading(true);
      firestore.createJobOffer({
        name,
        type,
        domain,
        position,
        city,
        description,
        startMonth,
        startYear,
        endMonth,
        endYear,
        hasEndDate,
        requiredXP,
        company,
        contactPhoneNumber,
        contactEmail,
        createdBy: id,
        createdOn: firestore.createTimeStamp(),
      }).then(() => {
        setSuccess({ message: 'jobOfferCreationSuccess' });
        setError(null);
        resetForm();
        setIsLoading(false);
      }).catch((e) => {
        setError(e);
        setSuccess(null);
        setIsLoading(false);
      })
    } else {
      setError({ message: 'fillAllInfo' });
    }
  };

  return {
    name,
    type,
    domain,
    position,
    city,
    description,
    startMonth,
    startYear,
    endMonth,
    endYear,
    hasEndDate,
    requiredXP,
    company,
    contactPhoneNumber,
    contactEmail,
    setName,
    setType,
    setDomain,
    setPosition,
    setCity,
    setDescription,
    setStartMonth,
    setStartYear,
    setEndMonth,
    setEndYear,
    setHasEndDate,
    setRequiredXP,
    setCompany,
    setContactPhoneNumber,
    setContactEmail,
    createJobOffer,
    isDisabled,
    isLoading,
   };
};

export default useJobOfferCreate;
