import {
  useState,
  useEffect,
  useContext,
} from 'react';
import { auth, firestore } from '../../firebase';
import { LOADING } from '../../constants/routes';
import { UseToastsContext } from '../contexts/UseToastsContext';

const useGraduateSignup = (history)  => {
  const {
    setError,
    setSuccess,
    isLoading,
    setIsLoading,
  } = useContext(UseToastsContext);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [name, setNameLow] = useState('');
  const [surname, setSurname] = useState('');
  const [position, setPosition] = useState('');
  const [corporateName, setCorporateName] = useState('');
  const [country, setCountry] = useState('');
  const [city, setCity] = useState('');
  const [address, setAddress] = useState('');
  const [zipCode, setZipCode] = useState('');
  const [isDisabled, setIsDisabled] = useState(true);

  const setName = (name) => {
    setNameLow(name.toUpperCase())
  }

  useEffect(
    () => {
      setIsDisabled(
        email.trim() === ''
        || password.length < 6
        || name.trim() === ''
        || surname.trim() === ''
        || corporateName.trim() === ''
        || address.trim() === ''
        || zipCode.trim() === ''
        || city.trim() === ''
        || country.trim() === ''
      );
    },
    [email, password, name, surname, corporateName, address, zipCode, city, country],
  );

  const signUp = (): void => {
    if (isLoading || isDisabled) {
      setError({ message: 'fillAllInformation' });
    }
    else {
      setIsLoading(true);
      auth.createUserWithEmailAndPassword(email, password)
        .then(({ user }) => {
          if (user && user.uid) {
            firestore.createUser(
              user.uid,
              {
                email,
                surname,
                name: name.toUpperCase(),
                position,
                corporateName,
                address,
                city,
                zipCode,
                country,
                createdOn: firestore.createTimeStamp(),
                updatedOn: firestore.createTimeStamp(),
                role: 'LAW_PRACTICE',
                validate: false,
              },
            ).then(() => {
              setSuccess({ message: 'loginSuccess' });
              setIsLoading(false);
              history.push(LOADING);
            }).catch((e) => {
              setError(e);
              setIsLoading(false);
            });
          } else {
            setError({ message: 'couldNotCreateUser' });
            setIsLoading(false);
          }
        }).catch((e) => {
          setError(e);
          setIsLoading(false);
        });
    }
  };

  return {
    email,
    setEmail,
    password,
    setPassword,
    name,
    setName,
    surname,
    setSurname,
    position,
    setPosition,
    address,
    setAddress,
    city,
    setCity,
    zipCode,
    setZipCode,
    country,
    setCountry,
    corporateName,
    setCorporateName,
    isDisabled,
    isLoading,
    signUp,
  };
};

export default useGraduateSignup;

