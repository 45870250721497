import moment from 'moment';
import 'moment/locale/fr';
import { getLanguage } from 'react-multi-lang';

export const scrollTop = () => {
  try {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  } catch (e) {
    // just a fallback for older browsers
    window.scrollTo(0, 0);
  }
};

export const timeToLT = (timestamp, format) => {
  const firestoreTimestampToDate = ({
    seconds = 0,
    nanoseconds = 0,
  } = {}) => new Date(
    seconds * 1000 + nanoseconds / 1000000,
  )

  moment.locale('fr');
  return moment(firestoreTimestampToDate(timestamp), 'X').format(format);
};