import React from 'react';
import { translate } from 'react-multi-lang';
import {
  Header,
  Container,
  Card,
  Image,
} from 'semantic-ui-react';
import ResponsiveContainer from '../../components/containers/ResponsiveContainer';
import PageHeader from '../../components/headers/PageHeader';
import useScrollTopOnMount from '../../logic/ui/useScrollTopOnMount';
import useWaitForImgToLoad from '../../logic/ui/useWaitForImgToLoad';
import FullPageLoaderNoRedirect from '../../views/loaders/FullPageLoaderNoRedirect';

import bgName from '../../img/bg/administration-council-bg.jpg';

import charles from '../../img/council/charlesDuCouedicDeKererant.png';
import gautier from '../../img/council/gautierValdiguie.png';
import remi from '../../img/president/remiLorrain.png';
import CB from '../../img/council/CB.png';
import CL from '../../img/council/CL.png';
import EM from '../../img/council/EM.png';
import EB from '../../img/council/EB.png';
import GH from '../../img/council/GH.png';
import wenceslas from '../../img/council/wenceslasLeChevalier.png';
import GV from '../../img/council/GV.png';
import KT from '../../img/council/KT.png';
import LT from '../../img/council/LT.png';
import MS from '../../img/council/MS.png';
import OB from '../../img/council/OB.png';
import SC from '../../img/council/SC.png';

const actualMembers = [
  {"name":"Charles du COUËDIC de KERÉRANT","position":"councilText6","role":"Secrétaire général de l'ANDJCE","img":charles},
  {"name":"Christophe LEFAILLET","position":"councilText9","role":"","img":CL},
  {"name":"Cindy BARRET","position":"councilText3","role":"","img":CB},
  {"name":"Etienne MATHEY","position":"councilText11","role":"","img":EM},
  {"name":"Evelyne BAGDASSARIAN","position":"councilText2","role":"","img":EB},
  {"name":"Gautier VALDIGUIE","position":"councilText15","role":"Trésorier de l'ANDJCE","img":gautier},
  {"name":"Guillaume HECKETSWEILER","position":"councilText7","role":"","img":GH},
  {"name":"Guillaume VITRICH","position":"councilText16","role":"","img":GV},
  {"name":"Kadiatou TAPILY","position":"councilText13","role":"","img":KT},
  {"name":"Laurent TISSOT","position":"councilText14","role":"","img":LT},
  {"name":"Michel STRUK","position":"councilText12","role":"","img":MS},
  {"name":"Olivier BILLARD","position":"councilText4","role":"","img":OB},
  {"name":"Rémi LORRAIN","position":"councilText10","role":"Président de l'ANDJCE","img":remi},
  {"name":"Sophie CONDROYER","position":"councilText5","role":"","img":SC},
  {"name":"Wenceslas LE CHEVALIER","position":"councilText8","role":"Vice-Président de l'ANDJCE","img":wenceslas},
];

const prevPresidents = [
  {"name":"Christian LAVABRE","position":"councilText20"},
  {"name":"Evelyne BAGDASSARIAN","position":"councilText19"},
  {"name":"François BARRIÈRE","position":"councilText18"},
  {"name":"Paul LIGNIERES","position":"councilText21"}
];


const AdministrationCouncilPage = ({ t }) => {

  useScrollTopOnMount();
  const {
    imgLoaded: remiLoaded,
  } = useWaitForImgToLoad(remi);
  const {
    imgLoaded: wenceslasLoaded,
  } = useWaitForImgToLoad(wenceslas);
  const {
    imgLoaded: gautierLoaded,
  } = useWaitForImgToLoad(gautier);
  const {
    imgLoaded: charlesLoaded,
  } = useWaitForImgToLoad(charles);
  const {
    imgLoaded: CLLoaded,
  } = useWaitForImgToLoad(CL);
  const {
    imgLoaded: CBLoaded,
  } = useWaitForImgToLoad(CB);
  const {
    imgLoaded: EMLoaded,
  } = useWaitForImgToLoad(EM);
  const {
    imgLoaded: EBLoaded,
  } = useWaitForImgToLoad(EB);
  const {
    imgLoaded: GHLoaded,
  } = useWaitForImgToLoad(GH);
  const {
    imgLoaded: KTLoaded,
  } = useWaitForImgToLoad(KT);
  const {
    imgLoaded: LTLoaded,
  } = useWaitForImgToLoad(LT);
  const {
    imgLoaded: MSLoaded,
  } = useWaitForImgToLoad(MS);
  const {
    imgLoaded: OBLoaded,
  } = useWaitForImgToLoad(OB);
  const {
    imgLoaded: SCLoaded,
  } = useWaitForImgToLoad(SC);
  return (remiLoaded 
    && wenceslasLoaded 
    && gautierLoaded 
    && charlesLoaded 
    && CLLoaded 
    && CBLoaded 
    && EMLoaded 
    && EBLoaded 
    && GHLoaded 
    && KTLoaded 
    && LTLoaded 
    && MSLoaded 
    && OBLoaded 
    && SCLoaded) 
    ? (
      <ResponsiveContainer
        minHeight={300}
        bgName={bgName}
        header={
          <PageHeader
            header="administrationCouncil"
          />
        }
      >
        <Container className="padded" text>
          <Header as="h3" textAlign="center">
            {t('councilText1')}
          </Header>
          <Card.Group centered>
            {actualMembers.map(({ name, position, role, img }) => (
              <Card
                color="red"
                raised
                key={position}
                className="width-220px"
              >
                {img && (<Image src={img} wrapped ui={false} />)}
                <Card.Content textAlign="center">
                  <Card.Header>{name}</Card.Header>
                  <Card.Description>
                    <span>{t(position)}</span>
                  </Card.Description>
                  {(role !== '') && (
                    <Card.Meta>{role}</Card.Meta>
                  )}
                </Card.Content>
              </Card>
            ))}
          </Card.Group>
          <Header as="h3" textAlign="center">
            {t('councilText17')}
          </Header>
          <Card.Group stackable centered>
            {prevPresidents.map(({ name, position }) => (
                <Card
                  color="red"
                  key={position}
                >
                  <Card.Content textAlign="center">
                    <Card.Header>{name}</Card.Header>
                    <Card.Meta>
                      <span>{t(position)}</span>
                    </Card.Meta>
                  </Card.Content>
                </Card>
              ))}
          </Card.Group>
        </Container>
      </ResponsiveContainer>
    ) : (
      <FullPageLoaderNoRedirect />
    )
};

export default translate(AdministrationCouncilPage);
