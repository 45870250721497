import React from 'react';
import { translate } from 'react-multi-lang';
import {
  Header,
  Container,
  Segment,
} from 'semantic-ui-react';

import ResponsiveContainer from '../../components/containers/ResponsiveContainer';
import PageHeader from '../../components/headers/PageHeader';
import useScrollTopOnMount from '../../logic/ui/useScrollTopOnMount';
import bgName from '../../img/bg/diploma-bg.jpg';

const DiplomaPage = ({ t }) => {
  useScrollTopOnMount();
  return (
    <ResponsiveContainer
      minHeight={300}
      bgName={bgName}
      header={
        <PageHeader
          header="theDiploma"
        />
      }
    >
      <Container className="padded" text>
        <Header as="h3" color="black">
          {t('diplomaHeader')}
        </Header>
        <p>
        {t('diplomaText1')}
        <a href="http://www.fnde.asso.fr/index2.html">
          {t('diplomaText2')}
        </a>
        {t('diplomaText3')}
        <b>
          {t('diplomaText4')}
        </b>
        {t('diplomaText5')}
        {t('diplomaText6')}
        <br />
        <br />
        {t('diplomaText7')}
        <b>
          {t('diplomaText8')}
        </b>
        {t('diplomaText9')}
        <br />
        <br />
        {t('diplomaText10')}
        <b>
          {t('diplomaText11')}
        </b>
        {t('diplomaText12')}
        <br />
        <br />
        {t('diplomaText13')}
        <b>
          {t('diplomaText14')}
        </b>
        {t('diplomaText15')}
        <br />
        <br />
        {t('diplomaText16')}
        <br />
        <br />
        <b>
          {t('diplomaText17')}
        </b>
        <br />
        <br />
        <a href="http://www.djce.fr/">
          {t('diplomaText18')}
        </a>
        {t('diplomaText19')}
        <br />
        <br />
        <b>
          {t('diplomaText20')}
        </b>
        {t('diplomaText21')}
        </p>
        <Segment>
          <p>
            {t('diplomaText22')}
            <span className="color-red">
              {t('diplomaText23')}
            </span>
            {t('diplomaText24')}
          </p>
        </Segment>
      </Container>
    </ResponsiveContainer>
  );
};

export default translate(DiplomaPage);
