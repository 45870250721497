import { auth, firebaseAuth } from './firebase';

export const createUserWithEmailAndPassword = (
  email,
  password,
) => (
  auth.createUserWithEmailAndPassword(email, password)
);

export const signInWithEmailAndPassword = (
  email,
  password,
) => (
  auth.signInWithEmailAndPassword(email, password)
);

export const signOut = () => (
  auth.signOut()
);

export const sendPasswordResetEmail = (email) => (
  auth.sendPasswordResetEmail(email)
);

export const updatePassword = (password) => {
  const { currentUser } = auth || null;
  if (currentUser) {
    return currentUser.updatePassword(password);
  } else {
    return new Promise((resolve, reject) => {
      reject({ message: 'failedToFindCurrentUser'});
    });
  }
};

export const sendEmailVerification = () => {
  const url = process.env.REACT_APP_CONFIRMATION_EMAIL_REDIRECT || '';
  const { currentUser } = auth || null;
  if (currentUser && (url === '')) {
    return currentUser.sendEmailVerification({ url });
  }
  else {
    return new Promise((resolve, reject) => {
      reject({ message: 'failedToFindCurrentUser'});
    });
  }
};

export const reauthenticateWithCredential = (
  currentPassword,
) => {
  const { currentUser } = auth || null;
  if (currentUser && currentUser.email) {
    const credential = firebaseAuth.EmailAuthProvider.credential(currentUser.email, currentPassword);
    return currentUser.reauthenticateAndRetrieveDataWithCredential(credential);
  }
  else {
    return new Promise((resolve, reject) => {
      reject({ message: 'failedToFindCurrentUser'});
    });
  }
};
