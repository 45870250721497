import React from 'react';
import { withRouter } from 'react-router-dom';
/* STYLE */
import {
  Grid,
  Form,
  Button,
  TextArea,
} from 'semantic-ui-react';
/* TRANSLATIONS */
import {
  translate,
} from 'react-multi-lang';
/* IMAGE */
/* LOGIC */
import useContact from '../../logic/contact/useContact';


import ResponsiveContainer from '../../components/containers/ResponsiveContainer';
import PageHeader from '../../components/headers/PageHeader';
import useScrollTopOnMount from '../../logic/ui/useScrollTopOnMount';
import bgName from '../../img/bg/contact.jpg';

const LoginPage = ({
  history,
  t,
}) => {
  useScrollTopOnMount();
  const {
    isLoading,
    email,
    setEmail,
    name,
    setName,
    message,
    setMessage,
    isDisabled,
    sendContactMessage,
  } = useContact();
  return (
    <ResponsiveContainer
      minHeight={300}
      bgName={bgName}
      header={
        <PageHeader
          header="contact"
        />
      }
    >
      <Grid textAlign="center" verticalAlign="middle"  className="padded">
        <Grid.Column className="max-width-45em">
          <Form size="big" loading={isLoading}>
            <span className="label text-align-left">{t('surnameAndName')}</span>
            <Form.Input
              fluid
              icon="user"
              value={name}
              onChange={(e, { value }) => setName(`${value}`)}
              iconPosition="left"
              placeholder="Nicolas VOCAT"
            />
            <span className="label text-align-left">{t('email')}</span>
            <Form.Input
              fluid
              icon="at"
              value={email}
              onChange={(e, { value }) => setEmail(`${value}`)}
              iconPosition="left"
              placeholder={t('email')}
            />
            <span className="label text-align-left">{t('message')}</span>
            <TextArea
              value={message}
              onChange={(e, { value }) => setMessage(`${value}`)}
              placeholder={t('message')}
              rows={10}
            />
            <Button color="red" fluid size="big" disabled={isDisabled} onClick={() => sendContactMessage()}>
              {t('send')}
            </Button>
          </Form>
        </Grid.Column>
      </Grid>
    </ResponsiveContainer>
  );
};

export default withRouter(translate(LoginPage));
