import React, {
  useContext,
} from 'react';
import { translate } from 'react-multi-lang';
import BasicModal from './BasicModal';
import { UseIsMobileContext } from '../../logic/contexts/UseIsMobileContext';
/* CONSTANTS */
/* LOGIC */
import useAdminLawPracticeInfoUpdate from '../../logic/users/useAdminLawPracticeInfoUpdate';
/* STYLE */
import {
  Form,
  Button,
  Segment,
} from 'semantic-ui-react';

const LawPracticeInfoUpdateModal = ({
  isShowingModal,
  setIsShowingModal,
  id,
  currentName,
  currentSurname,
  currentPosition,
  currentCorporateName,
  currentCountry,
  currentCity,
  currentAddress,
  currentZipCode,
  users,
  setUsers,
  t,
}) => {
  const {
    name,
    setName,
    surname,
    setSurname,
    position,
    setPosition,
    address,
    setAddress,
    city,
    setCity,
    zipCode,
    setZipCode,
    country,
    setCountry,
    corporateName,
    setCorporateName,
    isDisabled,
    isLoading,
    updateLawPracticeInfo,
  } = useAdminLawPracticeInfoUpdate(
    id,
    currentName,
    currentSurname,
    currentPosition,
    currentCorporateName,
    currentCountry,
    currentCity,
    currentAddress,
    currentZipCode,
    users,
    setUsers,
  );
  const isMobile = useContext(UseIsMobileContext);
  return (
    <BasicModal
      isShowing={isShowingModal}
      onClose={() => setIsShowingModal(false)}
      headerText={name}
      headerIcon="user outline"
      content={
        <Segment>
          <Form size="big" loading={isLoading}>
                  <Form.Input
                    fluid
                    icon="user"
                    value={surname}
                    onChange={(e, { value }) => setSurname(`${value}`)}
                    iconPosition="left"
                    placeholder={t('surname')}
                  />
                  <Form.Input
                    fluid
                    icon="user"
                    value={name}
                    onChange={(e, { value }) => setName(`${value}`)}
                    iconPosition="left"
                    placeholder={t('name')}
                  />
                  <Form.Input
                    fluid
                    icon="warehouse"
                    value={corporateName}
                    onChange={(e, { value }) => setCorporateName(`${value}`)}
                    iconPosition="left"
                    placeholder={t('corporateName')}
                  />
                  <Form.Input
                    fluid
                    icon="certificate"
                    value={position}
                    onChange={(e, { value }) => setPosition(`${value}`)}
                    iconPosition="left"
                    placeholder={t('position')}
                  />
                  <Form.Input
                    fluid
                    icon="map"
                    value={address}
                    onChange={(e, { value }) => setAddress(`${value}`)}
                    iconPosition="left"
                    placeholder={t('address')}
                  />
                  <Form.Input
                    fluid
                    icon="map"
                    value={city}
                    onChange={(e, { value }) => setCity(`${value}`)}
                    iconPosition="left"
                    placeholder={t('city')}
                  />
                  <Form.Input
                    fluid
                    icon="map"
                    value={zipCode}
                    onChange={(e, { value }) => setZipCode(`${value}`)}
                    iconPosition="left"
                    placeholder={t('zipCode')}
                  />
                  <Form.Input
                    fluid
                    icon="map"
                    value={country}
                    onChange={(e, { value }) => setCountry(`${value}`)}
                    iconPosition="left"
                    placeholder={t('country')}
                  />
                  <Button color="olive" fluid size="big" disabled={isDisabled} onClick={() => updateLawPracticeInfo()}>
                    {t('update')}
                  </Button>
                </Form>
        </Segment>
      }
      hasBackButton
    />
  );
};

export default translate(LawPracticeInfoUpdateModal);
