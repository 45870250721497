import React, {
  useContext,
} from 'react';
import { translate } from 'react-multi-lang';
import BasicModal from './BasicModal';
import { UseIsMobileContext } from '../../logic/contexts/UseIsMobileContext';
/* CONSTANTS */
import { DJCE_CENTERS } from '../../constants/djceCenters';
/* LOGIC */
import useAdminGraduateInfoUpdate from '../../logic/users/useAdminGraduateInfoUpdate';
/* STYLE */
import {
  Form,
  Button,
  Select,
  Segment,
} from 'semantic-ui-react';

const GraduateInfoUpdateModal = ({
  isShowingModal,
  setIsShowingModal,
  id,
  currentName,
  currentSurname,
  currentLinkedinUrl,
  currentDjceCenter,
  currentPromoYear,
  users,
  setUsers,
  t,
}) => {
  const {
    name,
    setName,
    surname,
    setSurname,
    linkedinUrl,
    setLinkedinUrl,
    promoYear,
    setPromoYear,
    djceCenter,
    setDjceCenter,
    isDisabled,
    isLoading,
    updateGraduateAccountInfo,
  } = useAdminGraduateInfoUpdate(
    id,
    currentName,
    currentSurname,
    currentLinkedinUrl,
    currentDjceCenter,
    currentPromoYear,
    users,
    setUsers,
  );
  const isMobile = useContext(UseIsMobileContext);
  return (
    <BasicModal
      isShowing={isShowingModal}
      onClose={() => setIsShowingModal(false)}
      headerText={name}
      headerIcon="user outline"
      content={
        <Segment>
          <Form size="big" loading={isLoading}>
            <Form.Input
              fluid
              icon="user"
              value={surname}
              onChange={(e, { value }) => setSurname(`${value}`)}
              iconPosition="left"
              placeholder={t('surname')}
            />
            <Form.Input
              fluid
              icon="user"
              value={name}
              onChange={(e, { value }) => setName(`${value}`)}
              iconPosition="left"
              placeholder={t('name')}
            />
            <Form.Input
              fluid
              icon="linkedin"
              value={linkedinUrl}
              onChange={(e, { value }) => setLinkedinUrl(`${value}`)}
              iconPosition="left"
              placeholder={t('linkedin')}
            />
            <Form.Input
              fluid
              icon="certificate"
              value={promoYear}
              onChange={(e, { value }) => setPromoYear(`${value}`)}
              iconPosition="left"
              placeholder={t('promoYear')}
            />
            <Select
              placeholder={t('djceCenter')}
              options={DJCE_CENTERS}
              defaultValue={DJCE_CENTERS.find(center => center.value === djceCenter).value}
              onChange={(e, { value }) => setDjceCenter(`${value}`)}
              className="full-width margin-bottom-1em"
            />
            <Button color="olive" fluid size="big" disabled={isDisabled} onClick={() => updateGraduateAccountInfo()}>
              {t('update')}
            </Button>
          </Form>
        </Segment>
      }
      hasBackButton
    />
  );
};

export default translate(GraduateInfoUpdateModal);
