import React, {
  useEffect,
} from 'react';
import { translate } from 'react-multi-lang';
import { Dimmer, Loader } from 'semantic-ui-react';

const FullPageLoader = ({ t, history }) => {
  useEffect(
    () => {
      const timeOut = setTimeout(() => {
        // TO DO : CHANGE URL !!!
        window.open(`${process.env.REACT_APP_CONFIRMATION_EMAIL_REDIRECT}`,"_self")
      }, 5000);
      return () => clearTimeout(timeOut);
    },
    [],
  );
  return (
    <Dimmer active inverted>
      <Loader inverted size="massive">
        <p className="color-dark-red">
          { t('loading') }
        </p>
      </Loader>
    </Dimmer>
  );
};

export default translate(FullPageLoader);
