import React from 'react';
/* STYLE */
import {
  Grid,
  Select,
  Header,
  Icon,
  Card,
  Segment,
  Button,
  Label,
  Search,
} from 'semantic-ui-react';
import {
  debounce,
} from 'lodash';
/* TRANSLATIONS */
import {
  translate,
} from 'react-multi-lang';
import {
  DJCE_CENTERS,
  generatePromoYearsOptions,
} from '../../constants/djceCenters';
import uuidv4 from 'uuid/v4';

import ResponsiveContainer from '../../components/containers/ResponsiveContainer';
import PageHeader from '../../components/headers/PageHeader';
import useScrollTopOnMount from '../../logic/ui/useScrollTopOnMount';
import useDirectory from '../../logic/users/useDirectory';
import bgName from '../../img/bg/members-bg.jpg';
const DirectoryPage = ({
  history,
  t,
}) => {
  useScrollTopOnMount();
  const {
    promoYearFilter,
    setPromoYearFilter,
    graduates,
    djceCenterFilter,
    setDjceCenterFilter,
    isFiltering,
    isLoading,
    loadMore,
    setLoadMore,
    allLoaded,
    setEventQuery,
    searchLoading,
    query,
  } = useDirectory(t);
  return (
    <ResponsiveContainer
      minHeight={300}
      bgName={bgName}
      header={
        <PageHeader
          header="theDirectory"
        />
      }
    >
      {(isLoading)
        ? (
          <Grid textAlign="center" verticalAlign="middle"  className="padded">
            <Segment
              basic
              className="full-width"
              loading={true}
            />
          </Grid>
        ) : (
          <Grid  className="small-padded" stackable divided>
            <Grid.Row>
              <Grid.Column width={4}>
                <Segment basic compact className="full-width">
                  <Header content={t('filters')} as="h2" />
                  <span className="label">{t('djceCenter')}</span>
                  <Select
                    options={[{ key: 'all', value: 'all', text: t('all') }, ...DJCE_CENTERS]}
                    defaultValue={djceCenterFilter}
                    text={t(djceCenterFilter)}
                    className="full-width margin-bottom-1em"
                    onChange={(e, { value }) => setDjceCenterFilter(`${value}`)}
                  />
                  <span className="label">{t('promoYear')}</span>
                  <Select
                    options={generatePromoYearsOptions(t)}
                    defaultValue={promoYearFilter}
                    text={t(promoYearFilter)}
                    className="full-width margin-bottom-1em"
                    onChange={(e, { value }) => setPromoYearFilter(`${value}`)}
                  />
                  <span className="label">{t('search')}</span>
                  <Search
                    loading={searchLoading}
                    onSearchChange={debounce(setEventQuery, 500, {
                      leading: true,
                    })}
                    value={query}
                    showNoResults={false}
                    placeholder={t('name')}
                  />
                </Segment>
              </Grid.Column>
              <Grid.Column width={11}>
                {isFiltering
                  ? (
                    <Segment
                      basic
                      className="full-width"
                      loading={true}
                    />
                  ) : (
                    <React.Fragment>
                      <Card.Group>
                        {graduates.map(({ name, surname, linkedinUrl, djceCenter, promoYear, email, createdOn }) => (
                          <Card
                            centered
                            key={uuidv4()}
                            header={
                              <React.Fragment>
                                <Label ribbon color="black" content={`${djceCenter} - ${promoYear}`} />
                                <Header as="h2">
                                  {`${surname} ${name}`}
                                </Header>
                              </React.Fragment>
                            }
                            meta={
                              !!email && ( 
                                <React.Fragment>
                                  <Icon name="mail outline" /> {email}
                                </React.Fragment>
                              )
                            }
                            className="full-width"
                            extra={
                              (linkedinUrl === '')
                                ? (<React.Fragment>
                                    <Icon name='linkedin' color="blue"/> {t('linkedinNotCommunicated')}
                                  </React.Fragment>
                                ) : (
                                  <a href={linkedinUrl}>
                                    <Icon name='linkedin' color="blue"/>
                                    {t('seeOnLinkedin')}
                                  </a>
                                )
                            }
                          />
                        ))}
                      {(graduates.length === 0) && (
                        <Card
                          centered
                          className="full-width"
                          header={
                            <Header as="h4" textAlign="center" className="full-width">
                              <Icon name="search" />
                              {searchLoading ? t('searching') : t('noResult')}
                            </Header>
                          }
                        />
                      )}
                      </Card.Group>
                      {(!allLoaded && (query === '')) && (
                        <Button
                          disabled={allLoaded}
                          loading={loadMore}
                          onClick={() => setLoadMore(true)}
                          className="margin-top-1em"
                          content={t('loadMore')}
                          color="red"
                          basic={allLoaded}
                          size="big"
                        />
                      )}
                    </React.Fragment>
                  )
                }
              </Grid.Column>
            </Grid.Row>
          </Grid>
        )
      }
    </ResponsiveContainer>
  );
};

export default translate(DirectoryPage);
