export const generateMonths = (t) => ([
  { key: 'january', value: 'january', text: t('january')  },
  { key: 'february', value: 'february', text: t('february') },
  { key: 'march', value: 'march', text: t('march') },
  { key: 'april', value: 'april', text: t('april') },
  { key: 'may', value: 'may', text: t('may') },
  { key: 'june', value: 'june', text: t('june') },
  { key: 'july', value: 'july', text: t('july') },
  { key: 'august', value: 'august', text: t('august') },
  { key: 'september', value: 'september', text: t('september') },
  { key: 'october', value: 'october', text: t('october') },
  { key: 'november', value: 'november', text: t('november') },
  { key: 'december', value: 'december', text: t('december') },
]);

export const YEARS = [
  { key: 2019, value: 2019, text: 2019 },
  { key: 2020, value: 2020, text: 2020 },
  { key: 2021, value: 2021, text: 2021 },
  { key: 2022, value: 2022, text: 2022 },
  { key: 2023, value: 2023, text: 2023 },
  { key: 2024, value: 2024, text: 2024 },
  { key: 2025, value: 2025, text: 2025 },
  { key: 2026, value: 2026, text: 2026 },
  { key: 2027, value: 2027, text: 2027 },
  { key: 2028, value: 2028, text: 2028 },
  { key: 2029, value: 2029, text: 2029 },
  { key: 2030, value: 2030, text: 2030 },
  { key: 2031, value: 2031, text: 2031 },
  { key: 2032, value: 2032, text: 2032 },
  { key: 2033, value: 2033, text: 2033 },
  { key: 2034, value: 2034, text: 2034 },
  { key: 2035, value: 2035, text: 2035 },
  { key: 2036, value: 2036, text: 2036 },
  { key: 2037, value: 2037, text: 2037 },
  { key: 2038, value: 2038, text: 2038 },
  { key: 2039, value: 2039, text: 2039 },
  { key: 2040, value: 2040, text: 2040 },
  { key: 2041, value: 2041, text: 2041 },
  { key: 2042, value: 2042, text: 2042 },
  { key: 2043, value: 2043, text: 2043 },
  { key: 2044, value: 2044, text: 2044 },
  { key: 2045, value: 2045, text: 2045 },
  { key: 2046, value: 2046, text: 2046 },
  { key: 2047, value: 2047, text: 2047 },
  { key: 2048, value: 2048, text: 2048 },
  { key: 2049, value: 2049, text: 2049 },
];
