import {
  useState,
  useEffect,
  useContext,
} from 'react';
import { firestore } from '../../firebase';
import { UseToastsContext } from '../contexts/UseToastsContext';
import { UseAuthUserContext } from '../contexts/UseAuthUserContext';
import { generateMonths } from '../../constants/datetimes';
import { scrollTop } from '../../utils/utils';
import useScrollTopOnMount from '../ui/useScrollTopOnMount';
import {
  generateJobTypes,
  generateJobDomains,
  JOB_XP,
} from '../../constants/jobs';
import {
  MY_JOB_OFFERS,
} from '../../constants/routes';

const useJobOfferUpdate = (t, history, jobOfferId) => {
  useScrollTopOnMount();
  const {
    setError,
    setSuccess,
    isLoading,
    setIsLoading,
  } = useContext(UseToastsContext);

  const {
    authUser,
  } = useContext(UseAuthUserContext);

  const {
    id,
  } = authUser || {
    id: '',
  };

  const currentDate = new Date();
  const [name, setName] = useState('');
  const [type, setType] = useState(generateJobTypes(t)[0].value);
  const [domain, setDomain] = useState(generateJobDomains(t)[0].value);
  const [position, setPosition] = useState('');
  const [city, setCity] = useState('');
  const [description, setDescription] = useState('');
  const [startMonth, setStartMonth] = useState(generateMonths(t)[currentDate.getMonth()].value);
  const [startYear, setStartYear] = useState(currentDate.getFullYear());
  const [endMonth, setEndMonth] = useState(generateMonths(t)[currentDate.getMonth()].value);
  const [hasEndDate, setHasEndDate] = useState(true);
  const [endYear, setEndYear] = useState(currentDate.getFullYear() + 1);
  const [requiredXP, setRequiredXP] = useState(JOB_XP[0].value);
  const [company, setCompany] = useState('');
  const [contactPhoneNumber, setContactPhoneNumber] = useState('');
  const [contactEmail, setContactEmail] = useState('');
  const [isDisabled, setIsDisabled] = useState(true);

  useEffect(
    () => {
      setIsLoading(true);
      firestore.getJobOffer(jobOfferId).then(({
        name: currentName,
        type: currentType,
        domain: currentDomain,
        position: currentPosition,
        city: currentCity,
        description: currentDescription,
        startMonth: currentStartMonth,
        endMonth: currentEndMonth,
        startYear: currentStartYear,
        endYear: currentEndYear,
        hasEndDate: currentHasEndDate,
        requiredXP: currentRequiredXP,
        company: currentCompany,
        contactPhoneNumber: currentContactPhoneNumber,
        contactEmail: currentContactEmail,
      }) => {
        setName(currentName);
        setType(currentType);
        setDomain(currentDomain);
        setPosition(currentPosition);
        setCity(currentCity);
        setDescription(currentDescription);
        setStartMonth(currentStartMonth);
        setEndMonth(currentEndMonth);
        setStartYear(currentStartYear);
        setEndYear(currentEndYear);
        setHasEndDate(currentHasEndDate);
        setRequiredXP(currentRequiredXP);
        setCompany(currentCompany);
        setContactPhoneNumber(currentContactPhoneNumber);
        setContactEmail(currentContactEmail);
        setIsLoading(false);
      }).catch((e) => {
        setError(e);
        setIsLoading(false);
        history.push(MY_JOB_OFFERS);
      })
    },
    [],
  );

  useEffect(
    () => {
      setIsDisabled(
        name.trim() === ''
        || type.trim() === ''
        || domain.trim() === ''
        || position.trim() === ''
        || city.trim() === ''
        || description.trim() === ''
        || startMonth.trim() === ''
        || endMonth.trim() === ''
        || startYear < currentDate.getFullYear()
        || endYear < currentDate.getFullYear()
        || (hasEndDate && (startYear > endYear))
        || requiredXP < 0
        || company.trim() === ''
        || contactPhoneNumber.trim() === ''
        || contactEmail.trim() === ''
      );
    },
    [
      name,
      domain,
      type,
      position,
      city,
      description,
      startMonth,
      endMonth,
      startYear,
      endYear,
      requiredXP,
      company,
      contactPhoneNumber,
      contactEmail,
    ],
  );

  const updateJobOffer = () => {
    if (!isLoading && !isDisabled && !!id) {
      setIsLoading(true);
      firestore.updateJobOffer(
        jobOfferId,
        {
          name,
          type,
          domain,
          position,
          city,
          description,
          startMonth,
          startYear,
          endMonth,
          endYear,
          hasEndDate,
          requiredXP,
          company,
          contactPhoneNumber,
          contactEmail,
          updatedBy: id,
          updatedOn: firestore.createTimeStamp(),
        }
      ).then(() => {
        setSuccess({ message: 'jobOfferUpdateSuccess' });
        setError(null);
        setIsLoading(false);
        scrollTop();
      }).catch((e) => {
        setError(e);
        setSuccess(null);
        setIsLoading(false);
      })
    } else {
      setError({ message: 'fillAllInfo '});
    }
  };

  return {
    name,
    type,
    domain,
    position,
    city,
    description,
    startMonth,
    startYear,
    endMonth,
    endYear,
    hasEndDate,
    requiredXP,
    company,
    contactPhoneNumber,
    contactEmail,
    setName,
    setType,
    setDomain,
    setPosition,
    setCity,
    setDescription,
    setStartMonth,
    setStartYear,
    setEndMonth,
    setEndYear,
    setHasEndDate,
    setRequiredXP,
    setCompany,
    setContactPhoneNumber,
    setContactEmail,
    updateJobOffer,
    isDisabled,
    isLoading,
  };
};

export default useJobOfferUpdate;
