import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

// translation related files 
import { setDefaultTranslations, setDefaultLanguage } from 'react-multi-lang';
import fr from './translations/fr.json';
import en from './translations/en.json';

const languages = ['fr', 'en'];

setDefaultTranslations({ fr, en });

interface NavigatorLanguage  {
    userLanguage?: string;
}

const language = (navigator.languages && navigator.languages[0])
  || navigator.language;
  // TO DO : FIX FOR IE with userLanguage
  // || navigator.userLanguage;

// Split locales with a region code
const languageWithoutRegionCode = language.toLowerCase().split(/[_-]+/)[0];

if (languages.includes(languageWithoutRegionCode)) {
  setDefaultLanguage(languageWithoutRegionCode);
} else {
  // if no implemented language found : fall back to english
  setDefaultLanguage('en');
}

setDefaultLanguage('fr');

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();