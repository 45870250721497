import React, {
  createContext,
  useState,
  useEffect,
} from 'react';
import { translate } from 'react-multi-lang'; 
/* NOTIFICATIONS */
import { toast } from 'react-toastify';

const defaultAppState = {
  error: null,
  setError: () => {return;},
  success: null,
  setSuccess: () => {return;},
  isLoading: false,
  setIsLoading: () => {return;},
};

const UseToastsContext = createContext(defaultAppState);

const UseToastsProviderBase = (
  {
    children,
    t,
  },
) => {
  const [error, setError] = useState(null);
	const [success, setSuccess] = useState(null);
	const [isLoading, setIsLoading] = useState(false);
	useEffect(
		() => {
			if (!!error && !!error.message) {
				toast(
					t(error.message),
					{
						type: toast.TYPE.ERROR,
						onClose: () => setError(null),
					},
				);
			}
		},
		[error, t]
	);

	useEffect(
		() => {
			if (!!success && !!success.message) {
				toast(
					t(success.message),
					{
						onClose: () => setSuccess(null),
					},
				);
			}
		},
		[success, t]
	);

  return (
    <UseToastsContext.Provider
    	value={{ error, setError, success, setSuccess, isLoading, setIsLoading }}
    >
      {children}
    </UseToastsContext.Provider>
  );
};

const UseToastsProvider = translate(UseToastsProviderBase);

export {
	UseToastsContext,
	UseToastsProvider,
};