import {
  useState,
  useEffect,
  useContext,
} from 'react';
import { firestore } from '../../firebase';
import { PAGINATION_LIMIT } from '../../constants/limits';
import { UseToastsContext } from '../contexts/UseToastsContext';
import { UseAuthUserContext } from '../contexts/UseAuthUserContext';

const useJobOffers = (t)  => {
  const {
    setError,
    isLoading,
    setSuccess,
    setIsLoading,
  } = useContext(UseToastsContext);

  const {
    authUser,
  } = useContext(UseAuthUserContext);

  const {
    id: authUserId,
  } = authUser || {
    id: '',
  };

  const [offers, setOffers] = useState([]);
  /*const [allOffers, setAllOffers] = useState([]);*/
  /*const [positionOptions, setPositionOptions] = useState(null);
  const [positionFilter, setPositionFilter] = useState('all');
  const [typeOptions, setTypeOptions] = useState(null);*/
  const [typeFilter, setTypeFilter] = useState('all');
  const [domainFilter, setDomainFilter] = useState('all');
  const [isInitialized, setIsInitialized] = useState(false);
  // const [deletionSuccess, setDeletionSuccess] = useState(false);
  const [isFiltering, setIsFiltering] = useState(false);
  const [startAt, setStartAt] = useState(1);
  const [nbJobOffers, setNbJobOffers] = useState(-1);
  const [nbJobOffersNotDeleted, setNbJobOffersNotDeleted] = useState(-1);
  const [loadMore, setLoadMore] = useState(false);
  const [allLoaded, setAllLoaded] = useState(false);

  useEffect(
    () => {
      setNbJobOffers(-1);
      setNbJobOffersNotDeleted(-1);
      setAllLoaded(false);
      setIsLoading(true);
      setIsInitialized(false);
      setOffers([]);
      firestore.getNbJobOffers().then((nb) => {
        setStartAt(nb.nbJobOffers);
        setNbJobOffers(nb.nbJobOffers);
        setNbJobOffersNotDeleted(nb.nbJobOffersNotDeleted);
      })
    },
    [typeFilter, domainFilter],
  );

  useEffect(
    () => {
      if (!isInitialized && (nbJobOffers >= 0)) {
        setIsLoading(true);
        getJobOffers();
      }
    },
    [nbJobOffers, isInitialized],
  );

  useEffect(
    () => {
      if (loadMore) {
        setLoadMore(true);
        getJobOffers();
      }
    },
    [loadMore]
  );

  const getJobOffers = () => {
    const typeFilterTreated = (typeFilter === 'all') ? '' : typeFilter;
    const domainFilterTreated = (domainFilter === 'all') ? '' : domainFilter;
    firestore.getJobOffers(startAt, typeFilterTreated, domainFilterTreated).then(jobOffers => {
      const newCounter = (jobOffers.length > 0)
        ? jobOffers[jobOffers.length - 1].counter - 1
        : 0;
      setStartAt(newCounter);
      if ((newCounter === 0) || (jobOffers.length < PAGINATION_LIMIT) || ((jobOffers.length + offers.length) === nbJobOffersNotDeleted)){
        setAllLoaded(true);
      }
      setOffers([...offers, ...jobOffers]);
      if ((offers.length > 0) && (jobOffers.length === 0)) {
        setSuccess({ message: 'noMoreToLoad'})
      }
      // setAllOffers([...offers, ...jobOffers]);
      setError(null);
    }).catch((e) => {
      setError(e);
      setOffers([]);
      /*setAllOffers([]);
      setPositionOptions([]);
      setTypeOptions([]);*/
    });
  };


  /*useEffect(
    () => { 
      if (!!positionOptions && !!typeOptions && !!allOffers && !!offers) {
        setIsLoading(false);
        setIsInitialized(true);
      }
    },
    [positionOptions, typeOptions, allOffers, offers]
  );*/

  useEffect(
    () => { 
      if (!!offers && (nbJobOffers >= 0) && (nbJobOffersNotDeleted >= 0)) {
        setIsLoading(false);
        setIsInitialized(true);
        setTimeout(() => {
          setLoadMore(false);
        }, 500)
      }
    },
    [offers]
  );

  /*useEffect(
    () => { 
      if (isInitialized) {
        setIsFiltering(true);
        const offersPositionFilter = allOffers.filter(({ position }) => (
          (positionFilter !== 'all') ? (position === positionFilter) : true
        ));
        const offersFiltered = offersPositionFilter.filter(({ type }) => (
          (typeFilter !== 'all') ? (type === typeFilter) : true
        ));
        setOffers([...offersFiltered]);
      }
    },
    [isInitialized, positionFilter, typeFilter]
  );*/

  useEffect(
    () => {
      if (isInitialized) {
        setIsFiltering(false);
      }
    },
    [offers]
  );

  const deleteJobOffer = (jobOfferId) => {
    if (!isLoading) {
      setIsLoading(true);
      firestore.deleteJobOffer(jobOfferId).then(() => {
        setNbJobOffersNotDeleted(nbJobOffers - 1);
        const newOffers = offers.filter(({ id }) => id !== jobOfferId);
        setOffers([...newOffers]);
        setSuccess({ message: 'jobOfferDeleteSuccess' });
        setError(null);
      }).catch((e) => {
        setError(e);
        setSuccess(null);
        setIsLoading(false);
      })
    } else {
      setError({ message: 'fillAllInfo' });
    }
  };

  return {
    offers,
    /*positionOptions,
    positionFilter,
    setPositionFilter,
    typeOptions,*/
    typeFilter,
    setTypeFilter,
    domainFilter,
    setDomainFilter,
    deleteJobOffer,
    isFiltering,
    isLoading,
    authUserId,
    loadMore,
    setLoadMore,
    allLoaded,
  };
};

export default useJobOffers;
