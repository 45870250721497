import React from 'react';
import {
  Container,
  Header,
} from 'semantic-ui-react';
import { translate } from 'react-multi-lang';
import { UseIsMobileContext } from '../../logic/contexts/UseIsMobileContext';

const PageHeader = ({ t, header, subHeader, param = '' }) => {
  const mobile = React.useContext(UseIsMobileContext);
  return (
    <Container
      text
    >
      <Header
        as="h1"
        content={t(header, { param: param })}
        inverted
        style={{
          fontSize: mobile ? "2em" : "4em",
          fontWeight: "normal",
          marginBottom: 0,
          marginTop: "1em"
        }}
      />
      <Header
        as="h2"
        content={subHeader ? t(subHeader) : ''}
        inverted
        style={{
          fontSize: mobile ? "1.5em" : "1.7em",
          fontWeight: "normal",
          marginTop: mobile ? "0.5em" : "1.5em"
        }}
      />
    </Container>
  );
};

export default translate(PageHeader);