import {
  useState,
  useEffect,
  useContext,
} from 'react';
import { auth } from '../../firebase';
import { LOADING } from '../../constants/routes';
import { UseToastsContext } from '../contexts/UseToastsContext';

const useRecoverPassword = ()  => {
  const {
    setError,
    setSuccess,
    isLoading,
    setIsLoading,
  } = useContext(UseToastsContext);
  const [email, setEmail] = useState('');
  const [isDisabled, setIsDisabled] = useState(true);

  useEffect(
    () => {
      setIsDisabled(
        email.trim() === '',
      );
    },
    [email],
  );

  const recoverMyPwd = () => {
    if (isLoading || isDisabled) {
      setError({ message: 'fillAllInformation' });
    }
    else {
      setIsLoading(true);
      auth.sendPasswordResetEmail(email).then(() => {
        setSuccess({ message: 'successRecoverMyPwd' });
        setIsLoading(false);
      })
      .catch((e) => {
        // Print the success message when 
        // no user is found (prevent valid account discovery)
        if (e.code === 'auth/user-not-found') {
          setSuccess({ message: 'successRecoverMyPwd' });
        } else {
          setError(e);
        }
        setIsLoading(false);
      });
    }
  };

  return {
    email,
    isDisabled,
    setEmail,
    isLoading,
    recoverMyPwd,
  };
};

export default useRecoverPassword;