import {
  useState,
  useEffect,
  useContext,
} from 'react';
import { firestore } from '../../firebase';
import { PAGINATION_LIMIT } from '../../constants/limits';
import { UseToastsContext } from '../contexts/UseToastsContext';
import { UseAuthUserContext } from '../contexts/UseAuthUserContext';

const useNews = (t, category = 'all')  => {
  const {
    setError,
    isLoading,
    setSuccess,
    setIsLoading,
  } = useContext(UseToastsContext);

  const {
    authUser,
  } = useContext(UseAuthUserContext);

  const {
    id: authUserId,
  } = authUser || {
    id: '',
  };

  const [news, setNews] = useState([]);
  /*const [allOffers, setAllOffers] = useState([]);*/
  /*const [positionOptions, setPositionOptions] = useState(null);
  const [positionFilter, setPositionFilter] = useState('all');
  const [typeOptions, setTypeOptions] = useState(null);*/
  const [tagFilter, setTagFilter] = useState(category);
  const [isInitialized, setIsInitialized] = useState(false);
  // const [deletionSuccess, setDeletionSuccess] = useState(false);
  const [isFiltering, setIsFiltering] = useState(false);
  const [startAt, setStartAt] = useState(1);
  const [nbNews, setNbNews] = useState(-1);
  const [nbNewsNotDeleted, setNbNewsNotDeleted] = useState(-1);
  const [loadMore, setLoadMore] = useState(false);
  const [allLoaded, setAllLoaded] = useState(false);

  useEffect(
    () => {
      setNbNews(-1);
      setNbNewsNotDeleted(-1);
      setAllLoaded(false);
      setIsLoading(true);
      setIsInitialized(false);
      setNews([]);
      firestore.getNbNews().then((nb) => {
        setStartAt(nb.nbNews);
        setNbNews(nb.nbNews);
        setNbNewsNotDeleted(nb.nbNewsNotDeleted);
      })
    },
    [tagFilter],
  );

  useEffect(
    () => {
      if (!isInitialized && (nbNews >= 0)) {
        setIsLoading(true);
        getNews();
      }
    },
    [nbNews, isInitialized],
  );

  useEffect(
    () => {
      if (loadMore) {
        setLoadMore(true);
        getNews();
      }
    },
    [loadMore]
  );

  const getNews = () => {
    const tagFiltertTreated = (tagFilter === 'all') ? '' : tagFilter;
    firestore.getAllNews(startAt, tagFiltertTreated).then(newsFromDB => {
      const newCounter = (newsFromDB.length > 0)
        ? newsFromDB[newsFromDB.length - 1].counter - 1
        : 0;
      setStartAt(newCounter);
      if ((newCounter === 0) || (newsFromDB.length < PAGINATION_LIMIT) || ((newsFromDB.length + news.length) === nbNewsNotDeleted)){
        setAllLoaded(true);
      }
      setNews([...news, ...newsFromDB]);
      if ((news.length > 0) && (newsFromDB.length === 0)) {
        setSuccess({ message: 'noMoreToLoad'})
      }
      // setAllOffers([...news, ...newsFromDB]);
      setError(null);
    }).catch((e) => {
      setError(e);
      setNews([]);
      /*setAllOffers([]);
      setPositionOptions([]);
      setTypeOptions([]);*/
    });
  };


  /*useEffect(
    () => { 
      if (!!positionOptions && !!typeOptions && !!allOffers && !!news) {
        setIsLoading(false);
        setIsInitialized(true);
      }
    },
    [positionOptions, typeOptions, allOffers, news]
  );*/

  useEffect(
    () => { 
      if (!!news && (nbNews >= 0) && (nbNewsNotDeleted >= 0)) {
        setIsLoading(false);
        setIsInitialized(true);
        setTimeout(() => {
          setLoadMore(false);
        }, 500)
      }
    },
    [news]
  );

  /*useEffect(
    () => { 
      if (isInitialized) {
        setIsFiltering(true);
        const newsPositionFilter = allOffers.filter(({ position }) => (
          (positionFilter !== 'all') ? (position === positionFilter) : true
        ));
        const newsFiltered = newsPositionFilter.filter(({ type }) => (
          (tagFilter !== 'all') ? (type === tagFilter) : true
        ));
        setNews([...newsFiltered]);
      }
    },
    [isInitialized, positionFilter, tagFilter]
  );*/

  useEffect(
    () => {
      if (isInitialized) {
        setIsFiltering(false);
      }
    },
    [news]
  );

  const deleteNews = (newsId) => {
    if (!isLoading) {
      setIsLoading(true);
      firestore.deleteNews(newsId).then(() => {
        setNbNewsNotDeleted(nbNews - 1);
        const newOffers = news.filter(({ id }) => id !== newsId);
        setNews([...newOffers]);
        setSuccess({ message: 'newsDeleteSuccess' });
        setError(null);
      }).catch((e) => {
        setError(e);
        setSuccess(null);
        setIsLoading(false);
      })
    } else {
      setError({ message: 'fillAllInfo' });
    }
  };

  return {
    news,
    tagFilter,
    setTagFilter,
    deleteNews,
    isFiltering,
    isLoading,
    authUserId,
    loadMore,
    setLoadMore,
    allLoaded,
  };
};

export default useNews;
