import React, {
  createContext,
  useState,
  useEffect,
  useContext,
} from 'react'; 
import { firebase, firestore } from '../../firebase';
import FullPageLoaderNoRedirect from '../../views/loaders/FullPageLoaderNoRedirect';
import { UseToastsContext } from '../contexts/UseToastsContext';

const defaultAuthUser = {
  authUser: null,
};

const UseAuthUserContext = createContext(defaultAuthUser);

const UseAuthUserProvider  = ({ children }) => {
  const {
    setError,
    setSuccess,
  } = useContext(UseToastsContext);

  const [authUserLoaded, setAuthUserLoaded] = useState(false);
  const [authUser, setAuthUser] = useState(null);

  useEffect(
    () => {
      let userListener = () => {return};
      firebase.auth.onAuthStateChanged((firestoreAuthUser) => {
        if (firestoreAuthUser) {
          const { uid } = firestoreAuthUser;
          userListener = firestore.getAuthUser(uid).onSnapshot((firestoreUserObjectDoc) => {
            if (firestoreUserObjectDoc.exists) {
              setAuthUser({
                id: uid,
                ...firestoreUserObjectDoc.data(),
              });
              setAuthUserLoaded(true);
            } else {
              setAuthUser(null);
              setAuthUserLoaded(true);
            }
          }, (error) => {
            if (error.code === 'permission-denied') {
              setError({ message: 'accountNotValidatedYet' });
            }
            setAuthUser(null);
            setAuthUserLoaded(true);
          });
        } else {
          userListener();
          setAuthUser(null);
          setAuthUserLoaded(true);
        }
      });
      return () => {
        userListener();
      };
    },
    [setSuccess, setError],
  );

  return (
     authUserLoaded
      ? (
        <UseAuthUserContext.Provider value={{ authUser }}>
          { children }
        </UseAuthUserContext.Provider>
      )
      : <FullPageLoaderNoRedirect />
  );
};

export { UseAuthUserContext, UseAuthUserProvider };
