import React from 'react';
import { withRouter } from 'react-router-dom';
/* STYLE */
import {
  Grid,
  Form,
  Button,
  Select,
} from 'semantic-ui-react';

import ResponsiveContainer from '../../components/containers/ResponsiveContainer';
import PageHeader from '../../components/headers/PageHeader';
import useScrollTopOnMount from '../../logic/ui/useScrollTopOnMount';
import bgName from '../../img/bg/sign-up-bg.jpg';
/* CONSTANTS */
import { DJCE_CENTERS } from '../../constants/djceCenters';
/* TRANSLATIONS */
import {
  translate,
} from 'react-multi-lang';
/* LOGIC */
import useGraduateSignup from '../../logic/users/useGraduateSignup';

const GraduateSignupPage = ({
  history,
  t,
}) => {
  useScrollTopOnMount();
  const {
    email,
    setEmail,
    password,
    setPassword,
    name,
    setName,
    surname,
    setSurname,
    linkedinUrl,
    setLinkedinUrl,
    promoYear,
    setPromoYear,
    setDjceCenter,
    isDisabled,
    isLoading,
    signUp,
  } = useGraduateSignup(history);
  return (
    <ResponsiveContainer
      minHeight={300}
      bgName={bgName}
      header={
        <PageHeader
          header="signUpHeader"
          subHeader="graduate"
        />
      }
    >
      <Grid textAlign="center" verticalAlign="middle"  className="padded">
        <Grid.Column className="max-width-45em">
          <Form size="big" loading={isLoading}>
            <Form.Input
              fluid
              icon="user"
              value={surname}
              onChange={(e, { value }) => setSurname(`${value}`)}
              iconPosition="left"
              placeholder={t('surname')}
            />
            <Form.Input
              fluid
              icon="user"
              value={name}
              onChange={(e, { value }) => setName(`${value}`)}
              iconPosition="left"
              placeholder={t('name')}
            />
            <Form.Input
              fluid
              icon="linkedin"
              value={linkedinUrl}
              onChange={(e, { value }) => setLinkedinUrl(`${value}`)}
              iconPosition="left"
              placeholder={t('linkedin')}
            />
            <Form.Input
              fluid
              icon="certificate"
              value={promoYear}
              onChange={(e, { value }) => setPromoYear(`${value}`)}
              iconPosition="left"
              placeholder={t('promoYear')}
            />
            <Select
              placeholder={t('djceCenter')}
              options={DJCE_CENTERS}
              className="full-width margin-bottom-1em"
              onChange={(e, { value }) => setDjceCenter(`${value}`)}
            />
            <Form.Input
              fluid
              icon="at"
              value={email}
              onChange={(e, { value }) => setEmail(`${value}`)}
              iconPosition="left"
              placeholder={t('email')}
            />
            <Form.Input
              fluid
              icon="lock"
              value={password}
              onChange={(e, { value }) => setPassword(`${value}`)}
              iconPosition="left"
              placeholder={t('password')}
              type="password"
            />
            <Button color="red" fluid size="big" disabled={isDisabled} onClick={() => signUp()}>
              {t('createMyAccount')}
            </Button>
          </Form>
        </Grid.Column>
      </Grid>
    </ResponsiveContainer>
  );
};

export default withRouter(translate(GraduateSignupPage));
