import React from 'react';
import { translate } from 'react-multi-lang';
import {
  Container,
  Image,
} from 'semantic-ui-react';
import ResponsiveContainer from '../../components/containers/ResponsiveContainer';
import PageHeader from '../../components/headers/PageHeader';
import useScrollTopOnMount from '../../logic/ui/useScrollTopOnMount';
import bgName from '../../img/bg/president-word-bg.jpg';
import remiLorrain from '../../img/president/remiLorrain.png';

const PresidentWordPage = ({ t }) => {
  useScrollTopOnMount();
  return (
    <ResponsiveContainer
      minHeight={300}
      bgName={bgName}
      header={
        <PageHeader
          header="presentation"
        />
      }
    >
      <Container className="padded" text>
        <Image src={remiLorrain} size="medium" floated="left" />
        <p>
          <b>
            {t('presidentWordText1')}
          </b>
          <br />
          <br />
          {t('presidentWordText2')}
          <br />
          <br />
          {t('presidentWordText3')}
          <br />
          <br />
          {t('presidentWordText4')}
          <br />
          <br />
          <b>
            {t('presidentWordText5')}
          </b>
          <br />
        </p>
      </Container>
    </ResponsiveContainer>
  );
};

export default translate(PresidentWordPage);
