import React from 'react';
import { translate } from 'react-multi-lang';
import {
  Button,
  Container,
  Divider,
  Grid,
  Header,
  Image,
  Segment,
  Label,
  Icon,
  Card,
} from 'semantic-ui-react';

import { TwitterTimelineEmbed } from 'react-twitter-embed';
import ResponsiveContainer from '../../components/containers/ResponsiveContainer';
import PageHeader from '../../components/headers/PageHeader';
import NewsModal from '../../components/modals/NewsModal';
import BasicModal from '../../components/modals/BasicModal';
import { PartnershipSegment } from './PartnershipsPage';

import testimonial1 from '../../img/testimonials/testimonial1.jpg';
import testimonial2 from '../../img/testimonials/testimonial2.jpg';
// import president_word_homepage from '../../img/president-word-homepage.jpg';
import useThreeNews from '../../logic/news/useThreeNews';
import { timeToLT } from '../../utils/utils';

import useScrollTopOnMount from '../../logic/ui/useScrollTopOnMount';
import bgName from '../../img/bg/bg.png';

const HomepageLayout = ({ t }) => {
  useScrollTopOnMount();
  const {
    isLoading,
    news,
  } = useThreeNews();
  const showModal = localStorage.getItem('showModal');
  const [showInfoModal, setShowInfoModal] = React.useState(!showModal ? true : false);
  // const [showInfoModal, setShowInfoModal] = React.useState(true);
  const closeModal = () => {
    setShowInfoModal(false);
    localStorage.setItem('showModal', false);
  }
  return (
    <ResponsiveContainer
      minHeight={600}
      bgName={bgName}
      header={
        <PageHeader
          header="andjce"
          subHeader="homepageSubHeader"
        />
      }
    >
      <Segment className="small-padded" vertical>
        <Grid container stackable verticalAlign="middle">
          <Grid.Row>
            <Grid.Column width={16}>
              <Header as="h3" style={{ fontSize: "2em" }} className="color-dark-red" textAlign="center">
                Merci à nos partenaires qui soutiennent l'association !
              </Header>
              <PartnershipSegment />
            </Grid.Column>
            {/*<Grid.Column floated="right" width={6}>
              <Image
                bordered
                rounded
                size="large"
                src={president_word_homepage}
              />
            </Grid.Column>*/}
          </Grid.Row>
        </Grid>
      </Segment>

      {/*<Segment className="padded" vertical>
        <Grid container stackable verticalAlign="middle">
          <Grid.Row>
            <Grid.Column width={16}>
              <Header as="h3" style={{ fontSize: "2em" }} className="color-dark-red">
                Le mot du président
              </Header>
              <p style={{ fontSize: "1.33em" }}>
                Le DJCE est une formation d’excellence, qui continue à se confirmer année après année, 40 ans après sa fondation. Cette formation procure un diplôme reconnu pour une vie professionnelle réussie et doit continuer à être mise en valeur au sein des Universités mais aussi à travers les liens entre tous les diplômés.
                <br /><br />Aux côtés des centres universitaires habilités à délivrer le DJCE, en coordination avec la FNDE, l’ANDJCE a la volonté de développer tous les moyens ayant pour but de conforter la notoriété du DJCE, de faciliter le recrutement et la promotion des diplômés et d’entretenir des liens entre les diplômés.
                <br /><br />Dans cette période d’incertitude, il faut développer l’esprit de corps, le sentiment d’appartenance. Il faut être conscient de l’environnement de plus en plus concurrentiel et de la nécessité de développer et d’afficher la marque DJCE, cette Grande Ecole au sein de l’Université. La réalité est complexe, mais, avec humilité, il faut s’attacher à y répondre, rester fidèle à ses valeurs d’hier tout en anticipant les défis de demain.
                <br /><br />Cet esprit DJCE, tentons de le faire perdurer tant au sein de nos Universités qu’au sein de notre Association, votre Association, qu’est l’ANDJCE. L’Association Nationale est en effet avant tout la vôtre et vos suggestions et implications seront les bienvenues. Tous les diplômés DJCE sont en effet membres de droit de l’ANDJCE. Et ce n’est qu’ensemble que nous pourrons aller de l’avant.
                <br /><br /><span style={{ fontWeight: "bold" }}>Rémi Lorrain, Président de l’ANDJCE</span>
                <br /><span style={{ fontWeight: "bold" }}>François Barrière, Président d’honneur de l’ANDJCE</span>
              </p>
             {/*<Header as="h3" style={{ fontSize: "2em" }}>
                We Make Bananas That Can Dance
              </Header>
              <p style={{ fontSize: "1.33em" }}>
                Yes that's right, you thought it was the stuff of dreams, but even
                bananas can be bioengineered.
              </p>*
            </Grid.Column>
            {/*<Grid.Column floated="right" width={6}>
              <Image
                bordered
                rounded
                size="large"
                src={president_word_homepage}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Segment>*/}

      <Segment style={{ padding: "0em" }} vertical>
        <Grid celled="internally" columns="equal" stackable>
          <Grid.Row textAlign="center">
            <Grid.Column className="padded padded-sides bg-color-light-grey">
              <Header as="h3" style={{ fontSize: "1.3em" }}>
                "L’ ANDJCE est une opportunité formidable pour rester en relation avec les anciens de ma promotion et lier de nouveaux contacts !"
              </Header>
              <h3 style={{ fontSize: "1.33em" }}>
                <Image avatar src={testimonial1} />
                <b className="color-dark-red">Charles Digby-Smith</b> 
              </h3>
            </Grid.Column>
            <Grid.Column className="padded padded-sides bg-color-light-grey">
              <Header as="h3" style={{ fontSize: "1.3em" }}>
                "L’ ANDJCE regroupe l’annuaire de tous les anciens et me permet de trouver un contact partout en France et même dans le monde lorsque j’en ai besoin."
              </Header>
              <h3 style={{ fontSize: "1.33em" }}>
                <Image avatar src={testimonial2} />
                <b className="color-dark-red">Marion Pourquier</b> 
              </h3>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Segment>


      <Segment style={{ paddingBottom: "5em" }} vertical>
        <Container>
          <Divider
            as="h4"
            className="header"
            horizontal
            style={{ margin: "3em 0em" }}
          >
            <span className="color-dark-red" style={{ fontSize: "2.5em" }}>
              {t('news')}
            </span>
          </Divider>
          <Card.Group itemsPerRow={3} stackable>
          {news.map(({ id, ...rest }) => (
            <NewsComponent
              id={id}
              key={id}
              t={t}
              {...rest}
            />
          ))}
          </Card.Group>
        </Container>
      </Segment>

      <Segment style={{ paddingBottom: "5em" }} vertical>
        <Container textAlign="center">
          <TwitterTimelineEmbed
            sourceType="profile"
            screenName="andjce_djce"
            options={{height: 500, width: 500, display: 'inline-block'}}
          />
          <iframe src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2Fandjce&tabs=timeline&width=500&height=500&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=true&appId" width="500" height="500" scrolling="no" frameborder="0" allowTransparency="true" allow="encrypted-media"></iframe>        </Container>
      </Segment>

      <BasicModal
        isShowing={showInfoModal}
        onClose={() => closeModal()}
        headerText="Information importante"
        headerIcon="info"
        content={
          <React.Fragment>
            <Header as="h3" textAlign="center">
              {t('welcomeModalInfo1')}
            </Header>
            <Header as="h3">
              {t('welcomeModalInfo2')}
            </Header>
            <Header as="a" color="green" href="/recover-my-password"> > Réinitialiser mon mot de passe</Header>
          </React.Fragment>
        }
        headerColor="red"
        hasConfirmButton
        confirmButtonAction={() => closeModal()}
        confirmButtonText={t('iUnderstand')}
      />

    </ResponsiveContainer>
  );
};

const NewsComponent = ({
  id,
  name,
  tag,
  description,
  createdOn,
  t,
}) => {
  const [isShowingInfoModal, setIsShowingInfoModal] = React.useState(false);
  return (
    <React.Fragment>
      <Card
        centered
        raised
        header={
          <React.Fragment>
            <Label ribbon color="black" content={t(tag)} />
            <Header as="h2">
              {`${name}`}
            </Header>
          </React.Fragment>
        }
        meta={
          <Label>
            <Icon name="calendar outline" />{timeToLT(createdOn, 'LLL')}
          </Label>
        }
        description={
          <div className="margin-bottom-1em margin-top-1em pre-wrap">
            {`${description.substr(0, 150)}`}
            {(description.length > 149) && (
              <React.Fragment>
                <br />
                {'...'}
              </React.Fragment>
            )}
          </div>
        }
        extra={
          <React.Fragment>
            <Button
              color="olive"
              content={t('seeMore')}
              icon="plus"
              labelPosition="left"
              onClick={() => setIsShowingInfoModal(true)}
            />
          </React.Fragment>
        }
      />
      <NewsModal
        name={name}
        tag={tag}
        createdOn={createdOn}
        description={description}
        isShowingModal={isShowingInfoModal}
        setIsShowingModal={setIsShowingInfoModal}
      />
    </React.Fragment>
  );
};

export default translate(HomepageLayout);