import React, {
  createContext,
  useState,
  useEffect,
} from 'react';
import { Responsive } from 'semantic-ui-react';

/* 
  Usage : const mobile = useContext(UseIsMobileContext); 
    return true/false
*/

const UseIsMobileContext = createContext(false);

const isMobile = () => {
  const isSSR = typeof window === 'undefined';
  const width = isSSR ? Number(Responsive.onlyTablet.minWidth) : Number(window.innerWidth);
  const maxWidth = Responsive.onlyMobile.maxWidth ? Number(Responsive.onlyMobile.maxWidth) : 0;
  return (width <= 1259);
};

const UseIsMobileProvider = ({ children }) => {
  const currentValue = isMobile();
  const [mobile, setMobile] = useState(currentValue);
  useEffect(() => {
    const adaptWidth = () => {
      const newValue = isMobile();
      setMobile(newValue);
    };
    window.addEventListener('resize', adaptWidth);
    return () => {
      window.removeEventListener('resize', adaptWidth);
    };
  }, []);
  return (
    <UseIsMobileContext.Provider value={mobile}>
      { children }
    </UseIsMobileContext.Provider>
  );
};

export { UseIsMobileContext, UseIsMobileProvider };
