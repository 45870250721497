import { useEffect } from 'react';
import {
  scrollTop,
} from '../../utils/utils';

const useScrollTopOnMount = (): void => {
  useEffect(() => {
    scrollTop();
  }, []);
};

export default useScrollTopOnMount;
