import React, {
  useState,
} from 'react';
/* STYLE */
import {
  Grid,
  Menu,
} from 'semantic-ui-react';
/* TRANSLATIONS */
import {
  translate,
} from 'react-multi-lang';

import ResponsiveContainer from '../../../components/containers/ResponsiveContainer';
import PageHeader from '../../../components/headers/PageHeader';
import UsersAdminPanel from './UsersAdminPanel';
import JobOffersPanel from '../JobOffersPanel';
import NewsPanel from '../NewsPanel';
import bgName from '../../../img/bg/admin-bg.jpg';

const AdminPanelPage = ({
  history,
  activeMenuProp,
  t,
}) => {
  const activeMenuDefault = activeMenuProp ? activeMenuProp : 'users';
  const [activeMenu, setActiveMenu] = useState(activeMenuDefault);
  return (
    <ResponsiveContainer
      minHeight={300}
      bgName={bgName}
      header={
        <PageHeader
          header="administration"
        />
      }
    >
      <Grid  className="small-padded" stackable divided centered>
        <Grid.Row centered>
          <Grid.Column textAlign="center" width={6}>
            <Menu pointing secondary color="red" fluid widths={3} size="huge">
              <Menu.Item
                name={t('users')}
                content={t('users')}
                active={activeMenu === 'users'}
                onClick={() => setActiveMenu('users')}
              />
              <Menu.Item
                name={t('newsAdmin')}
                active={activeMenu === 'news'}
                onClick={() => setActiveMenu('news')}
              />
              <Menu.Item
                name={t('jobOffers')}
                content={t('jobOffers')}
                active={activeMenu === 'jobOffers'}
                onClick={() => setActiveMenu('jobOffers')}
              />
            </Menu>
          </Grid.Column>
        </Grid.Row>
        <React.Fragment>
          {(activeMenu === 'users') && (
            <UsersAdminPanel />
          )}
          {(activeMenu === 'jobOffers') && (
            <JobOffersPanel />
          )}
          {(activeMenu === 'news') && (
            <NewsPanel />
          )}
        </React.Fragment>
      </Grid>
    </ResponsiveContainer>
  );
};

export default translate(AdminPanelPage);
