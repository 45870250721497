import React, {
  useState,
} from 'react';
import { withRouter } from 'react-router-dom';
/* STYLE */
import {
  Grid,
  Form,
  Button,
  Select,
  Menu,
} from 'semantic-ui-react';
/* TRANSLATIONS */
import {
  translate,
} from 'react-multi-lang';
/* CONSTANTS */
import { DJCE_CENTERS } from '../../constants/djceCenters';
/* LOGIC */
import useChangeMyPassword from '../../logic/users/useChangeMyPassword';
import useGraduateAccountInfo from '../../logic/users/useGraduateAccountInfo';

import ResponsiveContainer from '../../components/containers/ResponsiveContainer';
import PageHeader from '../../components/headers/PageHeader';
import useScrollTopOnMount from '../../logic/ui/useScrollTopOnMount';
import bgName from '../../img/bg/membership-bg.jpg';

const GraduateAccountPage = ({
  history,
  t,
}) => {
  const [activeForm, setActiveForm] = useState('info');
  useScrollTopOnMount();
  const {
    currentPwd,
    setCurrentPwd,
    newPwd,
    setNewPwd,
    newPwdConfirm,
    setNewPwdConfirm,
    isLoading,
    isDisabled,
    changeMyPwd,
  } = useChangeMyPassword();
  const {
    name,
    setName,
    surname,
    setSurname,
    linkedinUrl,
    setLinkedinUrl,
    promoYear,
    setPromoYear,
    djceCenter,
    setDjceCenter,
    isDisabled: isDisabledMyInfo,
    isLoading: isLoadingMyInfo,
    updateMyInfo,
  } = useGraduateAccountInfo();
  return (
    <ResponsiveContainer
      minHeight={300}
      bgName={bgName}
      header={
        <PageHeader
          header="myAccount"
        />
      }
    >
      <Grid textAlign="center" verticalAlign="middle"  className="padded">
        <Grid.Row>
          <Menu pointing secondary size="huge">
            <Menu.Item
              name="info"
              content={t('myInfo')}
              color="red"
              active={activeForm === 'info'}
              onClick={() => setActiveForm('info')}
            />
            <Menu.Item
              name="pwd"
              content={t('myPwd')}
              color="red"
              active={activeForm === 'pwd'}
              onClick={() => setActiveForm('pwd')}
            />
          </Menu>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column className="max-width-45em">
            {activeForm === 'pwd'
              ? (
                <Form size="big" loading={isLoading}>
                  <Form.Input
                    fluid
                    icon="lock"
                    value={currentPwd}
                    onChange={(e, { value }) => setCurrentPwd(`${value}`)}
                    iconPosition="left"
                    placeholder={t('currentPwd')}
                    type="password"
                  />
                  <Form.Input
                    fluid
                    icon="lock"
                    value={newPwd}
                    onChange={(e, { value }) => setNewPwd(`${value}`)}
                    iconPosition="left"
                    placeholder={t('newPwd')}
                    type="password"
                  />
                  <Form.Input
                    fluid
                    icon="lock"
                    value={newPwdConfirm}
                    onChange={(e, { value }) => setNewPwdConfirm(`${value}`)}
                    iconPosition="left"
                    placeholder={t('newPwdConfirm')}
                    type="password"
                  />
                  <Button color="red" fluid size="big" disabled={isDisabled} onClick={() => changeMyPwd()}>
                    {t('changeMyPwd')}
                  </Button>
                </Form>
              ) : (
                <Form size="big" loading={isLoadingMyInfo}>
                  <Form.Input
                    fluid
                    icon="user"
                    value={surname}
                    onChange={(e, { value }) => setSurname(`${value}`)}
                    iconPosition="left"
                    placeholder={t('surname')}
                  />
                  <Form.Input
                    fluid
                    icon="user"
                    value={name}
                    onChange={(e, { value }) => setName(`${value}`)}
                    iconPosition="left"
                    placeholder={t('name')}
                  />
                  <Form.Input
                    fluid
                    icon="linkedin"
                    value={linkedinUrl}
                    onChange={(e, { value }) => setLinkedinUrl(`${value}`)}
                    iconPosition="left"
                    placeholder={t('linkedin')}
                  />
                  <Form.Input
                    fluid
                    icon="certificate"
                    disabled
                    value={promoYear}
                    onChange={(e, { value }) => setPromoYear(`${value}`)}
                    iconPosition="left"
                    placeholder={t('promoYear')}
                  />
                  <Select
                    placeholder={t('djceCenter')}
                    disabled
                    options={DJCE_CENTERS}
                    defaultValue={DJCE_CENTERS.find(center => center.value === djceCenter).value}
                    onChange={(e, { value }) => setDjceCenter(`${value}`)}
                    className="full-width margin-bottom-1em"
                  />
                  <Button color="red" fluid size="big" disabled={isDisabledMyInfo} onClick={() => updateMyInfo()}>
                    {t('update')}
                  </Button>
                </Form>
              )
            }
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </ResponsiveContainer>
  );
};

export default withRouter(translate(GraduateAccountPage));
