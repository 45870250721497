import {
  useState,
  useEffect,
  useContext,
} from 'react';
import { auth, firestore } from '../../firebase';
import { LOADING } from '../../constants/routes';
import { UseToastsContext } from '../contexts/UseToastsContext';

const useGraduateSignup = (history)  => {
  const {
    setError,
    setSuccess,
    isLoading,
    setIsLoading,
  } = useContext(UseToastsContext);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [name, setNameLow] = useState('');
  const [surname, setSurname] = useState('');
  const [linkedinUrl, setLinkedinUrl] = useState('');
  const [djceCenter, setDjceCenter] = useState('');
  const [promoYear, setPromoYear] = useState('');
  const [isDisabled, setIsDisabled] = useState(true);

  const setName = (name) => {
    setNameLow(name.toUpperCase())
  }

  useEffect(
    () => {
      setIsDisabled(
        email.trim() === ''
        || password.length < 6
        || name.trim() === ''
        || surname.trim() === ''
        || djceCenter.trim() === ''
        || promoYear.trim() === ''
      );
    },
    [email, password, name, surname, djceCenter, promoYear],
  );

  const signUp = (): void => {
    if (isLoading || isDisabled) {
      setError({ message: 'fillAllInformation' });
    }
    else {
      setIsLoading(true);
      auth.createUserWithEmailAndPassword(email, password)
        .then(({ user }) => {
          if (user && user.uid) {
            firestore.createUser(
              user.uid,
              {
                email,
                surname,
                name: name.toUpperCase(),
                linkedinUrl,
                djceCenter,
                promoYear,
                createdOn: firestore.createTimeStamp(),
                updatedOn: firestore.createTimeStamp(),
                role: 'GRADUATE',
                validated: false,
              },
            ).then(() => {
              setSuccess({ message: 'loginSuccess' });
              setIsLoading(false);
              history.push(LOADING);
            }).catch((e) => {
              setError(e);
              setIsLoading(false);
            });
          } else {
            setError({ message: 'couldNotCreateUser' });
            setIsLoading(false);
          }
        }).catch((e) => {
          setError(e);
          setIsLoading(false);
        });
    }
  };

  return {
    email,
    setEmail,
    password,
    setPassword,
    name,
    setName,
    surname,
    setSurname,
    linkedinUrl,
    setLinkedinUrl,
    promoYear,
    setPromoYear,
    djceCenter,
    setDjceCenter,
    isDisabled,
    isLoading,
    signUp,
  };
};

export default useGraduateSignup;

