import React from 'react';
/* TRANSLATIONS */
import {
  translate,
} from 'react-multi-lang';
import {
  Grid,
} from 'semantic-ui-react';
import ResponsiveContainer from '../../components/containers/ResponsiveContainer';
import PageHeader from '../../components/headers/PageHeader';
import JobOffersPanel from './JobOffersPanel';
import bgName from '../../img/bg/job-bg.jpg';
const JobOffersPage = ({
  t,
}) => {
  return (
    <ResponsiveContainer
      minHeight={300}
      bgName={bgName}
      header={
        <PageHeader
          header="jobOffers"
        />
      }
    >
      <Grid  className="small-padded" stackable divided centered>
        <JobOffersPanel />
      </Grid>
    </ResponsiveContainer>
  );
};

export default translate(JobOffersPage);
