import {
  useContext,
} from 'react';
import { auth } from '../../firebase';
import { UNAUTHENTICATED_LANDING } from '../../constants/routes';
import { UseToastsContext } from '../contexts/UseToastsContext';

const useSignout = (history) => {
  const {
    setError,
    setSuccess,
  } = useContext(UseToastsContext);
  return {
    signOut: (): void => {
      auth.signOut().then(() => {
        setSuccess({ message: 'logoutSuccess' });
        history.push(UNAUTHENTICATED_LANDING);
      }).catch((e) => {
        setError({ message: 'logoutError' });
      });
    },
  };
};

export default useSignout;
