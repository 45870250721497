import React from 'react';
import { translate } from 'react-multi-lang';
import {
  Menu,
} from 'semantic-ui-react';
import MenuItems from './MenuItems';

const DesktopMenu = ({ fixed, t }) => (
  <Menu
    fixed={fixed ? "top" : null}
    color={fixed ? "red" : null}
    inverted={!fixed}
    style={{ backgroundColor: fixed ? "white" : "", border: 'none' }}
    pointing
    secondary
    className={fixed ? "border-bottom-red" : ""}
    size="huge"
  >
    <MenuItems fixed={fixed} />
  </Menu>
);

export default translate(DesktopMenu);
