import React from 'react';
import { translate } from 'react-multi-lang';
import {
  Header,
  Container,
  Button,
  Segment,
} from 'semantic-ui-react';
/* ROUTING */
import {
  withRouter,
} from 'react-router-dom';
import ResponsiveContainer from '../../components/containers/ResponsiveContainer';
import PageHeader from '../../components/headers/PageHeader';
import useScrollTopOnMount from '../../logic/ui/useScrollTopOnMount';
import bgName from '../../img/bg/membership-bg.jpg';

const MembershipPage = ({ t, history }) => {
  useScrollTopOnMount();
  return (
    <ResponsiveContainer
      minHeight={300}
      bgName={bgName}
      header={
        <PageHeader
          header="membership"
        />
      }
    >
      <Container className="padded">
        <Header as="h1" color="red">
          {t('membership')}
        </Header>
        <Header as="h3">
          {t('membershipText1')}
        </Header>
        <p>
          {t('membershipText2')}
          <br />
          <br />
          {t('membershipText3')}
          <br />
          <br />
          {t('membershipText4')}
        </p>
        <Segment basic textAlign="center">
          <Button
            color="red"
            size="huge"
            content={t('alumnis')}
            onClick={() => history.push('/graduate-sign-up')}
          />
          <Button
            inverted
            size="huge"
            color="red"
            content={t('partners')}
            onClick={() => history.push('/law-practice-sign-up')}
          />
        </Segment>
      </Container>
    </ResponsiveContainer>
  );
};

export default withRouter(translate(MembershipPage));
