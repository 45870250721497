import React from 'react';
import { translate } from 'react-multi-lang';
import BasicModal from './BasicModal';
/* STYLE */
import {
  Header,
} from 'semantic-ui-react';

const DeletionModal = ({
  isShowingModal,
  setIsShowingModal,
  confirmButtonAction,
  t,
}) => (
  <BasicModal
    isShowing={isShowingModal}
    onClose={() => setIsShowingModal(false)}
    headerText={t('deletion')}
    headerIcon="warning circle"
    content={
      <Header as="h2" content={t('confirmDeletion')} />
    }
    hasBackButton
    hasConfirmButton
    confirmButtonText={t('yes')}
    confirmButtonAction={confirmButtonAction}
  />
);

export default translate(DeletionModal);
