import {
	useState,
	useEffect,
	useContext,
} from 'react';
import { auth } from '../../firebase';
import { LOADING } from '../../constants/routes';
import { UseToastsContext } from '../contexts/UseToastsContext';

const useSignIn = (history)  => {
	const {
		setError,
    setSuccess,
		isLoading,
		setIsLoading,
	} = useContext(UseToastsContext);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isDisabled, setIsDisabled] = useState(true);

  useEffect(
  	() => {
  		setIsDisabled(
  			email.trim() === ''
  			|| password.length < 6
  		);
  	},
  	[email, password],
  );

  const signIn = (): void => {
    if (isLoading || isDisabled) {
      setError({ message: 'fillAllInformation' });
    }
    else {
      setIsLoading(true);
      auth.signInWithEmailAndPassword(email, password).then(() => {
        setSuccess({ message: 'loginSuccess' });
        setIsLoading(false);
        history.push(LOADING);
      })
      .catch((e) => {
        setError(e);
        setIsLoading(false);
      });
    }
  };

  return {
    email,
    isDisabled,
    setEmail,
    password,
    setPassword,
    isLoading,
    signIn,
  };
};

export default useSignIn;

