import {
  useState,
  useEffect,
  useContext,
} from 'react';
import { firestore } from '../../firebase';
import { UseToastsContext } from '../contexts/UseToastsContext';

const useAdminLawPracticeInfoUpdate = (
  id,
  currentName,
  currentSurname,
  currentPosition,
  currentCorporateName,
  currentCountry,
  currentCity,
  currentAddress,
  currentZipCode,
  users,
  setUsers,
)  => {
  const {
    setError,
    setSuccess,
    isLoading,
    setIsLoading,
  } = useContext(UseToastsContext);

  const [name, setNameLow] = useState(currentName);
  const [surname, setSurname] = useState(currentSurname);
  const [position, setPosition] = useState(currentPosition);
  const [corporateName, setCorporateName] = useState(currentCorporateName);
  const [country, setCountry] = useState(currentCountry);
  const [city, setCity] = useState(currentCity);
  const [address, setAddress] = useState(currentAddress);
  const [zipCode, setZipCode] = useState(currentZipCode);
  const [isDisabled, setIsDisabled] = useState(true);

  const setName = (name) => {
    setNameLow(name.toUpperCase())
  }

  useEffect(
    () => {
      setIsDisabled(
        name.trim() === ''
        || surname.trim() === ''
        || corporateName.trim() === ''
        || address.trim() === ''
        || zipCode.trim() === ''
        || city.trim() === ''
        || country.trim() === ''
      );
    },
    [name, surname, corporateName, address, zipCode, city, country],
  );

  const updateLawPracticeInfo = (): void => {
    if (isLoading || isDisabled) {
      setError({ message: 'fillAllInformation' });
    }
    else {
      setIsLoading(true);
      if (id !== '') {
        firestore.updateUser(
          id,
          {
            surname,
            name: name.toUpperCase(),
            position,
            corporateName,
            address,
            city,
            zipCode,
            country,
            updatedOn: firestore.createTimeStamp(),
          },
        ).then(() => {
          const newUsers = users;
          let index = -1;
          users.forEach(({ id: userId }, i) => {
            if (userId === id) {
              index = i;
            }
          });
          if (index !== -1) {
            const oldUser = newUsers[index];
            const userUpdated = {
              ...oldUser,
              surname,
              name,
              position,
              corporateName,
              address,
              city,
              zipCode,
              country,
            };
            newUsers[index] = { ...userUpdated };
            setUsers(newUsers);
          }
          setSuccess({ message: 'infoUpdateSuccess' });
          setIsLoading(false);
        }).catch((e) => {
          setError(e);
          setIsLoading(false);
        });
      } else {
        setError({ message: 'userNotFound' });
        setIsLoading(false);
      }
    }
  };

  return {
    name,
    setName,
    surname,
    setSurname,
    position,
    setPosition,
    address,
    setAddress,
    city,
    setCity,
    zipCode,
    setZipCode,
    country,
    setCountry,
    corporateName,
    setCorporateName,
    isDisabled,
    isLoading,
    updateLawPracticeInfo,
  };
};

export default useAdminLawPracticeInfoUpdate;
