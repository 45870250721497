import React from 'react';
import { translate } from 'react-multi-lang';
import {
  Container,
  Button,
  Segment,
  Image,
} from 'semantic-ui-react';

import {
  JOBFAIR_EVENTS
} from '../../constants/routes';

import {
  Link,
} from 'react-router-dom';

import ResponsiveContainer from '../../components/containers/ResponsiveContainer';
import PageHeader from '../../components/headers/PageHeader';
import useScrollTopOnMount from '../../logic/ui/useScrollTopOnMount';

import bgName from '../../img/bg/jobFair.png';
import jobFair from '../../img/job-fair/jobfair2.jpg';


const BoursePage = ({ t }) => {
  useScrollTopOnMount();
  return (
    <ResponsiveContainer
      minHeight={300}
      bgName={bgName}
      header={
        <PageHeader
          header="jobFair"
        />
      }
    >
      <Container className="padded" text>
        <p>
          {t('jobFairText1')}
          <br />
          <br />
          {t('jobFairText2')}
          <br />
          <br />
          <Image src={jobFair} rounded size="large" floated="left" />
          <b>
            {t('jobFairText3')}
          </b>
          <br />
          <br />
          {t('jobFairText4')}
        </p>
        <Segment basic textAlign="center">
          <Button
            size="huge"
            color="red"
            as={Link}
            to={JOBFAIR_EVENTS}
          >
            {t('jobFairButton')}
          </Button>
        </Segment>
      </Container>
    </ResponsiveContainer>
  );
};

export default translate(BoursePage);
