import React from 'react';
import { translate } from 'react-multi-lang';
import {
  Menu,
  Sidebar,
} from 'semantic-ui-react';
import MenuItems from './MenuItems';

const MobileSideMenu = ({ sidebarOpened, t }) => (
  <Sidebar
    as={Menu}
    animation="uncover"
    inverted
    vertical
    visible={sidebarOpened}
    className="z-index-3"
  >
    <MenuItems mobile/>
  </Sidebar>
);

export default translate(MobileSideMenu);
