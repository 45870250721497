import React, {
  useContext,
  useState,
} from 'react';
import {
  Link,
  withRouter,
} from 'react-router-dom';
/* STYLE */
import {
  Grid,
  Select,
  Header,
  Card,
  Segment,
  Button,
  Label,
  Icon,
} from 'semantic-ui-react';
/* TRANSLATIONS */
import {
  translate,
} from 'react-multi-lang';

import useNews from '../../logic/news/useNews';
import NewsModal from '../../components/modals/NewsModal';
import DeletionModal from '../../components/modals/DeletionModal';
import { UseIsMobileContext } from '../../logic/contexts/UseIsMobileContext';
import { UseAuthUserContext } from '../../logic/contexts/UseAuthUserContext';
import {
  timeToLT
} from '../../utils/utils';
import {
  NEWS_ADD,
  NEWS_UPDATE,
} from '../../constants/routes';
import {
  generateNewsTags
} from '../../constants/news';

const NewsPanel = ({
  history,
  t,
  category,
}) => {
  const isMobile = useContext(UseIsMobileContext);
  const { authUser } = useContext(UseAuthUserContext);
  const {
    role,
  } = authUser || {
    role: 'NONE',
  };
  const {
    news,
    tagFilter,
    setTagFilter,
    deleteNews,
    isFiltering,
    isLoading,
    authUserId,
    setLoadMore,
    loadMore,
    allLoaded,
  } = useNews(t, category);
  return (
    <React.Fragment>
      {(isLoading)
        ? (
          <Grid.Row textAlign="center" verticalAlign="middle" className="padded">
            <Segment
              basic
              className="full-width"
              loading={true}
            />
          </Grid.Row>
        ) : (
          <Grid.Row>
            <Grid.Column width={4}>
              <Segment basic compact className="full-width">
                {(role === "ADMIN") && (
                  <Button
                    as={Link}
                    to={NEWS_ADD}
                    color="olive"
                    icon="edit"
                    labelPosition="left"
                    size={isMobile ? 'medium' : 'huge'}
                    content={t('addANews')}
                  />
                )}
                <Header content={t('filters')} as="h2" />
                <span className="label">{t('tag')}</span>
                <Select
                  options={[{ key: 'all', value: 'all', text: t('all') }, ...generateNewsTags(t)]}
                  defaultValue={tagFilter}
                  text={t(tagFilter)}
                  className="full-width margin-bottom-1em"
                  onChange={(e, { value }) => setTagFilter(`${value}`)}
                />
              </Segment>
            </Grid.Column>
            <Grid.Column width={11}>
              {isFiltering
                ? (
                  <Segment
                    basic
                    className="full-width"
                    loading={true}
                  />
                ) : (
                  <React.Fragment>
                    <Card.Group stackable>
                      {news.map(({
                        id,
                        ...props
                      }) => (
                        <OfferCard
                          key={id}
                          id={id}
                          role={role}
                          authUserId={authUserId}
                          t={t}
                          deleteNews={deleteNews}
                          {...props}
                        />
                      ))}
                    </Card.Group>
                    {!allLoaded && (
                      <Button
                        disabled={allLoaded}
                        loading={loadMore}
                        onClick={() => setLoadMore(true)}
                        className="margin-top-1em"
                        content={t('loadMore')}
                        color="red"
                        basic={allLoaded}
                        size="big"
                      />
                    )}
                  </React.Fragment>
                )
              }
            </Grid.Column>
          </Grid.Row>
        )
      }
    </React.Fragment>
  );
};

const OfferCard = ({
  id,
  authUserId,
  role,
  name,
  tag,
  description,
  createdOn,
  createdBy,
  deleteNews,
  t,
}) => {
  const [isShowingDeletionModal, setIsShowingDeletionModal] = useState(false);
  const [isShowingInfoModal, setIsShowingInfoModal] = useState(false);
  return (
    <React.Fragment>
      <Card
        centered
        raised
        header={
          <React.Fragment>
            <Label ribbon color="black" content={t(tag)} />
            <Header as="h2">
              {`${name}`}
            </Header>
          </React.Fragment>
        }
        meta={
          <Label>
            <Icon name="calendar outline" />{timeToLT(createdOn, 'LLL')}
          </Label>
        }
        description={
          <div className="margin-bottom-1em margin-top-1em pre-wrap">
            {`${description.substr(0, 150)}`}
            {(description.length > 149) && (
              <React.Fragment>
                <br />
                {'...'}
              </React.Fragment>
            )}
          </div>
        }
        extra={
          <React.Fragment>
            <Button
              color="olive"
              content={t('seeMore')}
              icon="plus"
              labelPosition="left"
              onClick={() => setIsShowingInfoModal(true)}
            />
            {(role === 'ADMIN') && (
              <React.Fragment>
                <Button
                  color="black"
                  content={t('edition')}
                  icon="edit"
                  labelPosition="left"
                  as={Link}
                  to={`${NEWS_UPDATE}/${id}`}
                />
                <br />
                <br />
                <Button
                  color="red"
                  content={t('deletion')}
                  icon="close"
                  labelPosition="left"
                  onClick={() => setIsShowingDeletionModal(true)}
                />
              </React.Fragment>
            )}
          </React.Fragment>
        }
      />
      <DeletionModal
        isShowingModal={isShowingDeletionModal}
        setIsShowingModal={setIsShowingDeletionModal}
        confirmButtonAction={() => {
          deleteNews(id);
          setIsShowingDeletionModal(false);
        }}
      />
      <NewsModal
        name={name}
        tag={tag}
        createdOn={createdOn}
        description={description}
        isShowingModal={isShowingInfoModal}
        setIsShowingModal={setIsShowingInfoModal}
      />
    </React.Fragment>
  );
};


export default withRouter(translate(NewsPanel));
