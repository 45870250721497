import React from 'react';
import { translate } from 'react-multi-lang';
import {
  Link,
  withRouter,
} from 'react-router-dom';
import {
  Menu,
  Icon,
  Button,
} from 'semantic-ui-react';
import {
  UseAuthUserContext,
} from '../../logic/contexts/UseAuthUserContext';
import useSignout from '../../logic/users/useSignout';
import {
  SIGN_IN,
  MEMBERSHIP,
} from '../../constants/routes';

const MobileTopMenu = ({ handleToggle, t, history }) => {
  const {
    authUser,
  } = React.useContext(UseAuthUserContext);
  const { signOut } = useSignout(history);
  return (
    <Menu inverted pointing secondary size="large" style={{ border: 'none' }}>
      <Menu.Item onClick={() => handleToggle()}>
        <Icon name="sidebar" />
      </Menu.Item>
      <Menu.Item position="right">
        {!authUser && (
          <React.Fragment>
            <Button as={Link} to={SIGN_IN} inverted>
              {t('login')}
            </Button>
            <Button as={Link} to={MEMBERSHIP} inverted style={{ marginLeft: "0.5em" }}>
              {t('signup')}
            </Button>
          </React.Fragment>
        )}
        {!!authUser && (
          <React.Fragment>
            <Button onClick={() => signOut(history)} inverted>
              {t('signOut')}
            </Button>
          </React.Fragment>
        )}
      </Menu.Item>
    </Menu>
  );
};

export default withRouter(translate(MobileTopMenu));


