import React from 'react';
import { translate } from 'react-multi-lang';
import { Link } from 'react-router-dom';
import {
  UseAuthUserContext,
} from '../logic/contexts/UseAuthUserContext';
import {
  Grid,
  Header,
  Container,
  Segment,
  List,
  Divider,
} from 'semantic-ui-react';
import * as ROUTES from '../constants/routes';

const Footer = ({ t }) => {
  const {
    authUser,
  } = React.useContext(UseAuthUserContext);
  return (
    <Segment inverted vertical style={{ padding: "3em 0em" }}>
      <Container>
        <Grid divided inverted stackable>
          <Grid.Row>
            <Grid.Column width={3}>
              <Header color="red" inverted as="h4" content={t('theAssociation')} />
              <List link inverted>
                {/*<List.Item as={Link} to={ROUTES.PRESENTATION} href="/presentation">{t('presentation')}</List.Item>*/}
                <List.Item as={Link} to={ROUTES.PRESIDENT_WORD} href="/presentation">{t('presentation')}</List.Item>
                <List.Item as={Link} to={ROUTES.ADMINISTRATION_COUNCIL} href="/administration-council">{t('administrationCouncil')}</List.Item>
                <List.Item as={Link} to={ROUTES.LEGAL} href="/legal">{t('legal')}</List.Item>
                {!authUser && (
                  <List.Item as={Link} to={ROUTES.MEMBERSHIP} href="/membership">{t('membership')}</List.Item>
                )}
              </List>
            </Grid.Column>
            <Grid.Column width={3}>
              <Header color="red" inverted as="h4" content={t('theCommunity')} />
              <List link inverted>
                <List.Item as={Link} to={ROUTES.NEWS} href="/news">{t('news')}</List.Item>
                {!!authUser
                  ? (
                    <List.Item as={Link} to={ROUTES.DIRECTORY} href="/directory">{t('theDirectory')}</List.Item>
                  ) : (
                    <List.Item as={Link} to={ROUTES.JOBFAIR} href="/jobfair">{t('jobFair')}</List.Item>
                  )
                }
                <List.Item as={Link} to={ROUTES.DIPLOMA} href="/diploma">{t('theDiploma')}</List.Item>
              </List>
            </Grid.Column>
            {authUser && (
            <Grid.Column width={3}>
              <Header color="red" inverted as="h4" content={t('yourCarrier')} />
              <List link inverted>
                  <List.Item as={Link} to={ROUTES.JOB_OFFERS} href="/jobs">{t('jobOffers')}</List.Item>
                  <List.Item as={Link} to={ROUTES.JOBFAIR} href="/jobfair">{t('jobFair')}</List.Item>
              </List>
            </Grid.Column>
            )}
            <Grid.Column width={7}>
              <Header color="red" as="h4" inverted content={t('contact')} />
              <p>
                ANDJCE
                <br />
                <br />
                34 bis rue Vignon - 75009 Paris<br />
                Email : contact@andjce.com<br />
              </p>
            </Grid.Column>
          </Grid.Row>
          <br />
          <br />
          <Divider />
          <Grid.Row>
            <Grid.Column width={16} textAlign="left">
              <span className="font-size-1em">
                Application développée par <a className="color-red" href="https://komdan.com">KOMDAN SAS</a> 🎈
              </span>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Container>
    </Segment>
  );
};

export default translate(Footer);
