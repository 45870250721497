import {
  useState,
  useEffect,
  useContext,
} from 'react';
import { firestore } from '../../firebase';
import { UseToastsContext } from '../contexts/UseToastsContext';
import { UseAuthUserContext } from '../contexts/UseAuthUserContext';
import { scrollTop } from '../../utils/utils';
import useScrollTopOnMount from '../ui/useScrollTopOnMount';
import {
  generateNewsTags,
} from '../../constants/news';

const useNewsCreate = (t)  => {
  useScrollTopOnMount();
  const {
    setError,
    setSuccess,
    isLoading,
    setIsLoading,
  } = useContext(UseToastsContext);

  const {
    authUser,
  } = useContext(UseAuthUserContext);

  const {
    id,
  } = authUser || {
    id: '',
  };
  const [name, setName] = useState('');
  const [tag, setTag] = useState(generateNewsTags(t)[0].value);
  const [description, setDescription] = useState('');
  const [isDisabled, setIsDisabled] = useState(true);

  useEffect(
    () => {
      setIsDisabled(
        name.trim() === ''
        || description.trim() === ''
        || tag.trim() === ''
      );
    },
    [
      name,
      description,
      tag,
    ],
  );

  const resetForm = () => {
    setName('');
    setDescription('');
    setTag(generateNewsTags(t)[0].value);
    scrollTop();
  };

  const createNews = () => {
    if (!isLoading && !isDisabled && !!id) {
      setIsLoading(true);
      firestore.createNews({
        name,
        tag,
        description,
        createdBy: id,
        createdOn: firestore.createTimeStamp(),
      }).then(() => {
        setSuccess({ message: 'newsCreationSuccess' });
        setError(null);
        resetForm();
        setIsLoading(false);
      }).catch((e) => {
        setError(e);
        setSuccess(null);
        setIsLoading(false);
      })
    } else {
      setError({ message: 'fillAllInfo' });
    }
  };

  return {
    name,
    setName,
    description,
    setDescription,
    tag,
    setTag,
    createNews,
    isDisabled,
    isLoading,
   };
};

export default useNewsCreate;
